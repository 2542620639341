import React, { useEffect, useState } from 'react';
import ServiceBody from '../../../components/Service/ServiceBody';
import DefaultTable from './DefaultTable';

// Сервис оценок - Интерфейс руководителя
function Approver() {
  const isAdmin = true;
  const location = 'admin';

  return (
    <ServiceBody>
      {/* <Period period={period} setPeriod={writePeriodToLS} vertical>
        <Wrapper flexFlow="row wrap">
          {quarters.map((q) => (
              <IconButton key={q} onClick={() => setQuarterPeriod(q)}>{`Q${q}`}</IconButton>
          ))}
          <Button onClick={() => setOpenYear(!openYear)}>Выбрать финансовый год</Button>
          { openYear && <div className="openYear" onClick={() => chooseFinYear(currentYear, nextYear)}>{`${currentYear} - ${nextYear}`}</div>}
        </Wrapper>
        <Division />
      </Period> */}
{/*
      <TableWrapper
        title="Проектные оценки" */}
        {/* // button={showExTable ? 'Основная информация' : 'Расширенная информация'}
        // buttonHandler={() => setShowExTable(!showExTable)}
      > */}
        {/* {showExTable
          ? <ExtendedTable period={period} />
          : <DefaultTable period={period} />} */}
          <DefaultTable isAdmin={isAdmin} location={location} />
      {/* </TableWrapper> */}
    </ServiceBody>
  );
}

export default Approver;
