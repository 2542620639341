// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
  /* Добавьте другие стили по вашему усмотрению */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Позиция подсказки */
  left: 50%;
  margin-left: 1px; /* Центрирование подсказки */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1; /* Показываем подсказку при наведении */
}

#staff_cv .custom-select__option {
  font-weight: normal;
  text-transform: none;
  font-size: 12px;
  padding: 4px;
}
#staff_cv .info-card {
  position: absolute;
  background-color: rgb(228, 241, 255);
  border-radius: 4px;
  padding: 10px 20px;
  z-index: 1000;
  pointer-events: none;
  border: 1px solid #e7e7e7;
}`, "",{"version":3,"sources":["webpack://./src/pages/StaffCV/staffcv.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,kBAAA;EACA,uBAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;EACA,+CAAA;AAEF;;AACA;EACE,kBAAA;EACA,YAAA;EACA,uBAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA,EAAA,sBAAA;EACA,SAAA;EACA,gBAAA,EAAA,4BAAA;EACA,UAAA;EACA,wBAAA;AAEF;;AACA;EACE,mBAAA;EACA,UAAA,EAAA,uCAAA;AAEF;;AAGA;EACE,mBAAA;EACA,oBAAA;EACA,eAAA;EACA,YAAA;AAAF;AAGA;EACE,kBAAA;EACA,oCAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,oBAAA;EACA,yBAAA;AADF","sourcesContent":[".tooltip {\n  position: absolute;\n  background-color: white;\n  border: 1px solid #ccc;\n  padding: 10px;\n  z-index: 1000;\n  /* Добавьте другие стили по вашему усмотрению */\n}\n\n.tooltip .tooltiptext {\n  visibility: hidden;\n  width: 200px;\n  background-color: black;\n  color: #fff;\n  text-align: center;\n  border-radius: 5px;\n  padding: 5px;\n  position: absolute;\n  z-index: 1;\n  bottom: 125%; /* Позиция подсказки */\n  left: 50%;\n  margin-left: 1px; /* Центрирование подсказки */\n  opacity: 0;\n  transition: opacity 0.3s;\n}\n\n.tooltip:hover .tooltiptext {\n  visibility: visible;\n  opacity: 1; /* Показываем подсказку при наведении */\n}\n\n#staff_cv {\n\n.custom-select__option {\n  font-weight: normal;\n  text-transform: none;\n  font-size: 12px;\n  padding: 4px;\n}\n\n.info-card {\n  position: absolute;\n  background-color: rgb(228, 241, 255);\n  border-radius: 4px;\n  padding: 10px 20px;\n  z-index: 1000;\n  pointer-events: none;\n  border: 1px solid #e7e7e7;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
