import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../components/UI/Button/Button';
import CheckBox from '../../../../components/UI/CheckBox';
import Input from '../../../../components/UI/Input';
import Loader from '../../../../components/UI/Loader';
import Toggle from '../../../../components/UI/Toggle';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { log } from '../../../../utils/functions/others';
import { clientSendData } from '../../../../utils/functions/requests';
import { stringMatch } from '../../../../utils/functions/stringHandling';
import CardSetting from '../../../../components/Card/CardSetting';
import Select from '../../../../components/UI/Select';
import RadioButton from '../../../../components/UI/RadioButton';
import EditQuestionCard from './EditQuestionCard';

// Сервис анкетирования - вкладка "Анкеты" - карточка анкеты - вкладка "Вопросы"
function Questions(props) {
  const {
    currentQuestionnaire, // текущая анкета, отображаемая в карточке
    setCurrentQuestionnaire,
    saveQuestionnaire,
  } = props;
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [questions, setQuestions] = useState([]); // вопросы в выбранном направлении анкеты
  const userDirections = useSelector((state) => state.questionnaire.user_directions); // направления доступные пользователю
  const [filteredQuestions, setFilteredQuestions] = useState([]); // вопросы отображаемые при поиске
  const isAllAdded = (checkIsAllAdded()); // все вопросы добавлены в анкету?
  const [openedQuestions, setOpenedQuestions] = useState([]); // развернутые наряды
  const isActiveQuestion = (id) => openedQuestions.includes(id); // определение развернута ли строка наряда
  const [answers, setAnswers] = useState([]); // ответы в выбранном направлении анкеты
  const { questionnaire } = currentQuestionnaire;
  const [isLast, setIsLast] = useState(null);
  const [skipQuestion, setSkipQuestion] = useState({});
  const [skipQuestionAnswers, setSkipQuestionAnswers] = useState([]);
  const [radioButtonAnswerArray, setRadioButtonAnswerArray] = useState([]);
  const [radioButtonSkipAnswerArray, setRadioButtonSkipAnswerArray] = useState([]);
  const [allAnswers, setAllAnswers] = useState([]);
  const [questionId, setQuestionId] = useState(null);
  const [showQuestionCard, setShowQuestionCard] = useState(false);

  useEffect(() => {
    chooseQuestions();
    fetchAllAnswers();
  }, []);

  async function fetchAllAnswers() {
    try {
      const reqData = {
        type: 'fetchAllAnswers',
      };
      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/fetch_all_answers', reqData);// запрос в БД
      if (result) {
        setAllAnswers(result.data);
      }
      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'getQuestions');
      setIsLoading(false); // загрузка завершена
    }
  }

  // ---Выбрать вариант ответа для завершающего вопроса
  function fillRadioButtonAnswerArray(question_id, answer_id) {
    if (Object.keys(radioButtonAnswerArray).includes(question_id) && radioButtonAnswerArray[question_id] === answer_id) {
      setRadioButtonAnswerArray({ ...radioButtonAnswerArray, [question_id]: null });
    } else { setRadioButtonAnswerArray({ ...radioButtonAnswerArray, [question_id]: answer_id }); }
  }
  // Получить вопросы в выбранном направлении
  async function chooseQuestions() {
    try {
      const reqData = {
        type: 'chooseQuestions',
        direction_id: currentQuestionnaire.questionnaire.group_id || userDirections[0].id,
        questionnaire_id: currentQuestionnaire.questionnaire.id,
      };
      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/choose_questions', reqData);// запрос в БД
      if (result) {
        let resultWithNumber = [];
        result.forEach((element) => {
          const tempEl = element;
          const compareElWithNumber = currentQuestionnaire.questions.find((item) => item.id === element.id);
          if (compareElWithNumber !== undefined) tempEl.number = compareElWithNumber.number;
          else tempEl.number = '-1';
          resultWithNumber.push(tempEl);
        });
        resultWithNumber = resultWithNumber.sort((el1, el2) => {
          if (el1.number < el2.number) { return 1; }
          return -1;
        });

        setQuestions(resultWithNumber);
        console.log(resultWithNumber);
        setFilteredQuestions(resultWithNumber);
        // setQuestions(result);
        // setFilteredQuestions(result);
      }
      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'getQuestions');
      setIsLoading(false); // загрузка завершена
    }
  }

  // Получить ответы в выбранном направлении
  async function chooseAnswers(qId) {
    try {
      const reqData = {
        type: 'chooseAnswers',
        question_id: qId,
      };
      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/choose_answers', reqData);// запрос в БД
      if (result) {
        setAnswers(result.data);
      }
      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'getQuestions');
      setIsLoading(false); // загрузка завершена
    }
  }

  function changeIsLast(e, q, a) {
    const addBreak = { ...q, break: a.id }; // добавить значение required к выбранному вопросу

    if (e.target.value && !toggleDefaultHandler(q)) { // если required включается, а вопрос выключен
      setCurrentQuestionnaire({ // то вопрос также включается
        ...currentQuestionnaire,
        questions: [...currentQuestionnaire.questions, addBreak],
      });
    } else { // иначе меняется значение addBreak
      setCurrentQuestionnaire({ // обновить состояние
        ...currentQuestionnaire,
        questions: currentQuestionnaire.questions.map((question) => { // пройти по вопросам
          if (question.id === q.id) {
            return addBreak;
          } // если id равен id редактируемого меняем на addRequired
          return question; // иначе оставляем без изменений
        }),
      });
    }
  }

  // Обработчик состояния переключателя
  function toggleDefaultHandler(q) {
    // Поиск вопроса в массиве вопросов связанных с анкетой
    const result = currentQuestionnaire.questions.filter((question) => question.id === q.id);
    // Возвращается значение, которое используется для установки значения переключателя
    if (result.length > 0) {
      return true;
    } // если вопрос найден вернется true
    return false; // иначе false
  }

  // Проверка на добавление всех вопросов в анкету
  function checkIsAllAdded() {
    const checked = questions.map((q) => {
      const result = currentQuestionnaire.questions.find((question) => question.id === q.id);
      if (result) return true;
      return false;
    });
    if (checked.includes(false)) return false;
    return true;
  }

  // Обработчик состояния чекбокса ( параметр required )
  function requiredDefaultHandler(q) {
    console.log(currentQuestionnaire.questions);
    // Поиск вопроса в массиве вопросов связанных с анкетой
    const result = currentQuestionnaire.questions.filter((question) => question.id === q.id);
    console.log(result);
    // Возвращается значение которое используется для установки значения чекбокса
    if (result.length > 0 && result[0].required) return true;
    return false;
  }

  // Переключение вопроса
  function toggleQuestion(q) {
    if (!toggleDefaultHandler(q)) { // если вопрос отсутствует в анкете
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions: [...currentQuestionnaire.questions, q], // он добавляется
      });
      log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} добавил в анкету вопрос id: ${q.id}`, eventtype: 'info' });
    } else { // иначе удаляется
      const filtered = currentQuestionnaire.questions.filter((question) => question.id !== q.id);
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions: filtered,
      });
      setFilteredQuestions(questions); // обновление отображаемых вопросов
      log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} убрал из анкеты вопрос id: ${q.id}`, eventtype: 'info' });
    }
  }

  // Переключение параметра вопроса required
  function toggleRequired(e, q) {
    const addRequired = { ...q, required: e.target.checked }; // добавить значение required к выбранному вопросу

    if (e.target.checked && !toggleDefaultHandler(q)) { // если required включается, а вопрос выключен
      setCurrentQuestionnaire({ // то вопрос также включается
        ...currentQuestionnaire,
        questions: [...currentQuestionnaire.questions, addRequired],
      });
      log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} сделал вопрос id: ${q.id} обязательным`, eventtype: 'info' });
    } else { // иначе меняется значение required
      setCurrentQuestionnaire({ // обновить состояние
        ...currentQuestionnaire,
        questions: currentQuestionnaire.questions.map((question) => { // пройти по вопросам
          if (question.id === q.id) {
            log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} сделал вопрос id: ${q.id} ${e.target.checked ? ' обязательным' : ' необязательным'}`, eventtype: 'info' });
            return addRequired;
          } // если id равен id редактируемого меняем на addRequired
          return question; // иначе оставляем без изменений
        }),
      });
    }
  }

  // Поиск вопроса по строке
  function searchQuestion(e) {
    const string = e.target.value.toLowerCase();
    // Результат поиска - это отфильтрованный массив, в котором искомая строка содержится в элементах массива
    const searchResult = questions.filter((q) => (
      stringMatch(string, q.title) // поиск в заголовке вопроса
            || stringMatch(string, q.question) // поиск в содержании вопроса
    ));

    // если строка поиска пустая - показываются все вопросы
    if (string.length === 0) setFilteredQuestions(questions);
    else setFilteredQuestions(searchResult); // иначе - результат
  }

  // Добавить/сбросить все вопросы
  function toggleAllQuestions() {
    if (!isAllAdded) {
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions,
      });
    } else {
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions: [],
      });
    }
    setFilteredQuestions(questions); // обновление отображаемых вопросов
    log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} нажал кнопку ${isAllAdded ? 'Сбросить все' : 'Добавить все'}`, eventtype: 'info' });
  }

  function toggleLast(id) {
    if (isLast === id) {
      setIsLast(null);
    } else {
      setIsLast(id);
      setAnswers(allAnswers.filter((item) => item.question_id === id));
    }
  }
  // Свернуть/развернуть вопрос
  function toggleEdit(id) {
    if (openedQuestions.includes(id)) { // если id вопроса присутствует в списке развернутых
      setOpenedQuestions((state) => [...state.filter((item) => item !== id)]); // убрать его оттуда
    } else setOpenedQuestions((state) => [...state, id]); // иначе id вопроса нет - добавляем
  }

  // ----------- Обработчик чекбокса завершения опроса

  function endQuestionnaire(event, question_id) {
    if (event.target.checked) {
      setIsLast(question_id);
      chooseAnswers(question_id);
    }
  }

  /* function skipQuestionSetter(event, question_id) {
    const temp_questions = questions.map((el) => (el.id === question_id ? { ...el, dependence: event.id } : el));
    setQuestions(temp_questions);
    setCurrentQuestionnaire({
      ...currentQuestionnaire,
      questions: temp_questions, // он добавляется
    });
  } */

  function currentChoiceHandler(answerId) {
    // поиск текущего ответа в массиве ответов
    const findAnswer = answers.find((a) => a.answer_id === answerId);
    if (findAnswer) return true;
    return false;
  }

  function toggleSkipQuestion(e, qId) {
    const skippedQuestion = {};
    skippedQuestion[qId] = e.title;
    if (skipQuestion.id === e.id) {
      setSkipQuestion(null);
    } else {
      setSkipQuestion((state) => ({
        ...state, ...skippedQuestion,
      }));
      setSkipQuestionAnswers({ ...skipQuestionAnswers, [qId]: { qId: e.id, array: allAnswers.filter((item) => item.question_id === e.id) } });
    }
  }

  function dependenceAnswerSetter(e, q) {
    const temp_questions = questions.map((el) => (el.id === skipQuestionAnswers[q.id].qId ? { ...el, dependence: q.id, dependence_answer: e.target.id } : el));
    setQuestions(temp_questions);
    setCurrentQuestionnaire({
      ...currentQuestionnaire,
      questions: temp_questions, // он добавляется
    });
  }

  function breakAnswerSetter(q, a) {
    const temp_questions = questions.map((el) => (el.id === q.id ? { ...el, break: a.id } : el));
    setQuestions(temp_questions);
    setCurrentQuestionnaire({
      ...currentQuestionnaire,
      questions: temp_questions, // он добавляется
    });
  }

  // Открыть карточку вопроса
  function openQuestionCard(id) {
    setQuestionId(id);
    setShowQuestionCard(true);
  }
  // закрыть карточку
  function closeHandler() {
    setShowQuestionCard(false); // закрытие карточки
  }

  const dragQuestion = useRef(null);
  const draggedOverQuestion = useRef(null);

  function handleSort() {
    const questionsClone = [...filteredQuestions];
    const temp = questionsClone[dragQuestion.current];
    questionsClone[dragQuestion.current] = questionsClone[draggedOverQuestion.current];
    questionsClone[draggedOverQuestion.current] = temp;
    setFilteredQuestions(questionsClone);
    setCurrentQuestionnaire({
      ...currentQuestionnaire,
      questions: questionsClone, // он добавляется
    });
  }

  if (isLoading) return <Loader />;
  return (
    <div className="questions-card__block">
      {/* Поле поиска вопросов */}
      <div className="questions-card__block_space-between">
        <Input placeholder="Поиск вопроса" onChange={searchQuestion} />
        <Button onClick={toggleAllQuestions}>{isAllAdded ? 'Сбросить все' : 'Добавить все'}</Button>
      </div>

      {/* Cписок вопросов */}
      {filteredQuestions.map((q, index) => (
        <div key={q.id} className="questions-card__block_align-left">
          <div
            id={index}
            className="questions-card__question-choose-panel"
            // draggable
            // onDragStart={(e) => { (dragQuestion.current = e.target.id); }}
            // onDragEnter={(e) => { (draggedOverQuestion.current = e.target.id); }}
            // onDragEnd={handleSort}
            // onDragOver={(e) => e.preventDefault()}
          >
            <Toggle state={String(toggleDefaultHandler(q))} onClick={() => toggleQuestion(q)} />
            <span id={index} onClick={() => openQuestionCard(q.id)} className="clickable">
            {q.title}
            </span>
            <CheckBox
              onChange={(e) => toggleRequired(e, q)}
              id={`questionaire-card__checkbox_${q.id}`}
              checked={requiredDefaultHandler(q)}
              // defaultChecked={requiredDefaultHandler(q)}
              // возможно реакт будет ругаться на неконтролируемый компонент
              // checked вместо defaultChecked сделал для обновления состояния чекбокса
              // т.к defaultChecked сработает один раз при рендере
              // а checked обновится при изменении данных от которых он зависит
            >
              обязательный
            </CheckBox>
          {/*  <div className="question_chevron">
            <img
       //   className={`ticket-task__chevron ${isActiveTask('new') ? 'ticket-task__chevron_active' : ''}`}
              className={`ticket-task__chevron ${isActiveQuestion(q.id) ? 'ticket-task__chevron_active' : ''}`}
            //  className="ticket-task__chevron"
              onClick={() => toggleEdit(q.id)}
              src="../../../../../../icons/chevron.svg"
              alt="chevron"
              id={`question_${q.id}`}
            />
      </div> */}
          </div>
            <div />

            {showQuestionCard && (
              <EditQuestionCard
                setShowQuestionCard={setShowQuestionCard}
                accessQuestionId={questionId}
                closeHandler={closeHandler}
                currentQuestionnaire={currentQuestionnaire}
                questions={questions}
                setQuestions={setQuestions}
                setCurrentQuestionnaire={setCurrentQuestionnaire}
                saveQuestionnaire={saveQuestionnaire}
              />
            )}

{/* {isActiveQuestion(q.id) && <div className="questions-card__question-choose">
        <CardSetting title="Комментарий">
        <CheckBox />
        </CardSetting>

        <CardSetting title="Сделать вопрос завершающим">
        <CheckBox
          id={`questionaire-card__checkbox_${q.id}`}
          value={isLast === q.id}
          onClick={() => toggleLast(q.id)}
        />
        </CardSetting>
        {isLast !== null && isLast === q.id && <div className="questions-card__question-choose">
          {answers.map((a) => <div key={a.id}>
            <CardSetting>
{a.answer}
<RadioButton
  id={a.id}
  // value={Object.values(radioButtonAnswerArray).includes(a.id)}
  onChange={() => breakAnswerSetter(q, a)}
  defaultChecked={() => currentChoiceHandler(a.id)}
  group={`answer-${q.id}`}
/>
            </CardSetting>
                              </div>)}
                                               </div>}

        <CardSetting title="Пропустить вопрос">
        <Select
        //  defaultValue={skipQuestion.title}
          defaultValue={skipQuestion[q.id]}
          id={q.id}
          array={questions}
          onChoose={(e) => { toggleSkipQuestion(e, q.id); }}
        />
        </CardSetting>
        {skipQuestion[q.id] && (<div className="questions-card__question-choose">
          {skipQuestionAnswers[q.id].array.map((sa) => <CardSetting key={sa.id}>
              {sa.answer}
              <RadioButton
                id={sa.id}
                // value={Object.values(radioButtonSkipAnswerArray).includes(sa.id)}
                onChange={(e) => dependenceAnswerSetter(e, q)}
                defaultChecked={() => currentChoiceHandler(sa.id)}
                group={`skip-answer-${q.id}`}
              />
                                                       </CardSetting>)}
                                </div>)
        }

                           </div>} */}

        {/*  <div className="questions-card__question-choose">{q.question}</div> */}
        </div>
      ))}

      {filteredQuestions.length === 0 && (
      <div className="questions-card__question-choose">
        Вопросы отсутствуют
      </div>)}
    </div>
  );
}

export default Questions;
