// Заголовки первой части таблицы оценки (общая информация)
export const firstHeaders = [
  { id: 1, title: 'Проект', field: 'project_title' },
  { id: 2, title: 'Клиент', field: 'client_title' },
  { id: 3, title: 'Объем проекта (час.)', field: '' },
  { id: 4, title: 'Участие сотрудника в проекте (час.)', field: 'workload' },
];

// Заголовки второй части таблицы оценки (сама оценка)
export const secondHeaders = [
  { id: 1, title: 'Задачи на проекте', field: '' },
  { id: 2, title: 'Оценка достижения результатов (1-10)', field: '' },
  { id: 3, title: 'Оценка достижения результатов (А,В,С,D)', field: '' },
  { id: 4, title: 'Комментарии (обоснование оценки)', field: '' },
  { id: 5, title: 'Для руководителя (комментарии)', field: '' },
];

export const mainInfoHeaders = [
  { id: 1, title: 'Проект', field: 'project_title' },
  { id: 2, title: 'Клиент', field: 'client_title' },
  { id: 3, title: 'Объем проекта (час.)', field: '' },
  { id: 4, title: 'Участие сотрудника в проекте (час.)', field: 'workload' },
  { id: 5, title: 'Роль в проекте', field: '-' },
  { id: 6, title: 'Руководитель', field: '-' },
];
// Значение числовой оценки если задача не применима
export const integerNoValue = 'Не применимо';
// Варианты числовых оценок
export const integerOptions = [
  { id: 10, title: 10 },
  { id: 9, title: 9 },
  { id: 8, title: 8 },
  { id: 7, title: 7 },
  { id: 6, title: 6 },
  { id: 5, title: 5 },
  { id: 4, title: 4 },
  { id: 3, title: 3 },
  { id: 2, title: 2 },
  { id: 1, title: 1 },
];

// Значение буквенной оценки если задача не применима
export const characterNoValue = '-';
// Варианты буквенных оценок
export const characterOptions = [
  { id: 1, title: 'A' },
  { id: 2, title: 'B' },
  { id: 3, title: 'C' },
  { id: 4, title: 'D' },
];

// Цвета для подсветки ячеек на основании оценки

export const colourByValue = {
  0: '',
  1: '#FF493F',
  2: '#FF7742',
  3: '#FFA445',
  4: '#FFD248',
  5: '#FFFF4B',
  6: '#FFFF4B',
  7: '#D6FF4B',
  8: '#ACFF4B',
  9: '#83FF4A',
  10: '#59FF4A',
};

export const incidentCharacterOptions = [
  { id: 0, title: 'Нарушения не выявлены' },
  { id: 1, title: 'Нарушения выявлены' },
];

export const incidentIntegerOptions = [
  { id: 0, title: 0 },
  { id: 1, title: -0.5 },
];

export const finishedTasksIntegerOptions = [
  { id: 0, title: 0 },
  { id: 1, title: 0 },
  { id: 2, title: -1 },
  { id: 3, title: -2 },
];

export const finishedTasksCharacterOptions = [
  { id: 0, title: 'A' },
  { id: 1, title: 'B' },
  { id: 2, title: 'C' },
  { id: 3, title: 'D' },
];

export const description = {
  124: {
    Исполнитель: 'Выполнение персональных проектных задач, соответствующих уровню компетенций и профессиональным навыкам в соответствии с грейдом, и подготовка рабочих документов в обозначенные сроки и с надлежащим качеством.',
    'Руководитель команды': 'Выполнение персональных проектных задач, соответствующих уровню компетенций и профессиональным навыкам в соответствии с грейдом, и подготовка рабочих документов в обозначенные сроки и с надлежащим качеством.',
    'Руководитель проекта': 'Обеспечение выполнения проекта в целом. Своевременность, полнота и качество рабочих документов по заданию в целом, включая аудиторское заключение. Своевременная архивация задания',
    'Руководитель оценки': 'Обеспечение выполнения проекта в целом. Своевременность, полнота и качество рабочих документов по заданию в целом, включая аудиторское заключение. Своевременная архивация задания',
  },
  125: {
    Исполнитель: 'Своевременное информирование руководителя и иных сотрудников (если применимо) об изменениях в графике, возникающих вопросах и трудностях. Своевременная и адекватная обратная связь.',
    'Руководитель команды': 'Управление младшими специалистами, рациональное планирование, расстановка приоритетов и распределение задач в команде, контроль исполнения и эффективности, своевременная обратная связь.',
    'Руководитель проекта': 'Общее управление проектом: эффективное ресурсное планирование, управление и контроль младшими грейдами,  соблюдение сроков, бюджетных показателей, выставление актов и счетов, договорная работа.',
    'Руководитель оценки': 'Общее управление проектом: эффективное ресурсное планирование, управление и контроль младшими грейдами,  соблюдение сроков, бюджетных показателей, выставление актов и счетов, договорная работа.',
  },
  126: {
    Исполнитель: 'Своевременное, эффективное и позитивное взаимодействие с клиентом - прямое или опосредованное, через подготовку материалов для руководителя (запросы, ответы, ведение переписки, сглаживание/избегание конфликтных ситуаций,  своевременное доведение до руководства потребностей и вопросов клиента).',
    'Руководитель команды': 'Своевременное, эффективное и позитивное взаимодействие с клиентом (запросы, ответы, ведение переписки, сглаживание/избегание конфликтных ситуаций). Понимание и поддержание уровня удовлетворенности Клиентов, своевременное доведение до руководства потребностей клиента.',
    'Руководитель проекта': 'Обеспечение высокого качества обслуживания, поддержание уровня удовлетворенности Клиентов, положительные отзывы и рекомендации Клиентов.',
    'Руководитель оценки': 'Обеспечение высокого качества обслуживания, поддержание уровня удовлетворенности Клиентов, положительные отзывы и рекомендации Клиентов.',
  },
  127: {
    Исполнитель: 'Понимание зон и степени своей ответственности. Уровень самостоятельности при выполнении задач, принятие решений на своем уровне, внимательность, тщательность, оперативность. Мотивация развиваться.',
    'Руководитель команды': 'Понимание и способность взять на себя ответственность за результат работы команды. Уровень самостоятельности при принятии решений на уровне команды,  способность решать вопросы, находящиеся в зоне ответственности, брать на себя ответственность за принятые решения',
    'Руководитель проекта': 'Понимание и способность взять на себя ответственность за качество и эффективность проекта /проверки в целом. Допустимое количество замечаний и отсутствие критических замечаний по качеству аудита и соблюдению регламентов по результатам ХКК и/или экспресс-мониторинга.',
    'Руководитель оценки': 'Понимание и способность взять на себя ответственность за качество и эффективность проекта /проверки в целом. Допустимое количество замечаний и отсутствие критических замечаний по качеству аудита и соблюдению регламентов по результатам ХКК и/или экспресс-мониторинга.',
  },
  128: '',
  129: '',
};

export const referenceInfo = {
  A: 'Плановые показатели перевыполнены, реальные результаты превышают запланированные. Полное соответствие должностным требованиям.',
  B: 'Выполнены все поставленные задачи, реальные результаты на уровне запланированных. Соответствие должностным требованиям.',
  C: 'Поставленные задачи выполнены частично, необходимо дополнительное развитие, чтобы выйти на уровень запланированных результатов. Неполное соответствие должностным требованиям.',
  D: 'Большинство запланированных результатов не достигнуто. Несоответствие должностным требованиям.',
};
