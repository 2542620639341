import React, { useEffect, useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { headers } from './common';
import Select from '../../components/UI/Select';
import { alertFunction, alertFunctionLocal } from '../../utils/functions/alertFunction';
import TFoot from '../../components/Table/TFoot';
import { authorization } from '../../utils/functions/authenticate';
import Cap from '../Cap/Cap';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input';
import './adminstaffcv.scss';

function AdminStaffCV() {
  const [qrData, setQrData] = useState({ companies: [], users: [] });
  const [allUsers, setAllUsers] = useState([]);
  const [selectUser, setSelectUser] = useState({});
  const [certificates, setCertificates] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [selectedCertificates, setSelectedCertificates] = useState(new Set()); // Состояние для хранения выбранных сертификатов

  useEffect(() => {
    awaitRequests();
    getPermissions();
  }, []);

  async function getPermissions() {
    try {
      const reqData = { type: 'getAdminDataStaffCV' };
      const result = await clientSendData('POST', '/get_admin_data_staff_cv', reqData);
      if (result) {
        console.log(result.data);
        setQrData(result.data);
        setCertificates(() => JSON.parse(result.certificates.value));
      }
    } catch (error) {
      catchHandler(error, 'getAdminDataStaffCV');
    }
  }

  const handleFullNameChange = (id, newFullName) => {
    setCertificates(certificates.map((cert) => (cert.id === id ? { ...cert, fullName: newFullName } : cert)));
  };

  const handleShortNameChange = (id, newShortName) => {
    setCertificates(certificates.map((cert) => (cert.id === id ? { ...cert, shortName: newShortName } : cert)));
  };

  async function awaitRequests() {
    const checkAuthorization = await authorization();
    setIsAuthorized(checkAuthorization);
  }

  async function editPermissionsQr() {
    try {
      const reqData = { type: 'editPermissionsQr', data: qrData };
      const result = await clientSendData('POST', '/edit_permissions_qr', reqData);
      if (result.success) {
        alertFunction('save_settings', 'client');
        setQrData((state) => ({ ...state, change: false }));
      }
    } catch (error) {
      catchHandler(error, 'getPermissionsQr');
    }
  }

  const toggleSelectCertificate = (id) => {
    setSelectedCertificates((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const deleteSelectedCertificates = () => {
    const newCertificates = certificates.filter((cert) => !selectedCertificates.has(cert.id));
    setCertificates(newCertificates);
    setSelectedCertificates(new Set()); // Сбросить выбранные сертификаты
  };

  const saveChanges = async () => {
    await editPermissionsQr(); // Сохранить изменения
  };

  if (isAuthorized) {
    return (
            <div id="staff_cv" className="service">
                <ServiceBody>
                    <Table id="admin_staff_cv">
                        <THead title="Сертификаты для сервиса резюме" headers={headers.certificates} className="table-header" />
                        <TBody>
                            {certificates.map((el) => (
                                <TRow key={el.id}>
                                    <TData className="id-cell">
                                    {el.id}
                                    </TData>
                                    <TData className="full-name-cell">
    <Input
      type="text"
      value={el.fullName}
      onChange={(e) => handleFullNameChange(el.id, e.target.value)}
      placeholder="Полное имя сертификата"
    />
                                    </TData>
<TData className="short-name-cell">
    <Input
      type="text"
      value={el.shortName}
      onChange={(e) => handleShortNameChange(el.id, e.target.value)}
      placeholder="Короткое имя сертификата"
    />
</TData>
<TData className="checkbox-cell">
                                        <input
                                          type="checkbox"
                                          checked={selectedCertificates.has(el.id)}
                                          onChange={() => toggleSelectCertificate(el.id)}
                                        />
</TData>
                                </TRow>
                            ))}
                        </TBody>
                        <TFoot>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
  <Button
    onClick={saveChanges}
    style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }} // Отступ справа
  >
    Сохранить изменения
  </Button>
  <Button
    onClick={deleteSelectedCertificates}
    style={{ marginLeft: '10px' }} // Отступ слева
  >
    Удалить выбранные
  </Button>
                        </div>

                        </TFoot>
                    </Table>
                </ServiceBody>
            </div>
    );
  } return <Cap />;
}

export default AdminStaffCV;
