/* eslint-disable consistent-return */
/* eslint-disable default-param-last */
// eslint-disable-next-line import/no-cycle
import { configureStore } from '@reduxjs/toolkit';
import { catchHandler } from '../error_handling/error_handling';

// Функция создания action
// type - тип action,
// payload - данные для изменения состояния
export function createAction(type, payload) {
  store.dispatch({ type, payload });
}

// Reducer - функция, принимающая в качестве аргумента начальное состояние (state) и действие (action)
// Возвращает изменённое состояние в зависимости от типа action
// State - изначальное состояние (любой тип данных)
// Action - действие передающее тип и данные для изменения состояния

// Состояние данных о сервисах
const serviceDefault = {
  areas: [], // области отображения сервисов
  services: [], // сервисы
  services_access: [], // доступы к сервисам
  user_services: [], // сервисы, доступные пользователю
  aliases: [], // краткие наименования сервисов
};

function serviceReducer(state = serviceDefault, action) {
  try {
    switch (action.type) {
      case 'SET_SERVICES':
        return { ...state, services: [...action.payload] };

      case 'SET_ALIASES':
        return { ...state, aliases: [...action.payload] };

      case 'SET_AREAS':
        return { ...state, areas: [...action.payload] };

      case 'SET_SERVICES_ACCESS':
        return { ...state, services_access: [...action.payload] };

      case 'SET_USER_SERVICES':
        return { ...state, user_services: [...action.payload] };
      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'serviceReducer');
  }
}

// Состояние информации о пользователе
const authDefault = {
  auth: true, // результат аутентификации
  blocked: false, // сессия заблокирована
  is_logout: false, // состояние выхода из системы
  is_authenticating: true, // процесс аутентификации запущен
};

function authReducer(state = authDefault, action) {
  try {
    switch (action.type) {
      case 'SET_AUTH':
        return { ...state, auth: action.payload };

      case 'SET_LOG_OUT':
        return { ...state, is_logout: action.payload };

      case 'SET_IS_AUTHENTICATING':
        return { ...state, is_authenticating: action.payload };

      case 'SET_BLOCK_ROUTING':
        return { ...state, blocked: action.payload };

      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'usersReducer');
  }
}
// Состояние информации о пользователе
const userDefault = {
  current_user: '',
  user_info: {},
  new_user: {
    company: '',
    department: '',
    title: '',
    enabled: false,
  },
  edit_user: {},
  search_result: [],
  access: {},
  default_page: '',
};

function userReducer(state = userDefault, action) {
  try {
    switch (action.type) {
      case 'SET_CURRENT_USER':
        return { ...state, current_user: action.payload };

      case 'SET_USER_INFO':
        return { ...state, user_info: { ...action.payload } };

      case 'SET_SEARCH_RESULT':
        return { ...state, search_result: [...action.payload] };

      case 'CREATE_USER':
        return { ...state, new_user: { ...state.new_user, [action.payload.key]: action.payload.value } };

      case 'EDIT_USER':
        return { ...state, edit_user: { ...state.edit_user, [action.payload.key]: action.payload.value } };

      case 'SET_USER_ACCESS':
        return { ...state, access: { [action.payload.user]: [...action.payload.data] } };

      case 'SET_DEFAULT_PAGE':
        return { ...state, default_page: action.payload };

      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'usersReducer');
  }
}

// Оперативная информация
const operInfoDefault = {
  alias: '',
  url: '',
  // header: false,
  // page: false,
  // footer: false
};

function operInfoReducer(state = operInfoDefault, action) {
  try {
    switch (action.type) {
      case 'SET_OPER_INFO':
        return { ...state, ...action.payload };

      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'renderCheckReducer');
  }
}

// Данные таблицы логов
const logDefault = {
  period_logs: [],
  logs: [],
  logs_default: [],
  logs_search: [],
  log_default_date: '',
  log_start_date: '',
  log_end_date: '',
};

function logReducer(state = logDefault, action) {
  try {
    switch (action.type) {
      case 'SET_LOGS_DEFAULT':
        return { ...state, logs_default: [...action.payload] };

      case 'SET_PERIOD_LOGS':
        return { ...state, period_logs: [...action.payload] };

      case 'SET_LOGS':
        return { ...state, logs: [...action.payload] };

      case 'SET_DEFAULT_DATE':
        return { ...state, log_default_date: action.payload };

      case 'SET_START_DATE':
        return { ...state, log_start_date: action.payload };

      case 'SET_END_DATE':
        return { ...state, log_end_date: action.payload };

      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'logReducer');
  }
}

// Данные менеджера процессов
const pmDefault = {
  show_type: 'table',
  source_type: '',
  current_procedure: {},
};

function pmReducer(state = pmDefault, action) {
  try {
    switch (action.type) {
      case 'SET_SHOW_TYPE':
        return { ...state, show_type: action.payload };

      case 'SET_SOURCE_TYPE':
        return { ...state, source_type: action.payload };

      case 'SET_CURRENT_PROCEDURE':
        return { ...state, current_procedure: action.payload };

      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'renderCheckReducer');
  }
}

// Состояние видимости компонентов
const showDefault = { // состояние по умолчанию
  addUserForm: false,
  menu: false,
  help: false,
  select: {},
};

function showReducer(state = showDefault, action) {
  try {
    switch (action.type) {
      case 'TOGGLE_MENU':
        return { ...state, menu: action.payload };

      case 'TOGGLE_HELP':
        return { ...state, help: action.payload };

      case 'TOGGLE_SELECT':
        return { ...state, select: { [action.payload.key]: action.payload.value } };

      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'showReducer');
  }
}

// Данные сервиса РП
const probationControlDefault = { // состояние по умолчанию
  partners: [], // партнёры
  mentors: [], // наставники
  editing: false, // состояние режима редактирования
  employee: {}, // данные текущего сотрудника
  employee_log: [], // лог по текущему сотруднику
  edit_employee: {}, // изменяемые данные текущего сотрудника
  edit_employee_source: {}, // исходные данные текущего сотрудника
  dismissed_types: [], // Типы увольнений
  output_interview: {}, // Выходное интервью
  output_questionnaire: '', // Анкета выходного интервью
  output_logs: [], // История изменений карточки выходного интервью
  dismissal_user: {}, // Выбранная карточка сотрудника выходного интервью
};

function probationControlReducer(state = probationControlDefault, action) {
  try {
    switch (action.type) {
      case 'SET_PARTNERS':
        return { ...state, partners: action.payload };

      case 'SET_MENTORS':
        return { ...state, mentors: action.payload };

      case 'SET_EDITING':
        return { ...state, editing: action.payload };

      case 'SET_EMPLOYEE_INFO':
        return { ...state, employee: action.payload };

      case 'SET_EMPLOYEE_LOG':
        return { ...state, employee_log: action.payload };

      case 'EDIT_EMPLOYEE_INFO':
        return {
          ...state,
          edit_employee: action.payload,
          edit_employee_source: action.payload,
        };

      case 'SET_EDITING_FIELD':
        return {
          ...state,
          edit_employee: {
            ...state.edit_employee,
            ...action.payload,
          },
        };

      case 'SET_DISMISSED_TYPES':
        return { ...state, dismissed_types: action.payload };

      case 'SET_OUTPUT_INTERVIEW':
        return { ...state, output_interview: action.payload };

      case 'SET_OUTPUT_QUESTIONNAIRE':
        return { ...state, output_questionnaire: action.payload };

      case 'SET_OUTPUT_INTERVIEW_LOGS':
        return { ...state, output_logs: action.payload };

      case 'SET_DISMISSAL_USER':
        return { ...state, dismissal_user: action.payload };

      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'probationControlReducer');
  }
}

// Данные сервиса анкетирования
const questionnaireDefault = { // состояние по умолчанию
  directions: [], // все направления
  user_directions: [], // направления доступные пользователю
  current_direction: {}, // текущее направление (выбранное в селекте)
  categories: [], // все категории
  displayed_categories: [], // категории отображаемые в таблице
  questionnairies: [], // все анкеты
  displayed_questionnairies: [], // анкеты отображаемые в таблице
  questions: [], // все вопросы
  displayed_questions: [], // вопросы отображаемые в таблице
};

function questionnaireReducer(state = questionnaireDefault, action) {
  try {
    switch (action.type) {
      case 'SET_DIRECTIONS':
        return { ...state, directions: action.payload };

      case 'SET_USER_DIRECTIONS':
        return { ...state, user_directions: action.payload };

      case 'SET_CURRENT_DIRECTION':
        return { ...state, current_direction: action.payload };

      case 'SET_CATEGORIES':
        return { ...state, categories: action.payload };

      case 'SET_DISPLAYED_CATEGORIES':
        return { ...state, displayed_categories: action.payload };

      case 'SET_QUESTIONNAIRIES':
        return { ...state, questionnairies: action.payload };

      case 'SET_DISPLAYED_QUESTIONNAIRIES':
        return { ...state, displayed_questionnairies: action.payload };

      case 'SET_QUESTIONS':
        return { ...state, questions: action.payload };

      case 'SET_DISPLAYED_QUESTIONS':
        return { ...state, displayed_questions: action.payload };

      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'questionnaireReducer');
  }
}

// Данные страницы анкеты
const interviewDefault = { // состояние по умолчанию
  interview: { // данные интервью по умолчанию
    access_data: {}, // данные доступа
    questionnaire: {}, // информация об анкете
    categories: [], // категории вопросов
    questions: [], // вопросы
    answers: [], // варианты ответов
  },
  comment: '', // текущий комментарий, отображаемый в поле комментария
  is_wiki: false, // состояние параметра "справочник"
  is_finish: false, // состояние завершения опроса
  is_searching: false, // состояние поиска
  is_show_form_mobile: false, // состояние видимости формы с заголовками вопросов и поиском
  user_answers: [], // ответы пользователя в анкете
  current_question: null, // текущий вопрос
  current_category: null, // текущая категория
  output_type: null, // тип отображения
  is_show_question: false, // состояние видимости вопроса
  displayed_questions: [], // вопросы отображаемые в таблице
  questions_found: [], // найденные ответы (поиск по справочнику)
  break: false,
};

function interviewReducer(state = interviewDefault, action) {
  try {
    switch (action.type) {
      case 'SET_INTERVIEW':
        return { ...state, interview: action.payload };

      case 'SET_IS_WIKI':
        return { ...state, is_wiki: action.payload };

      case 'SET_COMMENT':
        return { ...state, comment: action.payload };

      case 'SET_IS_FINISH':
        return { ...state, is_finish: action.payload };

      case 'SET_IS_SEARCHING':
        return { ...state, is_searching: action.payload };

      case 'SET_IS_SHOW_FORM_MOBILE':
        return { ...state, is_show_form_mobile: action.payload };

      case 'SET_USER_ANSWERS':
        return { ...state, user_answers: action.payload };

      case 'SET_CURRENT_QUESTION':
        return { ...state, current_question: action.payload };

      case 'SET_OUTPUT_TYPE':
        return { ...state, output_type: action.payload };

      case 'SET_SEQUENCE':
        return { ...state, sequence: action.payload };

      case 'SET_CURRENT_CATEGORY_INTERVIEW':
        return { ...state, current_category: action.payload };

      case 'SET_IS_SHOW_QUESTION':
        return { ...state, is_show_question: action.payload };

      case 'SET_DISPLAYED_QUESTIONS_INTERVIEW':
        return { ...state, displayed_questions: action.payload };

      case 'SET_SEQUENCE_BREAK':
        return { ...state, break: action.payload };

      case 'SET_QUESTIONS_FOUND':
        return { ...state, questions_found: action.payload };

      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'interviewReducer');
  }
}

// Данные сервиса отчётов
const reportsDefault = { // состояние по умолчанию

  report_settings: {}, // настройки для получения отчета
  report_periоd: {}, // период отчёта
  current_questionnaire: {}, // выбранная анкета
  ext_report_access: false, // доступ к текущему отчёту
  current_component: 'basic', // текущий компонент отчётов (basic или ext)
  choosed_ext_report: null, // выбранный расширенный отчёт
  ext_reports_list: [], // список расширенных отчётов доступных пользователю
  is_show_chart: true, // параметр видимости графика
};

function reportsReducer(state = reportsDefault, action) {
  try {
    switch (action.type) {
      case 'SET_REPORT_SETTINGS':
        return { ...state, report_settings: action.payload };

      case 'SET_REPORT_PERIOD':
        return { ...state, report_periоd: action.payload };

      case 'SET_CURRENT_QUESTIONNAIRE':
        return { ...state, current_questionnaire: action.payload };

      case 'SET_CHOOSED_EXT_REPORT':
        return { ...state, choosed_ext_report: action.payload };

      case 'SET_CURRENT_COMPONENT':
        return { ...state, current_component: action.payload };

      case 'SET_EXT_REPORT_ACCESS':
        return { ...state, ext_report_access: action.payload };

      case 'SET_EXT_REPORTS_LIST':
        return { ...state, ext_reports_list: action.payload };

      case 'SET_SHOW_CHART':
        return { ...state, is_show_chart: action.payload };

      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'reportsReducer');
  }
}

// Данные проверки рендера страницы
const noticeDefault = {
  show: false,
  message: '',
  type: 'info',
};

function noticeReducer(state = noticeDefault, action) {
  try {
    switch (action.type) {
      case 'SHOW_NOTICE':
        return {
          ...state,
          show: true,
          message: action.payload.message,
          type: action.payload.type || 'info',
        };

      case 'HIDE_NOTICE':
        return {
          ...state,
          show: false,
          // message: '',
          // type: '',
        };

      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'renderCheckReducer');
  }
}

// Данные сервиса задач
const taskDefault = { // состояние по умолчанию
};

function taskReducer(state = taskDefault, action) {
  try {
    switch (action.type) {
      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'taskReducer');
  }
}

// Данные сервиса базы знаний
const kbDefault = { // состояние по умолчанию
  divisions: [], // все подразделения
  user_divisions: [], // подразделения доступные пользователю
  current_division: null, // выбранное подразделение
  active_scheme: {
    theme: null,
    question: null,
  }, // активные схемы выбранного подразделения
  choose: '', // состояние выбора вкладки
  is_tp_open: false, // тектовый процессор открыт?
  scheme_types: [{ id: 4, title: 'Тема' }, { id: 5, title: 'Вопрос' }], // типы схем
  essence_types_id: {
    division: 1, // подразделение
    theme: 2, // тема
    question: 3, // вопрос
    theme_scheme: 4, // схема темы
    question_scheme: 5, // схема вопроса
  }, // id cущностей
  founded_questions: null,
  ls_key: 'kb_search', // ключ в LocalStorage в котором хранится состояние поиска kb_search
};

function kbReducer(state = kbDefault, action) {
  try {
    switch (action.type) {
      case 'SET_DIVISIONS':
        return { ...state, divisions: action.payload };

      case 'SET_USER_DIVISIONS':
        return { ...state, user_divisions: action.payload };

      case 'SET_CURRENT_DIVISION':
        return { ...state, current_division: action.payload };

      case 'SET_IS_TP_OPEN':
        return { ...state, is_tp_open: action.payload };

      case 'SET_ACTIVE_SCHEME':
        return { ...state, active_scheme: action.payload };

      case 'SET_CHOOSED_COMPONENT':
        return { ...state, choose: action.payload };

      case 'SET_FOUNDED_KB_QUESTIONS':
        return { ...state, founded_questions: action.payload };

      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'kbReducer');
  }
}

// Состояние данных хелпдеска
const helpdeskDefault = {
  parameters: {}, // параметры сервиса хелпдеска из таблицы sd_parameters
};

function helpdeskReducer(state = helpdeskDefault, action) {
  try {
    switch (action.type) {
      case 'SET_SD_PARAMETERS':
        return { ...state, parameters: action.payload };

      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'helpDeskReducer');
  }
}

// Состояние данных системы оценок
const evDefault = {
  period: '', // финансовый год
};

function evReducer(state = evDefault, action) {
  try {
    switch (action.type) {
      case 'SET_PERIOD':
        return { ...state, period: action.payload };

      default:
        return state;
    }
  } catch (error) {
    catchHandler(error, 'evDeskReducer');
  }
}

// Создание хранилища
// Используется в HOC <Provider>
export const store = configureStore({
  reducer: {
    auth: authReducer,
    interview: interviewReducer,
    kb: kbReducer,
    logs: logReducer,
    notice: noticeReducer,
    oper_info: operInfoReducer,
    pm: pmReducer,
    probation: probationControlReducer,
    questionnaire: questionnaireReducer,
    reports: reportsReducer,
    services: serviceReducer,
    tasks: taskReducer,
    users: userReducer,
    visibility: showReducer,
    helpdesk: helpdeskReducer,
    evaluation: evReducer,
  },
});
