/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useRef, useState } from 'react';
import TicketProcessMessage from '../HelpDesk/Support/TicketCard/TicketProcess/TicketProcessMessage';
import SendForm from '../HelpDesk/Support/TicketCard/TicketProcess/SendForm';
import Button from '../../components/UI/Button/Button';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';

function Chat(props) {
  const { messageId, sdlUser, dir } = props;
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const messagesEndRef = useRef(null);
  const [currentStatus, setCurrentStatus] = useState('');
  const [messageDat, setMessageDat] = useState({
    info: {
      date_created: '2024-11-29 14:13',
      id: 27675,
      initiator: 'Митина Е.А.',
      created_by: 183401,
      status: 'Решена',
      status_id: 5,
      ticket_number: '291124069',
      title: 'ошибка в СЭД',
    },
    process: [{
      content: 'тестовое сообщение',
      date_created: '2024-11-29 14:13',
      files: ['image002.png'],
      id: 70350,
      message_type: 'received',
      sender_id: 680,
      sender_name: 'Шаматрина А.Н.',
      sender_type: 'initiator',
      title: 'Работа по сдл',
    },
    {
      content: 'тестовое сообщение2',
      date_created: '2024-11-29 14:26',
      id: 70357,
      message_type: 'send',
      sender_id: 680,
      sender_name: 'Митина Е.А.',
      sender_type: 'performer',
      title: 'Работа по сдл',
    }],
  }); // данные процесса по заявке

  const [messageData, setMessageData] = useState({ info: {}, process: [] });
  useEffect(() => {
    scrollToBottom();
  }, [messageData.process]);

  useEffect(() => {
    getMessageProcess(); // запросить информацию по заявке при каждом изменении messageId
  }, [messageId]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  async function getMessageProcess() {
    try {
      const reqData = { type: 'getMessageProcess' };
      setIsLoading(true); // загружается
      const result = await clientSendData('POST', `/get_message_process/${messageId}`, reqData); // запрос в БД
      if (result?.success) { // если запрос выполнен успешно
        const { info, process } = result;
        setMessageData({ info, process });
        setCurrentStatus(info.status_id);
      //  setCurrentUser(result.performer);
      }
    } catch (error) { // если ошибка
      catchHandler(error, 'getMessageProcess'); // обрабатываем ошибку
    } finally {
      setIsLoading(false); // загрузка завершена
    }
  }

  return (
    <div id="SDL-chat">
<div className="chat-title">
Общение по внутреннему сообщению №
{messageId}
<p style={{ marginLeft: '7px' }}>({messageData.info?.client ? messageData.info?.client : '-'})</p>
</div>
<div className="work-wrapper">
  <div className="block-A">
  <div className="ticket-process__content">
      <div className={`ticket-process__content_wrapper${isLoading ? ' upu__loading' : ''}`}>

      {currentStatus === 1 && <p style={{ color: 'darkgreen', textAlign: 'center', fontWeight: '500' }}>Дождитесь, когда СДЛ возьмет сообщение в работу. Тогда вы сможете начать общение.</p>}
     { [1, 2, 3, 4, 8].includes(currentStatus) && messageData.process?.map((item) => (
          <TicketProcessMessage
            key={item.id}
            date_created={item.date_created}
            subject={item.title}
            sender_type={item.sender_type}
            sender={item.sender_name}
            content={
              item.files && item.files.length > 0
                ? (
                  <>
                    {item.content}
                    <img
                      src="../../icons/button/paperclip.svg"
                      alt="paperclip"
                      title="Вложение"
                    />
                  </>
                )
                : item.content
            }
            message_type={item.message_type}
          />
     ))
      }

      <div ref={messagesEndRef} />
      </div>
  </div>

      <SendForm
        PODFT
        dir={dir}
        sdlUser={sdlUser}
        ticketID={messageId}
        ticketInfo={messageData.info}
        getTicketProcess={getMessageProcess}
        currentStatus={messageData.info.status_title}
      //  falsePerformer={183401}
      />
  </div>
</div>

    </div>
  );
}

export default Chat;
