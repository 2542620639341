import React, { useEffect, useState } from 'react';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import EvaluationCap from '../EvaluationCap';
import Application from './Application';
import Loader from '../../../../components/UI/Loader';

// Сервис оценок - страница заявления
function ApplicationPage(props) {
  const {
    applicationId, projectId, isAdmin, finalApp, location,
  } = props;
  const [isLoading, setIsLoading] = useState(false); // параметры поиска url
  const [applicationAccess, setApplicationAccess] = useState(null); // состояние доступа к ПО

  useEffect(() => {
    checkApplicationAccess();
  }, []);

  // Проверка доступа к заявлению
  async function checkApplicationAccess() {
    try {
      setIsLoading(true);

      // если приведённое к числу значение является NaN - уведомление
      if (Number.isNaN(applicationId)) alertFunction('incorrect_url_param', 'clientPost');
      else {
        const reqData = {
          type: 'checkApplicationAccess',
          application_id: applicationId,
          project_id: projectId,
          isAdmin,
        };
        const result = await clientSendData('POST', '/check_application_access', reqData);
        setApplicationAccess(result);
      }
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'checkApplicationAccess');
    }
  }

  if (isLoading) return <Loader />;
  switch (applicationAccess) {
    case ('no_application'): return <Application id={applicationId} projectId={projectId} location={location} />;
    case ('no_access'): return <EvaluationCap title="Нет доступа к проектной оценке" />;
    case ('no_evaluation'): return <EvaluationCap title="Такой анкеты не существует" />;
    case ('there_is_access'): return <Application id={applicationId} isAdmin={isAdmin} finalApp={finalApp} location={location} />;
    default: return null;
  }
}

export default ApplicationPage;
