import React from 'react';

/**
  * @component Карточка - Тело - настройка
  * @prop {title} string - Название поля
  * @prop {invalid} boolean - Параметр валидации для стилей (если true - название выделяется красным)
  * @prop {active} boolean - Параметр для стилей (если true - меняется максимальная высота и flex-direction на column )
  * @prop {loading} boolean - Параметр загрузки для стилей

*/
function CardSetting(props) {
  const {
    title, invalid, active, loading, children,
  } = props;

  // Подсветка обязательных полей
  const defineClass = () => {
    let result = 'upu-card__setting';
    if (active) result += ' upu-card__setting_active'; // изменения размера и направления контента
    if (invalid || invalid?.length === 0) result += ' upu-card__setting_invalid'; // подсветка красным
    if (loading) result += ' upu__loading'; // анимация загрузки
    return result;
  };

  return (
    <div className={defineClass()}>
      {title && (<p className="upu-card__setting-title">{title}</p>)}
      {children}
    </div>
  );
}

export default CardSetting;
