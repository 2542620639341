import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Document, AlignmentType, Packer, Table, TableCell, TableRow, WidthType, Paragraph, TextRun, TableBorders, BorderStyle, convertInchesToTwip, Header,
} from 'docx';
import JSZip from 'jszip';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { clientSendData } from '../../utils/functions/requests';
import { authorization } from '../../utils/functions/authenticate';
import { createAction } from '../../utils/redux/store';
import { setOperInfo } from '../../utils/functions/others';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { alertFunction } from '../../utils/functions/alertFunction'; // Импортируем alertFunction
import CV from './CV'; // Import the CV component
import PaginatedTable from '../../components/Table/PaginatedTable/PaginatedTable';
import './staffcv.scss';
import {
  orderedKeys,
} from './common';
import ServiceBody from '../../components/Service/ServiceBody';
import Loader from '../../components/UI/Loader';

const defaultTableState = {
  title: 'Резюме сотрудников',
  page: 1,
  limit: 50, // количество строк в таблице
};

/**
* @component Резюме сотрудников - Интерфейс главной страницы
*/

function StaffCV(isAdmin) {
  const tableId = 'staff-cv';
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [dataCV, setDataCV] = useState([]);
  const [allCertificates, setAllCertificates] = useState([]);
  const [levelLanguages, setLevelLanguages] = useState([]);
  const [nameLanguages, setNameLanguages] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска в url
  const [filter, setFilter] = useState([]);
  const [fileInputVisible, setFileInputVisible] = useState(false);
  const [selectedCV, setSelectedCV] = useState([]); // Изменено на массив
  const navigate = useNavigate();
  const [showCard, setShowCard] = useState(false);
  const [total, setTotal] = useState(0);
  const fileInputRef = useRef(null);
  const [checkedItems, setCheckedItems] = useState({});
  // Проверяем, выбраны ли все резюме
  const [allChecked, setAllChecked] = useState(false);
  let documentResult = null;// Объект для хранения сгенерированных документов

  const headers = [ // Заголовки таблицы
    { id: 1, title: 'ФИО', field: 'fullName' },
    { id: 2, title: 'Грейд', field: 'grade' },
    { id: 3, title: 'Отдел', field: 'department' },
    { id: 4, title: 'МВЗ', field: 'mvz' },
    { id: 5, title: 'Аттестаты и сертификаты', field: 'certificate' },
    { id: 6, title: 'Дата изменения', field: 'date_modifed' },
    { id: 7, title: 'Редактировать резюме', field: 'resume' },
  ];
  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem(tableId)) || defaultTableState,
  );

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState([]);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (content, event) => {
    setTooltipContent(content);
    setTooltipVisible(true);
    const { clientX, clientY } = event;
    setTooltipPosition({ top: clientY - 50, left: clientX + 10 }); // Позиционируем подсказку немного ниже и правее курсора
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  useEffect(() => {
    localStorage.setItem(tableId, JSON.stringify(tableState));
    getStaffCV();
  }, [tableState]);

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    setIsLoading(true);
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
    // if (checkAuthorization) await getSdInterfaces(); // получить интерфейсы пользователя
    setIsLoading(false);
  }

  useEffect(() => {
    // проверка параметров url при их изменении (для открытия карточки)
    if (searchParams.size > 0) { // если есть какие то параметры
      // если url содержит параметр поиска "ticket" открывается карточка вопроса
      if (searchParams?.get('user')) setShowCard(true);
      // else setSearchParams(''); // иначе это другие параметры - сбрасываем их
    }
  }, [searchParams]);

  // Запрос данных при изменении фильтров
  useEffect(() => {
    const fetchData = async () => {
      await getStaffCV(); // Загружаем данные при изменении фильтров
    };
    fetchData();
  }, [filter]); // Зависимость от filter

  useEffect(() => {
    // Проверяем, есть ли вообще элементы в checkedItems
    const totalChecked = Object.keys(checkedItems).length > 0 && Object.values(checkedItems).every((isChecked) => isChecked);
    setAllChecked(totalChecked); // Устанавливаем allChecked в true, если все чекбоксы выбраны
  }, [checkedItems]);

  useEffect(() => {
    if (allCertificates.length > 0) {
      try {
        const allCertificates2 = JSON.parse(allCertificates);
        const filtersData2 = {
          certificate: allCertificates2.map((item) => ({
            id: item.id,
            title: item.shortName,
            value: false,
          })),
        };
        setFilter(filtersData2);
      } catch (error) {
        console.error('Ошибка парсинга JSON:', error);
      }
    }
  }, [allCertificates]); // Зависимость от allCertificates

  async function getStaffCV() {
    try {
      const reqData = {
        type: 'getStaffCV',
        tableState,
        total,
        filter,
      };

      const result = await clientSendData('POST', '/get_staff_cv', reqData);
      if (result && result.data) {
        setAllCertificates(result.certificates[0].certificate);
        setLevelLanguages(result.languages[0].language);
        setNameLanguages(result.languages_name[0].language_name);
        setUserId(result.user_id);
        const updatedResult = result.data.map((item) => {
          // Создаем новый объект cv без ключа "Дата рождения"
          const { 'Дата рождения': _, ...newCv } = item.cv;
          return {
            ...item,
            cv: newCv, // Возвращаем новый объект cv
          };
        });
        setSelectedCV(updatedResult);

        const formattedData = result.data.map((item) => ({
          id: item.id,
          fullName: item.cv['Ф.И.О.'],
          grade: item.extensionattribute11,
          department: item.department,
          mvz: item.extensionattribute13,
          certificate: item.cv.filter,
          date_modifed: new Date(item.date_modifed).toLocaleDateString(),
          resume: 'Просмотреть резюме',
        }));
        setDataCV(formattedData);
        setTotal(result.total); // Обновляем общее количество записей
      }
    } catch (error) {
      catchHandler(error, 'getStaffCV');
    }
  }

  let uploadedFiles = [];
  let totalFilesCount = 0;

  const createNewCVs = async () => {
    try {
      // Создаем объект для отправки
      const reqData = {
        type: 'createNewCV',
        app_side: 'client',
      };

      // Отправляем данные на сервер
      const result = await clientSendData('POST', '/create_new_cv', reqData);

      // Обработка ответа от сервера
      if (result.success) {
        await alertFunction('resume_create_success', 'client'); // Уведомление об успешном создании резюме
      } else {
        await alertFunction('resume_create_error', 'client'); // Уведомление об ошибке
      }
    } catch (error) {
      catchHandler(error, 'createNewCV'); // Обработка ошибок
    }
  };

  const uploadFile = async (file) => {
    try {
      const reader = new FileReader();
      reader.onloadend = async (e) => {
        const binaryArray = new Uint8Array(e.target.result);
        uploadedFiles.push(binaryArray);

        if (uploadedFiles.length === totalFilesCount) {
          const reqData = {
            type: 'updateStaffCV',
            DataCV: uploadedFiles,
          };

          const result = await clientSendData('POST', '/update_staff_cv', reqData);
          if (result.success) {
            await alertFunction('file_upload_success', 'client'); // Уведомление об успешной загрузке
            setFileInputVisible(false);
            const newData = await getStaffCV(); // Получаем новые данные
            setTableState({ ...tableState, data: newData }); // Обновляем состояние с новыми данными
          } else if (result.message === 'Пользователь не найден.') {
            await alertFunction('not_user', 'client'); // Уведомление об ошибке
          } else {
            await alertFunction('file_upload_error', 'client'); // Уведомление об ошибке
          }
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      catchHandler(error, 'uploadFile');
    }
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    totalFilesCount = files.length;

    if (files.length > 0) {
      uploadedFiles = [];
      for (const file of files) {
        // eslint-disable-next-line no-await-in-loop
        await uploadFile(file);
      }
    }
  };

  const generateWordDocument = async (id) => {
    const selectedResume = selectedCV.find((cv) => cv.id === id);

    if (!selectedResume) {
      console.error('Резюме не найдено');
      return;
    }

    const rows = [];

    const defaultFontStyle = {
      font: 'Microsoft Sans Serif', // Название шрифта
      size: 18, // Размер шрифта (в пунктах)
    };

    // Создаем колонтитул
    const header = new Header({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: 'РЕЗЮМЕ',
              bold: true,
              font: 'Microsoft Sans Serif', // Задаем шрифт для колонтитула
              size: 22, // Размер шрифта для колонтитула (можно настроить)
            }),
          ],
          alignment: AlignmentType.RIGHT, // Выравнивание по правому краю
        }),
      ],
    });

    orderedKeys.forEach((key) => {
      if (selectedResume.cv[key] !== undefined) {
        const value = selectedResume.cv[key];

        // Пропускаем пустые значения
        if (value === undefined || value === null || (Array.isArray(value) && value.length === 0) || (typeof value === 'string' && value.trim() === '')) {
          return; // Пропускаем это поле
        }

        if (Array.isArray(value) && key === 'Образование и профессиональная квалификация') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 3,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
                columnSpan: 2,
              }),
            ]);
          });
        } else if (Array.isArray(value) && key === 'Аттестаты и сертификаты') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 3,
            }),
          ]);

          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
                width: { size: 2000, type: WidthType.DXA }, // Задаем ширину для первой ячейки
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
                width: { size: 6000, type: WidthType.DXA }, // Задаем ширину для второй ячейки
                borders: {
                  right: {
                    style: BorderStyle.SINGLE, // Стиль границы
                    size: 100, // Устанавливаем толщину границы в 0
                    color: 'FFFFFF', // Цвет границы (белый, чтобы быть невидимым на белом фоне)
                  },
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [new TextRun({ text: item.number, ...defaultFontStyle })],
                    alignment: AlignmentType.RIGHT, // Выравнивание текста вправо
                  }),
                ],
                width: { size: 1000, type: WidthType.DXA }, // Задаем ширину для третьей ячейки
                borders: {
                  left: {
                    style: BorderStyle.SINGLE, // Стиль границы
                    size: 100, // Устанавливаем толщину границы
                    color: 'FFFFFF', // Цвет границы (белый, чтобы быть невидимым на белом фоне)
                  },
                },
              }),
            ]);
          });
        } else if (Array.isArray(value) && key === 'Участие в профессиональных сообществах') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 3,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.pozition, ...defaultFontStyle })] })],
              }),
            ]);
          });
        } else if (Array.isArray(value) && key === 'Сведения о трудовой деятельности') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 3,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
                columnSpan: 2,
              }),
            ]);
          });
        } else if (Array.isArray(value) && (key === 'Ключевые компетенции')) {
          // Если ключ совпадает, добавляем заголовок
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 3,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);

          // Добавляем массив значений в виде списка с буллитами
          const bulletList = value.map((item) => new Paragraph({
            children: [new TextRun({ text: `• ${item}`, ...defaultFontStyle })],
          }));

          // Добавляем список в новую ячейку, занимающую две колонки
          rows.push([
            new TableCell({
              children: bulletList,
              columnSpan: 3,
            }),
          ]);
        } else if (Array.isArray(value) && ((key === 'Основные проекты'))) {
          // Если ключ совпадает, добавляем заголовок
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 3,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);

          // Добавляем массив значений в виде списка с буллитами
          const bulletList = value.map((item) => new Paragraph({
            children: [new TextRun({ text: `• ${item}`, ...defaultFontStyle })],
          }));

          // Добавляем список в новую ячейку, занимающую две колонки
          rows.push([
            new TableCell({
              children: bulletList,
              columnSpan: 3,
            }),
          ]);
        } else if (key === 'Номер квалификационного аттестата аудитора' || key === 'Номер квалификационного сертификата аудитора' || key === 'Номер квалификационного сертификата налогового консультанта'
         || key === 'Наличие квалификационного аттестата главного бухгалтера' || key === 'Номер квалификационного аттестата ИПБ профессионального главного бухгалтера коммерческой организации' || key === 'Номер квалификационного аттестата ИПБ профессионального финансового директора' || key === 'Номер квалификационного аттестата ИПБ профессионального главного бухгалтера – эксперта в области внутреннего контроля') {
          rows.push([
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: `${key}`, bold: true, ...defaultFontStyle }),
                    new TextRun({ text: `: ${value}`, ...defaultFontStyle }),
                  ],
                }),
              ],
              columnSpan: 3,
            }),
          ]);
        } else if (key === 'Стаж финансово-экономической деятельности' || key === 'Стаж юридической деятельности' || key === 'Стаж оценочной деятельности') {
          rows.push([
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: `${key}`, bold: true, ...defaultFontStyle }),
                    new TextRun({ text: `: ${value}`, ...defaultFontStyle }),
                  ],
                }),
              ],
              columnSpan: 3,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
        } else if (Array.isArray(value) && (key === 'Знание иностранных языков')) {
          // Если ключ совпадает, добавляем заголовок
          rows.push([
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle }), // Ключ
                    new TextRun({ text: ` ${value.map((item) => `${item.language} — ${item.level}`).join(', ')}`, ...defaultFontStyle }), // Значения
                  ],
                }),
              ],
              columnSpan: 3, // Ячейка занимает три колонки
              borders: {
                bottom: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
        } else if (key === 'Достоверность сведений, представленных в резюме, подтверждаю') {
          // Извлечение даты из value
          const dateRegex = /(\d{1,2}\.\d{1,2}\.\d{4}|\d{1,2}\s+(января|февраля|марта|апреля|мая|июня|июля|августа|сентября|октября|ноября|декабря)\s+\d{4})/i;
          const dateMatch = value.match(dateRegex);
          const date = dateMatch ? dateMatch[0] : 'Введите дату';

          // Извлечение оставшегося текста
          const remainingText = value.replace(dateRegex, '').trim();
          // Разделяем текст на части
          const parts = remainingText.split('Игоревна');

          // Создание ячеек
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, ...defaultFontStyle })] })],
              columnSpan: 3,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);

          // Ячейка с оставшимся текстом
          rows.push([
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${parts[0].trim()} Игоревна`,
                      ...defaultFontStyle,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${parts[1].trim()}       ${date}`, // Объединяем текст и дату
                      ...defaultFontStyle,
                    }),
                  ],
                }),
              ],
              // width: { size: 7000, type: WidthType.DXA }, // Установка ширины ячейки с оставшимся текстом
              columnSpan: 3, // Объединяем ячейку с соседней
            }),
          ]);
        } else if (key === 'Ф.И.О.') {
          // Для обычных ключей
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: value, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),

          ]);
        } else {
          // Для обычных ключей
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
            }),
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: Array.isArray(value) ? value.join(', ') : value, ...defaultFontStyle })] })],
              columnSpan: 2,
            }),
          ]);
        }
      }
    });

    const table = new Table({
      rows: rows.map((row) => new TableRow({ children: row })),
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: TableBorders.NONE,
      margins: {
        top: convertInchesToTwip(0.1),
        bottom: convertInchesToTwip(0.1),
        right: convertInchesToTwip(0),
        left: convertInchesToTwip(0),
      },
    });

    const doc = new Document({
      sections: [{
        properties: {},
        headers: {
          default: header, // Добавляем колонтитул в секцию
        },
        children: [table],
      }],
    });

    // Извлечение Ф.И.О. для имени файла
    const fullName = selectedResume.cv['Ф.И.О.'] || 'Резюме';
    const sanitizedFileName = fullName.replace(/[^a-zA-Z0-9а-яА-ЯёЁ\s]/g, '').replace(/\s+/g, '_').trim();

    // Генерация документа
    const blob = await Packer.toBlob(doc);

    // Возвращаем объект с blob и именем файла
    documentResult = {
      blob,
      fileName: `${selectedResume.cv['Ф.И.О.']}.docx`,
    };
  };

  const downloadSelectedResumes = async (ids) => {
    const idsArray = Object.keys(ids).filter((key) => ids[key] === true).map(Number);
    const selectedResumes = selectedCV.filter((cv) => idsArray.includes(cv.id));

    if (selectedResumes.length === 0) {
      console.error('Резюме не найдены для переданных ID:', idsArray);
      return;
    }

    if (selectedResumes.length === 1) {
      await generateWordDocument(selectedResumes[0].id); // Вызов функции, результат будет записан в глобальную переменную
      if (documentResult) {
        const url = URL.createObjectURL(documentResult.blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = documentResult.fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        console.error('Ошибка: документ не был сгенерирован.');
      }
    } else {
      const zip = new JSZip();
      const promises = selectedResumes.map(async (resume) => {
        await generateWordDocument(resume.id); // Вызов функции
        if (documentResult) {
          zip.file(documentResult.fileName, documentResult.blob);
        } else {
          console.error(`Ошибка: документ для резюме с ID ${resume.id} не был сгенерирован.`);
        }
      });

      await Promise.all(promises);

      zip.generateAsync({ type: 'blob' }).then((content) => {
        const url = URL.createObjectURL(content);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'resumes.zip';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }).catch((error) => {
        console.error('Ошибка при создании ZIP-архива:', error);
      });
    }
  };

  // function openCard(id) {
  //   const cv = dataCV.find((item) => item.id === id); // Используйте dataCV для поиска
  //   if (cv) {
  //     setSelectedCV(cv); // Устанавливаем выбранное резюме
  //     setShowCard(true);
  //     setSearchParams({ user: id }); // Устанавливаем параметр в URL
  //   }
  // }

  const openCard = (id) => {
    const cv = dataCV.find((item) => item.id === id); // Используйте dataCV для поиска
    if (cv) {
      // Проверяем, совпадает ли userId с id резюме
      if (isAuthorized || userId === cv.id) {
        setSelectedCV(cv); // Устанавливаем выбранное резюме
        setShowCard(true);
        setSearchParams({ user: id, location: 'admin' }); // Устанавливаем параметр в URL
      } else {
        alert('У вас нет доступа к этому резюме.'); // Сообщение об отсутствии доступа
      }
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click(); // Программно вызываем клик на input
  };

  // console.log(nameLanguages);
  if (isLoading) return <Loader />; // если идет загрузка - показать загрузчик
  if (isAuthorized) {
    return (
    <div id="staff_cv" className="service">
      {/* <div className="service__body"> */}
      {showCard
      && <CV
        meetingId={selectedCV?.id}
        handler={getStaffCV}
        // userId={userId}
        setShow={setShowCard}
        refreshTable={() => setTableState({ ...tableState })}
        isAdmin={isAdmin}
      />
  }
  {!showCard
        && <ServiceBody>
        <PaginatedTable
          id="cv_results"
          headers={headers}
          state={tableState}
          setState={setTableState}
          total={total}
          filterFields={filter}
          filterFieldsFunc={setFilter}
          footerButton={{
            title: 'Скачать выбранные резюме',
            handler: () => downloadSelectedResumes(checkedItems),
          }}
          // footerButtonUpload={{
          //   title: 'Создать резюме для новых сотрудников',
          //   // handler: () => handleImageClick(), // Загрузить резюме сотрудника в docx
          //   handler: () => createNewCVs(),
          // }}
          optButton={{
            title: allChecked ? 'Снять выделение' : 'Выбрать все резюме',
            handler: () => {
              const newCheckedItems = {};
              const newAllChecked = !allChecked; // Переключаем состояние allChecked

              dataCV.forEach((cv) => {
                newCheckedItems[cv.id] = newAllChecked; // Устанавливаем состояние для каждого резюме
              });

              setCheckedItems(newCheckedItems); // Обновляем состояние чекбоксов
              setAllChecked(newAllChecked); // Обновляем состояние allChecked
            },
          }}
        >
  {dataCV.map((cv) => (
    <TRow key={cv.id}>
      {headers.map((header) => {
        const { id, field } = header; // Деструктуризация объекта колонки
        const value = cv?.[field]; // Значение текущего ключа в объекте строки

        return (
          <TData
            key={id}
            loading={isLoading}
          >
            {header.field === 'resume' ? (
              <div onClick={() => openCard(cv.id)} style={{ cursor: 'pointer' }}>
                <img src="../../icons/button/edit.svg" alt="file" />
              </div>
            ) : header.field === 'certificate' ? (
              cv.certificate && cv.certificate.length > 0 ? (
                <span
                  onMouseEnter={(e) => handleMouseEnter(cv.certificate, e)}
                  onMouseLeave={handleMouseLeave}
                >
                ✔️
                </span>
              ) : (
                <span>-</span> // Если сертификатов нет
              )
            ) : (
              // Обработка значения, если оно является массивом
              typeof value === 'object' ? value.join(', ') : value ?? '-'
            )}
          </TData>

        );
      })}

      {/* Последняя ячейка для поиска */}
      <TData loading={isLoading}>
        <div style={{ display: 'flex', alignItems: 'right' }}>
          <div onClick={() => downloadSelectedResumes({ [cv.id]: true })} style={{ cursor: 'pointer', alt: 'скачать резюме' }}>
            <img src="../../icons/file-download.png" alt="скачать резюме" />
          </div>
          <input
            type="checkbox"
            id={`checkbox-${cv.id}`}
            checked={checkedItems[cv.id] || false}
            onChange={() => setCheckedItems((prev) => ({ ...prev, [cv.id]: !prev[cv.id] }))}
            style={{ marginLeft: '10px' }}
          />
        </div>
      </TData>
    </TRow>
  ))}

  <TRow />

        </PaginatedTable>
           </ServiceBody>
  }
        {tooltipVisible && (
          <div
            className="info-card"
            style={{
              top: tooltipPosition.top,
              left: tooltipPosition.left,
            }}
          >
    <ul style={{ listStyleType: 'none', paddingLeft: '0', margin: 0 }}>
      {Array.isArray(tooltipContent) ? (
        tooltipContent.map((item, index) => (
          <li key={index} style={{ margin: '5px 0' }}>
            {'- '}
{' '}
{item}
{' '}
{/* Добавляем тире перед элементом списка */}
          </li>
        ))
      ) : (
        <li style={{ margin: '5px 0' }}>
{'– '}
{' '}
{tooltipContent}
        </li> // Тире для одиночного элемента
      )}
    </ul>
          </div>
        )}

        <input
          type="file"
          accept=".docx"
          multiple
          onChange={handleFileChange}
          ref={fileInputRef} // Привязываем реф к input
          style={{ display: 'none' }}
        />
      {/* </div> */}
    </div>

    );
  }
}
export default StaffCV;
