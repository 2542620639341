import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import CardFooter from '../../components/Card/CardFooter';
import Button from '../../components/UI/Button/Button';
import CardSetting from '../../components/Card/CardSetting';
import Input from '../../components/UI/Input';
import Select from '../../components/UI/Select';
import SingleFileUpload from '../../components/UI/FileUpload/SingleFileUpload';
import './PODFT.scss';
import BulkFileUpload from '../../components/UI/FileUpload/BulkFileUpload';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { alertFunction } from '../../utils/functions/alertFunction';
import CheckBox from '../../components/UI/CheckBox';
import { stringMatch } from '../../utils/functions/stringHandling';

function NewMessageCard(props) {
  const { setShowCreateCard, getMessages, tasks1form } = props;
  const user_id = useSelector((state) => state?.oper_info?.user_id); // id текущего пользователя
  const [users, setUsers] = useState([]);
  const [extraInfo, setExtraInfo] = useState('');
  const [searchParams, setSearchParams] = useSearchParams(''); // параметры поиска url

  // const operationTypes = [
  //   { id: 1, title: 'Разовая необычная операция' },
  //   { id: 2, title: 'Иные операции (сделки)' },
  // ];
  // const operationCriteria = [
  //   { id: 1, title: 'Критерий1' },
  //   { id: 2, title: 'Критерий2' },
  // ];
  const currency = [{ id: 1, title: 'Рубль' }, { id: 2, title: 'Евро' }, { id: 3, title: 'Доллар' }];
  const [operationTypes, setOperationTypes] = useState([]);
  const [operationCriteria, setOperationCriteria] = useState([]);
  const [displayCriteria, setDisplayCriteria] = useState([]);
  const [isDict, setIsDict] = useState(false); // состояние показа справочника критериев
  const [criteriaArr, setCriteriaArr] = useState([]); // Набор критериев выбора операции
  const [newMail, setNewMail] = useState({
    client: '',
    task: '',
    contractNum: '',
    releaseDate: '',
    taskSupervisor: '',
    operationType: '',
    operTypeCriteria: [],
    operationContent: '',
    additionalData: '',
    operationComment: '',
    executionDate: '',
    operationSumm: '',
    currency: '',
    foreignExecutor: '',
    employee: '',
  }); // новое письмо
  const [acceptFileExtension, setAcceptFileExtension] = useState([]); // разрешения файлов
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    getUsers();
    get1FormTasks();
  }, []);

  function closeHandler() {
    setShowCreateCard(false); // закрытие карточки
  }

  // Обработка прикрепленного вложения и запись в объект нового email
  function attachmentHandler(file) {
    for (const f of file) {
      const reader = new FileReader(); // API чтения файлов
      reader.onloadend = (e) => {
        setAttachments((prev) => [
          ...prev, // развернуть существующее состояние
          { path: e.target.result, filename: f.name },
        ]);
      };
      reader.readAsDataURL(f); // Прочитать как DataURL ("data:image/png;base64,iVBORw...")
    }
  }
  // console.log(attachments);

  async function getUsers() {
    try {
      const reqData = {
        type: 'podftGetUsers',
      };
      const result = await clientSendData('POST', '/podft_get_users', reqData); // запрос в БД
      if (result) {
        setUsers(result.data);
        setOperationTypes(result.oper_types);
        setOperationCriteria(result.type_criteria);
        setDisplayCriteria(result.type_criteria);
      }
    } catch (error) {
      catchHandler(error, 'podftGetUsers'); // обработка ошибок
    }
  }

  // Если каждый из ключей в массиве прошел валидацию
  const validateMail = () => [
    'client',
    'task',
    'contractNum',
    'releaseDate',
    'taskSupervisor',
    'operationType',
    'operTypeCriteria',
    'operationContent',
    'additionalData',
    'operationComment',
    'executionDate',
    'operationSumm',
    'currency',
    'foreignExecutor',
    'employee',
  ].every((key) => validateField(key));
  // Валидация отдельного ключа
  const validateField = (field) => Boolean(newMail[field] !== '');

  const [requiredFields, setRequiredFields] = useState(false);
  // Сохранить новое сообщение
  async function saveNewMessage() {
    try {
      if (validateMail() && attachments.length > 0) {
        setRequiredFields(false);
        const reqData = {
          type: 'podftMessageSave',
          action: 'create',
          message: newMail,
          attachments,
          employee: user_id,
        };
        const result = await clientSendData(
          'POST',
          '/podft_message_save',
          reqData,
        ); // запрос в БД
        if (result) {
          await alertFunction('inner_message_created', 'clientPost');
          await getMessages();
          // setShowCreateCard(false);
          // setNewMail((prev) => ({
          //   ...prev,
          //   client: '',
          //   contractNum: '',
          //   releaseDate: '',
          //   taskSupervisor: '',
          //   operationType: '',
          //   operTypeCriteria: '',
          //   operationContent: '',
          //   additionalData: '',
          //   operationComment: '',
          //   executionDate: '',
          //   operationSumm: '',
          //   currency: '',
          //   foreignExecutor: '',
          //   employee: '',
          // }));
          setShowCreateCard(false);
        }
      } else {
        await alertFunction('required_fields', 'clientPost');
        setRequiredFields(true);
      }
    } catch (error) {
      catchHandler(error, 'podftMessageSave'); // обработка ошибок
    }
  }

  const [tasks, setTasks] = useState([]); // задания из 1формы
  async function get1FormTasks() {
    try {
      const reqData = {
        type: 'getPODFTtasks',
      };
      const result = await clientSendData('POST', '/get_podft_tasks', reqData); // запрос в БД
      if (result) {
        setTasks(result.data);
      }
    } catch (error) {
      catchHandler(error, 'getPODFTtasks'); // обработка ошибок
    }
  }

  // Добавить критерий
  function addCriteria(title, criterion) {
    setCriteriaArr([...criteriaArr, title]);
    setNewMail({ ...newMail, operTypeCriteria: [...newMail.operTypeCriteria, { code: title, title: criterion }] });
  }

  // Удалить критерий из списка
  function deleteItem(item) {
    const filtered = criteriaArr.filter((i) => i !== item.code);
    setCriteriaArr(filtered);
    setNewMail({ ...newMail, operTypeCriteria: newMail.operTypeCriteria.filter((i) => i.code !== item.code) });
  }

  const findCriterion = (e) => {
    try {
      const filterResult = operationCriteria.filter((item) => stringMatch(e.target.value, String(item.title)));
      if (filterResult.length > 0) {
        setDisplayCriteria(filterResult);
      } else {
        setDisplayCriteria(operationCriteria);
      }
    } catch (error) {
      catchHandler(error, 'searchHandler');
    }
  };

  if (isDict) {
    return <Card id="new_podft_message_card" setShow={setIsDict}>
  <CardHeader>
    <p style={{
      width: '100%', textAlign: 'center', fontSize: '18px', fontWeight: '500', paddingBottom: '15px',
    }}
    >
Справочник критериев выбора типа операции
    </p>
  </CardHeader>
  <CardBody>
    <div style={{ background: 'white', borderRadius: '7px' }}>
    <span style={{
      display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '15px', marginBottom: '15px',
    }}
    >
        {/* <p style={{ fontWeight: 'bold', color: 'darkgreen' }}>Поиск по коду критерия</p> */}
        <Input placeholder="Найти критерий по коду" onInput={(e) => findCriterion(e)} />
    </span>

    {displayCriteria.map((c) => <div
      key={c.id}
      style={{
        borderBottom: '1px solid grey', display: 'flex', flexDirection: 'row', paddingBottom: '5px', marginTop: '5px', alignItems: 'center',
      }}
    >
      <p style={{ width: '5%', fontWeight: 'bold', marginLeft: '15px' }}>{c.title}</p>
{' '}
<p style={{ width: '85%', marginRight: '15px' }}>{c.criterion}</p>
      <CheckBox id={c.id} onChange={() => addCriteria(c.title, c.criterion)} checked={Boolean(criteriaArr.includes(c.title))} />
{/* <CardSetting title={c.title}>{c.criterion}</CardSetting> */}
<span />
                                </div>)}
    </div>
  </CardBody>
  <CardFooter>
    <Button onClick={() => setIsDict(false)}>Завершить выбор</Button>
  </CardFooter>
           </Card>;
  }

  return (
    <Card setShow={closeHandler} id="new_podft_message_card">
      <CardHeader>
       {requiredFields && (
        <div
          className="headerAlert"
          style={{
            display: 'flex', justifyContent: 'center', width: '100%', fontWeight: 'bold', color: 'red', fontSize: '15px',
          }}
        >
        <p>Заполните обязательные поля!</p>
        </div>
       )}
      </CardHeader>
      <CardBody>
        <div className="card_container">
          <div className="a">
            {/* Наименование клиента */}
            <CardSetting title="Наименование клиента:" invalid={Boolean(!validateField('client'))}>
              {/* <div className={!validateField('client') ? 'required' : ''}> */}
              <Input
                disabled
                defaultValue={newMail.client}
                onChange={(e) => setNewMail({ ...newMail, client: e.target.value })
                }
              />
              {/* </div> */}
            </CardSetting>

            {/* Номер задания */}
            <CardSetting title="№ задачи из АСК:" invalid={Boolean(!newMail.form1_task_id)}>
              <Select
                id="task_id"
                array={tasks}
                search
                defaultValue={newMail.form1_task_id}
                onChoose={(e) => setNewMail({
                  ...newMail,
                  task: e.task_title,
                  form1_task_id: e.id,
                  client: e.client_title,
                  contractNum: e.contract_title,
                  releaseDate: e.document_date,
                  taskSupervisor: { id: e.task_supervisor_id, name: e.task_supervisor_title },
                  employee: { id: e.task_supervisor_id, name: e.task_supervisor_title },
                })}
              />
            </CardSetting>

            {/* Задание */}
            <CardSetting title="Задание" invalid={Boolean(!validateField('task'))}>
              {/* <Input
                defaultValue={newMail.task}
                onChange={(e) => setNewMail({ ...newMail, task: e.target.value })
                }
              /> */}
              <Input
                onPointerEnter={() => setExtraInfo('task')}
                onPointerLeave={() => setExtraInfo('')}
                disabled
                defaultValue={newMail.task}
                // onChoose={(e) => setNewMail({
                //   ...newMail,
                //   task: e.title,
                //   client: e.client_title,
                //   contractNum: e.contract_title,
                //   form1_task_id: e.id,
                //   taskSupervisor: { id: e.task_supervisor_id, name: e.task_supervisor_title },
                //   employee: { id: e.task_supervisor_id, name: e.task_supervisor_title },
                // })}
              />
              {(extraInfo === 'task' && newMail.task !== '') && (
                      <div className="reference">
                        {newMail.task}
                      </div>
              )}
            </CardSetting>

            {/* Номер договора */}
            <CardSetting title="Номер договора:" invalid={Boolean(!validateField('contractNum'))}>
              <Input
                onPointerEnter={() => setExtraInfo('contractNum')}
                onPointerLeave={() => setExtraInfo('')}
                disabled
                defaultValue={newMail.contractNum}
                onChange={(e) => setNewMail({ ...newMail, contractNum: e.target.value })
                }
              />
              {(extraInfo === 'contractNum' && newMail.contractNum !== '') && (
                      <div className="reference">
                        {newMail.contractNum}
                      </div>
              )}
            </CardSetting>

            {/* Дата выпуска отчетного документа  */}
            <CardSetting title="Дата выпуска отчетного документа:" invalid={Boolean(!validateField('releaseDate'))}>
              <Input
                type="date"
                defaultValue={newMail.releaseDate}
                onChange={(e) => setNewMail({ ...newMail, releaseDate: e.target.value })
                }
              />
            </CardSetting>

            {/* Руководитель задания */}
            <CardSetting title="Руководитель задания:" invalid={Boolean(!validateField('taskSupervisor'))}>
              {users.length > 0 && (
                <Select
                  id="operation_supervisor"
                  disabled
                  defaultValue={
                    newMail.taskSupervisor.name || 'Выберите руководителя'
                  }
                  onChoose={(e) => setNewMail({
                    ...newMail,
                    taskSupervisor: { id: e.id, name: e.title },
                  })
                  }
                  array={users}
                  search
                />
              )}
            </CardSetting>

            {/* Вид операции */}
            <CardSetting title="Вид операции:" invalid={Boolean(!validateField('operationType'))}>
              <Select
                id="operation_types"
                array={operationTypes}
                onChoose={(e) => setNewMail({ ...newMail, operationType: e.title })
                }
                defaultValue={newMail.operationType}
              />
            </CardSetting>

            {/* Критерии выбора вида операции */}
            <div className="criteria-wrapper">
            <CardSetting title="Критерии выбора вида операции:" invalid={Boolean(newMail.operTypeCriteria.length === 0)}>
              {/* <Select
                // onPointerEnter={() => setExtraInfo('criterion')}
                // onPointerLeave={() => setExtraInfo('')}
                search
                id="operation_types_criteria"
                array={operationCriteria}
                defaultValue={newMail.operTypeCriteria.length > 0 ? newMail.operTypeCriteria[newMail.operTypeCriteria.length - 1].title : 'Выберите вариант'}
                onChoose={(e) => addCriteria(e)}
              /> */}
              {/* {(extraInfo === 'criterion' && newMail.operTypeCriteria.length > 0) && (
                      <div className="reference">
                        {newMail.operTypeCriteria[newMail.operTypeCriteria.length - 1].title}
                      </div>
              )} */}
              <p className="clickable" style={{ width: '40%' }} onClick={() => setIsDict(true)}>Выбрать критерии</p>
              <div className="criteria-list">
               {newMail.operTypeCriteria && (
                 newMail.operTypeCriteria.map((item) => (
                  <div style={{ width: '100px', display: 'flex', flexDirection: 'row' }}>
                  <p key={item.code} style={{ width: '50px' }}>{item.code}</p>
                  <p key={item.id} onClick={() => deleteItem(item)} className="clickable">X</p>
                  </div>
                 ))
               )}
              </div>
            </CardSetting>
            </div>

          </div>

          <div className="b">
            {/* Содержание (характер) операции (сделки) */}
            <CardSetting title="Содержание (характер) операции:" invalid={Boolean(!validateField('operationContent'))}>
              <div className="hintline">
                <span
                  className="reference_info"
                  onPointerEnter={() => setExtraInfo('content')}
                  onPointerLeave={() => setExtraInfo('')}
                >
                  <div className="question_mark">
                    ?
                    {extraInfo === 'content' && (
                      <div className="reference">
                        Содержание операции по документу, например: выдача займа
                        по договору №1 от 05.10.2013
                      </div>
                    )}
                  </div>
                </span>
                <Input
                  defaultValue={newMail.operationContent}
                  onChange={(e) => setNewMail({ ...newMail, operationContent: e.target.value })
                  }
                />
              </div>
            </CardSetting>

             {/* Иные данные по операции (№ договора, и т.п.) */}
             <CardSetting title="Иные данные по операции:" invalid={Boolean(!validateField('additionalData'))}>
              <div className="hintline">
                <span
                  className="reference_info"
                  onPointerEnter={() => setExtraInfo('extradata')}
                  onPointerLeave={() => setExtraInfo('')}
                >
                  <div className="question_mark">
                    ?
                    {extraInfo === 'extradata' && (
                      <div className="reference">
                        Документ/документы, на основании которого/которых
                        составлено сообщение
                      </div>
                    )}
                  </div>
                </span>
                <Input
                  defaultValue={newMail.additionalData}
                  onChange={(e) => setNewMail({ ...newMail, additionalData: e.target.value })
                  }
                />
              </div>
             </CardSetting>

            {/* Документы, подтверждающие сделку */}
            <CardSetting title="Документы, подтверждающие сделку:" invalid={Boolean(attachments.length === 0)}>
              {/* <p style={{ marginRight: '27px', color: 'blue' }}>прикрепить</p> */}
              <BulkFileUpload
                handler={attachmentHandler}
                accept={
                  acceptFileExtension.length > 0
                    ? acceptFileExtension[0].value
                    : ''
                }
              >
                {/* <img className="ticket-send__attach" src="../../icons/button/paperclip.svg" alt="paperclip" title="Добавить вложение" /> */}
                Прикрепить вложение 📑
              </BulkFileUpload>
              <div style={{ maxHeight: '62px', overflow: 'auto' }}>
                {attachments.length > 0
                  && attachments.map((file) => (
                    <p className="clickable">{file.filename}</p>
                  ))}
                {/* <p className="clickable">{newMail.attachments?.name}</p> */}
              </div>
            </CardSetting>

            {/* Дата совершения операции (сделки */}
            <CardSetting title="Дата совершения операции (сделки):" invalid={Boolean(!validateField('executionDate'))}>
              <div className="hintline">
                <span
                  className="reference_info"
                  onPointerEnter={() => setExtraInfo('execdate')}
                  onPointerLeave={() => setExtraInfo('')}
                >
                  <div className="question_mark">
                    ?
                    {extraInfo === 'execdate' && (
                      <div className="reference">Дата договора займа</div>
                    )}
                  </div>
                </span>
                <Input
                  type="date"
                  defaultValue={newMail.executionDate}
                  onChange={(e) => setNewMail({ ...newMail, executionDate: e.target.value })
                  }
                />
              </div>
            </CardSetting>

            {/* Сумма операции (сделки) */}
            <CardSetting title="Сумма операции (сделки):" invalid={Boolean(!validateField('operationSumm'))}>
              <Input
                type="number"
                defaultValue={newMail.operationSumm}
                onChange={(e) => setNewMail({ ...newMail, operationSumm: e.target.value })
                }
              />
            </CardSetting>

            {/* Валюта проведения операции (сделки) */}
            <CardSetting title="Валюта проведения операции (сделки):" invalid={Boolean(!validateField('currency'))}>
            <Select
              id="currency"
              array={currency}
              onChoose={(e) => setNewMail({ ...newMail, currency: e.title })
                }
              defaultValue={newMail.currency}
            />
              {/* <Input
                defaultValue={newMail.currency}
                onChange={(e) => setNewMail({ ...newMail, currency: e.target.value })
                }
              /> */}
            </CardSetting>

            {/* Сведения о лице (лицах), иностранной структуры без образования юридического лица, проводящих операцию (сделку) */}
            <CardSetting invalid={Boolean(!validateField('foreignExecutor'))} title="Сведения о лице (лицах), иностранной структуры без образования юридического лица, проводящих операцию (сделку):">
              <div className="hintline">
                <span
                  className="reference_info"
                  onPointerEnter={() => setExtraInfo('foreignexec')}
                  onPointerLeave={() => setExtraInfo('')}
                >
                  <div className="question_mark">
                    ?
                    {extraInfo === 'foreignexec' && (
                      <div className="reference">
                        Наименование участников по договору (например:
                        кредитор/заемщик)
                      </div>
                    )}
                  </div>
                </span>
                <Input
                  defaultValue={newMail.foreignExecutor}
                  onChange={(e) => setNewMail({ ...newMail, foreignExecutor: e.target.value })
                  }
                />
              </div>
            </CardSetting>

            {/* Сведения о работнике, составившем внутреннее сообщение об операции (сделке)  */}
            {/* <CardSetting title="Сведения о сотруднике, составившем внутреннее сообщение об операции (сделке):">
              {users.length > 0 && (
                <Select
                  search
                  defaultValue={newMail.employee.name}
                  onChoose={(e) => setNewMail({
                    ...newMail,
                    employee: { id: e.id, name: e.title },
                  })
                  }
                  array={users}
                />
              )}
            </CardSetting> */}
          </div>
          {/* Комментарии */}
        </div>
        <div className="card-comment">
          <CardSetting title="Комментарии:" invalid={Boolean(!validateField('operationComment'))}>
            <div
              style={{
                marginLeft: '0px',
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                justifyContent: 'right',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <span
                className="reference_info"
                onPointerEnter={() => setExtraInfo('comment')}
                onPointerLeave={() => setExtraInfo('')}
              >
                <div className="question_mark">
                  ?
                  {extraInfo === 'comment' && (
                    <div
                      className="reference"
                      style={{ width: '500px', fontSize: '12px' }}
                    >
                      Полностью опишите схему для СДЛ, укажите причину
                      составления сообщения. Если займ: 1) был ли возврат 2) в
                      каком объеме 3)пролонгировался ли договор
                    </div>
                  )}
                </div>
              </span>
              <Input
                onChange={(e) => setNewMail({ ...newMail, operationComment: e.target.value })
                }
              />
            </div>
          </CardSetting>
        </div>
      </CardBody>
      <CardFooter>
        <Button onClick={saveNewMessage}>Создать</Button>
      </CardFooter>
    </Card>
  );
}

export default NewMessageCard;
