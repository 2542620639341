import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import ApplicationCard from '../Employee/ApplicationCard/ApplicationCard';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import Select from '../../../components/UI/Select';
import Wrapper from '../../../components/Basic/Wrapper';

function DefaultTable(props) {
  const { isAdmin, location } = props;
  const tableId = 'project_evs';
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const [applications, setApplications] = useState([]); // найденные заявки
  const [allApplications, setAllApplications] = useState([]);
  const [total, setTotal] = useState(0);
  // const isSearchAllowed = !(!period?.from || !period?.to || (new Date(period?.from) > new Date(period?.to)));
  const [showCard, setShowCard] = useState(false);
  const [tableTwo, setTableTwo] = useState(false);
  const title = 'Проектные оценки';
  const id = 'evaluation__approver-table2';
  const [currentApplicationId, setCurrentApplicationId] = useState(null);
  const [period, setPeriod] = useState(''); // период времени
  const [finYears, setFinYears] = useState([]);

  const headers = [ // заголовки
    { id: 1, title: 'Клиент', field: 'client_title' },
    { id: 2, title: 'Проект', field: 'project_title' },
    { id: 3, title: 'Сотрудник', field: 'employee_title' },
    { id: 4, title: 'Руководитель', field: 'approver_title' },
    { id: 5, title: 'Партнер', field: 'partner_title' },
    { id: 6, title: 'Директор', field: 'director_title' },
    { id: 7, title: 'Менеджер', field: 'manager_title' },
    { id: 8, title: 'Кол-во часов', field: 'hours_amount' },
    { id: 9, title: 'Грейд', field: 'grade' },
    { id: 10, title: 'Статус', field: 'status_title' },
    { id: 11, title: 'Действие', field: 'action' },
  ];

  const defaultTableState = {
    title: 'Проектные оценки',
    page: 1,
    limit: 20, // количество строк в таблице
    switch_data: {
      current: 'active',
      titles: {
        all: 'Все',
        active: 'Активные',
      },
    },
  };
  const [tableState, setTableState] = useState(JSON.parse(localStorage.getItem(tableId)) || defaultTableState);

  useEffect(() => {
    localStorage.setItem(tableId, JSON.stringify(tableState));
    findApplications();
  }, [tableState, period]);

  useEffect(() => {
    getFinYears();
  }, []);

  // Поиск заявлений на выбранные даты
  async function findApplications() {
    try {
      const reqData = {
        type: 'findProjectEvs',
        is_admin: true,
        period,
        tableState,
      };
      const result = await clientSendData('POST', '/find_project_evs', reqData); // запрос в БД
      if (result.success) {
      //  setAllApplications(result.result);
        setApplications(result.result);
        setTotal(result.total);
      }
    } catch (error) {
      catchHandler(error, 'findProjectEvs'); // обработка ошибок
    }
  }

  // Открыть заявление
  function openApplication(id, isAdmin) {
    setSearchParams({ app: id, isAdmin, location });
  }

  function openApplicationCard(id) {
    setShowCard(true);
    setCurrentApplicationId(id);
  }

  async function getFinYears() {
    try {
      const reqData = {
        type: 'getAllFinYears',
      };
      const result = await clientSendData('POST', '/get_all_fin_years', reqData); // запрос в БД
      if (result) {
        setFinYears(result);
        setPeriod(result[0].title);
      }
    } catch (error) {
      catchHandler(error, 'findProjectEvs'); // обработка ошибок
    }
  }

  function chooseFinYear(finYear) {
    setPeriod(finYear.title);
    setTableState((state) => ({ ...state, page: 1 }));
  }

  const [reportIsLoading, setReportIsLoading] = useState(false);
  async function getReport() {
    try {
      const reqData = {
        type: 'getProjectEvalReport',
        period,
      };
      setReportIsLoading(true);
      const result = await clientSendData('POST', '/get_project_eval_report', reqData); // запрос в БД
      if (result?.success) { // если запрос выполнен успешно
        const uInt8Array = new Uint8Array(result.file.data);
        const blob = new Blob([uInt8Array], { type: 'application/vnd.ms-excel' });
        const tempLink = document.createElement('a');
        tempLink.href = window.URL.createObjectURL(blob);
        tempLink.setAttribute('download', 'Отчет_ПО.xlsx');
        document.body.appendChild(tempLink);
        tempLink.click();
        setReportIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'getPodftReport'); // обработка ошибок
    }
  }

  return (
  // <Table id="evaluation__approver-table" loading={isLoading}>
  //   <THead
  //     headers={headers}
  //     array={applications}
  //     setArray={setApplications}
  //     button_place="row"
  //   />

    <>
    <Wrapper flexFlow="row wrap">
        Финансовый год
        <Select
          id="fin_year"
          array={finYears}
          onChoose={(finYear) => chooseFinYear(finYear)}
          defaultValue={period}
        />
    </Wrapper>
    <PaginatedTable
      id="evaluation__approver-table"
      headers={headers}
      setState={setTableState}
      state={tableState}
      button_place="row"
      total={total}
    >
      {/* <TBody> */}
        {/* Если нет результатов */}
        {applications.length === 0 ? (
        <TRow>
          <TData>Результаты отсутствуют</TData>
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
        </TRow>) : (
        // Если результаты есть
          applications.map((row) => (
          <TRow key={row.id}>
            {headers.map((item) => {
              if (item.field !== 'action' && !row.enabled) {
                return <TData key={item.field}>
                  <div style={{ color: 'red' }}>
                {row[item.field] ? row[item.field] : '-'}
                  </div>
                       </TData>;
              } if (item.field !== 'action' && row.enabled) return <TData key={item.field}>{row[item.field] ? row[item.field] : '-'}</TData>;
              return <TData key={item.field} onClick={() => openApplication(row.id, isAdmin)}>Открыть карточку</TData>;
            })}
            <TData onClick={() => openApplicationCard(row.id)}>
              <img src="../../icons/file-text.svg" alt="edit" />
            </TData>
          </TRow>

          )))}
      {/* </TBody> */}

      {showCard && (
        <ApplicationCard
          id={currentApplicationId}
          setShowCard={setShowCard}
          findApplications={findApplications}
        />)}
    </PaginatedTable>
    <div className="excel-button">
    <div className="upu-table__table-button" title={title} onClick={getReport}>Cкачать в Excel</div>
    </div>
    {reportIsLoading && <div className="loadalert">
        <p>ПОЖАЛУЙСТА, ПОДОЖДИТЕ!</p>
        <p>ДОКУМЕНТ СКАЧИВАЕТСЯ</p>
                        </div>}
    </>
  );
}

export default DefaultTable;
