import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  Document, AlignmentType, Packer, Table, TableCell, TableRow, WidthType, Paragraph, TextRun, TableBorders, BorderStyle, convertInchesToTwip, Header,
} from 'docx';
import Button from '../../components/UI/Button/Button';
import { alertFunction } from '../../utils/functions/alertFunction';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { setOperInfo } from '../../utils/functions/others';
import { clientSendData } from '../../utils/functions/requests';
import classes from '../../components/UI/Button/button.module.scss';
import './staffcv.scss';
import {
  orderedKeys, disabledFields, specialKey, topBorderKey,
} from './common';
import Select from '../../components/UI/Select';
import Input from '../../components/UI/Input';

/**
* @component Резюме сотрудников - Интерфейс страницы резюме сотрудника
*/

function CV({
  meetingId, userId, isAdmin,
}) {
  const [formData, setFormData] = useState({
    'Основные проекты': [],
    'Участие в профессиональных сообществах': [],
    draft: {}, // Добавляем ключ для черновика
  });
  const [allCertificates, setAllCertificates] = useState('');
  const [cvData, setSelectedCV] = useState({});
  const [levelLanguages, setLevelLanguages] = useState([]);
  const [nameLanguages, setNameLanguages] = useState([]);
  const navigate = useNavigate();
  const smallCurtain = `${classes.curtain__button} ${classes.small}`;
  const certificates = allCertificates || [];
  const languages = levelLanguages;
  const nameOfLanguages = nameLanguages;
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска из URL
  const userIdFromUrl = searchParams.get('user'); // взять id резюме из URL
  const [CVStatus, setCVStatus] = useState(''); // Добавляем состояние для статуса резюме
  const updatedCertificates = certificates.map(({ id, fullName, shortName }) => ({
    id,
    title: fullName, // Изменяем ключ с fullName на title
    shortName,
  }));
  const [isEditable, setIsEditable] = useState(true); // Новое состояние для управления редактированием
  const dataWithDraft = JSON.parse(JSON.stringify(formData));
  delete dataWithDraft.draft;

  useEffect(() => {
    if (userIdFromUrl) {
      getCVData(userIdFromUrl); // Запросить данные резюме по userId
      setOperInfo({ subject_id: userIdFromUrl }); // Записываем id резюме в оперативную информацию
    }
  }, [searchParams]);

  // if (isAdmin) {
  useEffect(() => {
    if (meetingId) getCVData(meetingId);
    // запросить информацию по заявке
  }, [meetingId]);
  // } else {
  useEffect(() => {
    if (userId !== undefined) {
      getCVData(userId);
      // запросить информацию по заявке
      setSearchParams({ user: userId });
    }
  }, []);
  // }

  useEffect(() => {
    if (cvData && cvData.cv) {
      const updatedFormData = {
        ...cvData.cv,
        'Основные проекты': cvData.cv['Основные проекты'] || [],
        'Участие в профессиональных сообществах': cvData.cv['Участие в профессиональных сообществах'] || [],
        draft: cvData.cv.draft || { ...cvData.cv }, // Если draft отсутствует, используем cvData.cv в качестве draft
      };

      setFormData(updatedFormData);

      // Добавляем проверку на существование draft
      if (cvData.cv.draft) {
        setIsEditable(false); // Резюме не редактируемое
      } else {
        setIsEditable(true); // Резюме редактируемое
      }
    }
  }, [cvData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Проверяем, нужно ли обновлять draft или основное formData
    const targetData = formData.draft ? formData.draft : formData;

    // Если нужно обновить draft, обновляем его отдельно
    if (targetData) {
      setFormData((prevData) => ({
        ...prevData,
        draft: {
          ...prevData.draft,
          [name]: value === '' ? ' ' : value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value === '' ? ' ' : value, // Устанавливаем значение по умолчанию при пустой строке
      }));
    }
  };

  const handleArrayStringChange = (name, index, value) => {
    // Проверяем, нужно ли обновлять draft или основное formData
    const targetData = formData.draft ? formData.draft : formData;

    // Если это draft, обновляем его отдельно
    if (targetData) {
      setFormData((prevData) => ({
        ...prevData,
        draft: {
          ...prevData.draft,
          [name]: prevData.draft[name].map((item, i) => (i === index ? value === '' ? ' ' : value : item)), // Обновляем только нужный элемент массива
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: prevData[name].map((item, i) => (i === index ? value === '' ? ' ' : value : item)), // Обновляем только нужный элемент массива
      }));
    }
  };

  const handleArrayChange = (key, index, field, value) => {
    // Проверяем, нужно ли обновлять draft или основное formData

    const currentArray = formData.draft ? formData.draft[key] : formData[key];

    // Проверяем, является ли currentArray массивом
    const updatedArray = Array.isArray(currentArray) ? [...currentArray] : [];

    // Проверяем, находится ли индекс в допустимых пределах
    if (index >= 0 && index < updatedArray.length) {
      const item = updatedArray[index];

      // Проверяем, является ли элемент объектом
      if (typeof item === 'object' && item !== null) {
        updatedArray[index] = {
          ...item,
          [field]: value,
        };
      } else {
        console.error(`Элемент по индексу ${index} не является объектом или равен null.`);
      }
    } else {
      console.error(`Индекс ${index} находится вне диапазона массива. Длина массива: ${updatedArray.length}`);
    }

    if (currentArray) {
      setFormData((prevData) => ({
        ...prevData,
        draft: {
          ...prevData.draft,
          [key]: updatedArray,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [key]: updatedArray,
      }));
    }
  };

  const sortResumeData = (resumeData) => {
    const sortByYear = (a, b) => {
      const getYear = (yearString) => {
        const rangeMatch = yearString.match(/^(\d{4})-(\d{4})$/);
        if (rangeMatch) {
          return parseInt(rangeMatch[1], 10); // Используем начальный год диапазона
        }
        const currentMatch = yearString.match(/^(\d{4}) по наст\. вр\.$/);
        if (currentMatch) {
          return parseInt(currentMatch[1], 10);
        }
        return parseInt(yearString, 10) || 0; // Возвращаем 0, если не удалось распарсить
      };

      return getYear(b.year || '') - getYear(a.year || '');
    };

    // Фильтруем и сортируем данные
    const filteredAndSortedEducation = resumeData['Образование и профессиональная квалификация']
      .filter((item) => item.year && item.value) // Убираем пустые значения
      .sort(sortByYear);

    const filteredAndSortedCertificates = resumeData['Аттестаты и сертификаты']
      .filter((item) => item.year && item.value && item.year) // Убираем пустые значения
      .sort(sortByYear);

    const filteredAndSortedOrganizations = resumeData['Участие в профессиональных сообществах']
      .filter((item) => item.year && item.value && item.year) // Убираем пустые значения
      .sort(sortByYear);

    const filteredAndSortedWorkExperience = resumeData['Сведения о трудовой деятельности']
      .filter((item) => item.year && item.value) // Убираем пустые значения
      .sort(sortByYear);

    // Возвращаем отсортированные данные
    return {
      ...resumeData,
      'Образование и профессиональная квалификация': filteredAndSortedEducation,
      'Сведения о трудовой деятельности': filteredAndSortedWorkExperience,
      'Аттестаты и сертификаты': filteredAndSortedCertificates,
      'Участие в профессиональных сообществах': filteredAndSortedOrganizations,
    };
  };

  const handleApproved = async (e) => {
    e.preventDefault();
    const sortedFormData = sortResumeData(formData);
    let dataToSend = {};
    dataToSend = sortResumeData(sortedFormData.draft);
    await uploadResume(dataToSend, userId, { status: 'confirmed' });
    await getCVData();
    setSearchParams({ user: userId });
    window.location.reload();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sortedFormData = sortResumeData(formData);
    const userDraft = cvData?.cv?.draft;
    const userId = cvData?.id;
    let dataToSend = {};
    // Удаляем draft из formDataWithoutDraft, если он есть
    if (userDraft) {
      dataToSend = sortResumeData(sortedFormData.draft);
      await uploadResume(dataToSend, userId, { status: 'confirmed' });
    } else {
      // Присваиваем обновленные данные как draft
      dataToSend = sortedFormData;
      await uploadResume(dataToSend, userId, { status: 'changed' });
    }
    // Отправляем обновленные данные

    await getCVData();
    setSearchParams({ user: userId });
    window.location.reload();
  };

  async function getCVData(userId) {
    try {
      const reqData = {
        type: 'getCVData',
        userId,
      };
      // console.log(reqData);
      const result = await clientSendData('POST', '/get_cv_data', reqData);
      if (result && result.data) {
        setAllCertificates(JSON.parse(result.certificates[0].certificate) || []);
        setLevelLanguages(JSON.parse(result.languages[0].language) || []);
        setNameLanguages(JSON.parse(result.languages_name[0].language_name) || []);
        delete result.data[0].cv['Дата рождения'];
        setSelectedCV(result.data[0]);
        setCVStatus(result.data[0].status);
        console.log(result.data[0].status);
      }
    } catch (error) {
      catchHandler(error, 'getCVData');
    }
  }
  console.log(CVStatus);
  const uploadResume = async (resumeData, userId, status) => {
    // console.log(resumeData);
    try {
      // Создаем объект для отправки
      const reqData = {
        type: 'editStaffCV',
        DataCV: resumeData,
        userId, // Добавляем ID пользователя
        status, // Добавляем статус
      };

      // Отправляем данные на сервер
      const result = await clientSendData('POST', '/edit_staff_cv', reqData);

      // Обработка ответа от сервера
      if (result.success) {
        await alertFunction('resume_edit_success', 'client'); // Уведомление об успешном обновлении
        // Обновляем состояние, чтобы перерендерить компонент
        setFormData((prevData) => ({
          ...prevData,
          ...resumeData, // Обновляем данные резюме
        }));
      } else {
        await alertFunction('resume_edit_error', 'client'); // Уведомление об ошибке
      }
    } catch (error) {
      catchHandler(error, 'editStaffCV'); // Обработка ошибок
    }
  };

  const handleBackToResults = () => {
    navigate('/staff_cv');
    window.location.reload();
  };

  useEffect(() => {
    const cvFromUrl = searchParams?.get('user'); // взять id резюме из url
    if (cvFromUrl) { // если id есть
      // setTicketID(cvFromUrl); // запросить информацию по заявке
      setOperInfo({ subject_id: cvFromUrl }); // записываем id заявки в оперативную информацию
    } // else closeHandler();
  }, [searchParams]);

  const generateWordDocument = () => {
    const rows = [];

    const defaultFontStyle = {
      font: 'Microsoft Sans Serif', // Название шрифта
      size: 18, // Размер шрифта (в пунктах)
    };

    // Создаем колонтитул
    const header = new Header({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: 'РЕЗЮМЕ',
              bold: true,
              font: 'Microsoft Sans Serif', // Задаем шрифт для колонтитула
              size: 22, // Размер шрифта для колонтитула (можно настроить)
            }),
          ],
          alignment: AlignmentType.RIGHT, // Выравнивание по правому краю
        }),
      ],
    });

    orderedKeys.forEach((key) => {
      if (formData[key] !== undefined) {
        const value = formData[key];

        // Пропускаем пустые значения
        if ((value === undefined || value === null || (Array.isArray(value) && value.length === 0) || (typeof value === 'string' && value.trim() === ''))) {
          return; // Пропускаем это поле
        }

        if (Array.isArray(value) && key === 'Образование и профессиональная квалификация') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 3,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
                columnSpan: 2,
              }),
            ]);
          });
        } else if (Array.isArray(value) && key === 'Аттестаты и сертификаты') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 3,
            }),
          ]);

          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
                width: { size: 2000, type: WidthType.DXA }, // Задаем ширину для первой ячейки
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
                width: { size: 6000, type: WidthType.DXA }, // Задаем ширину для второй ячейки
                borders: {
                  right: {
                    style: BorderStyle.SINGLE, // Стиль границы
                    size: 100, // Устанавливаем толщину границы в 0
                    color: 'FFFFFF', // Цвет границы (белый, чтобы быть невидимым на белом фоне)
                  },
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [new TextRun({ text: item.number, ...defaultFontStyle })],
                    alignment: AlignmentType.RIGHT, // Выравнивание текста вправо
                  }),
                ],
                width: { size: 1000, type: WidthType.DXA }, // Задаем ширину для третьей ячейки
                borders: {
                  left: {
                    style: BorderStyle.SINGLE, // Стиль границы
                    size: 100, // Устанавливаем толщину границы
                    color: 'FFFFFF', // Цвет границы (белый, чтобы быть невидимым на белом фоне)
                  },
                },
              }),
            ]);
          });
        } else if (Array.isArray(value) && key === 'Участие в профессиональных сообществах') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 3,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.pozition, ...defaultFontStyle })] })],
              }),
            ]);
          });
        } else if (Array.isArray(value) && key === 'Сведения о трудовой деятельности') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 3,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
                columnSpan: 2,
              }),
            ]);
          });
        } else if (Array.isArray(value) && (key === 'Ключевые компетенции')) {
          // Если ключ совпадает, добавляем заголовок
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 3,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);

          // Добавляем массив значений в виде списка с буллитами
          const bulletList = value.map((item) => new Paragraph({
            children: [new TextRun({ text: `• ${item}`, ...defaultFontStyle })],
          }));

          // Добавляем список в новую ячейку, занимающую две колонки
          rows.push([
            new TableCell({
              children: bulletList,
              columnSpan: 3,
            }),
          ]);
        } else if (Array.isArray(value) && ((key === 'Основные проекты'))) {
          // Если ключ совпадает, добавляем заголовок
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 3,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);

          // Добавляем массив значений в виде списка с буллитами
          const bulletList = value.map((item) => new Paragraph({
            children: [new TextRun({ text: `• ${item}`, ...defaultFontStyle })],
          }));

          // Добавляем список в новую ячейку, занимающую две колонки
          rows.push([
            new TableCell({
              children: bulletList,
              columnSpan: 3,
            }),
          ]);
        } else if (key === 'Номер квалификационного аттестата аудитора' || key === 'Номер квалификационного сертификата аудитора' || key === 'Номер квалификационного сертификата налогового консультанта'
          || key === 'Наличие квалификационного аттестата главного бухгалтера' || key === 'Номер квалификационного аттестата ИПБ профессионального главного бухгалтера коммерческой организации' || key === 'Номер квалификационного аттестата ИПБ профессионального финансового директора' || key === 'Номер квалификационного аттестата ИПБ профессионального главного бухгалтера – эксперта в области внутреннего контроля') {
          rows.push([
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: `${key}`, bold: true, ...defaultFontStyle }),
                    new TextRun({ text: `: ${value}`, ...defaultFontStyle }),
                  ],
                }),
              ],
              columnSpan: 3,
            }),
          ]);
        } else if (key === 'Стаж финансово-экономической деятельности' || key === 'Стаж юридической деятельности' || key === 'Стаж оценочной деятельности') {
          rows.push([
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: `${key}`, bold: true, ...defaultFontStyle }),
                    new TextRun({ text: `: ${value}`, ...defaultFontStyle }),
                  ],
                }),
              ],
              columnSpan: 3,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
        } else if (Array.isArray(value) && (key === 'Знание иностранных языков')) {
          // Если ключ совпадает, добавляем заголовок
          rows.push([
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle }), // Ключ
                    new TextRun({ text: ` ${value.map((item) => `${item.language} — ${item?.level}`).join(', ')}`, ...defaultFontStyle }), // Значения
                  ],
                }),
              ],
              columnSpan: 3, // Ячейка занимает три колонки
              borders: {
                bottom: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
        } else if (key === 'Достоверность сведений, представленных в резюме, подтверждаю') {
          // Извлечение даты из value
          const dateRegex = /(\d{1,2}\.\d{1,2}\.\d{4}|\d{1,2}\s+(января|февраля|марта|апреля|мая|июня|июля|августа|сентября|октября|ноября|декабря)\s+\d{4})/i;
          const dateMatch = value.match(dateRegex);
          const date = dateMatch ? dateMatch[0] : 'Введите дату';

          // Извлечение оставшегося текста
          const remainingText = value.replace(dateRegex, '').trim();
          // Разделяем текст на части
          const parts = remainingText.split('Игоревна');

          // Создание ячеек
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, ...defaultFontStyle })] })],
              columnSpan: 3,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);

          // Ячейка с оставшимся текстом
          rows.push([
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${parts[0].trim()} Игоревна`,
                      ...defaultFontStyle,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${parts[1].trim()}       ${date}`, // Объединяем текст и дату
                      ...defaultFontStyle,
                    }),
                  ],
                }),
              ],
              // width: { size: 7000, type: WidthType.DXA }, // Установка ширины ячейки с оставшимся текстом
              columnSpan: 3, // Объединяем ячейку с соседней
            }),
          ]);
        } else if (key === 'Ф.И.О.') {
          // Для обычных ключей
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: value, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),

          ]);
        } else {
          // Для обычных ключей
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
            }),
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: Array.isArray(value) ? value.join(', ') : value, ...defaultFontStyle })] })],
              columnSpan: 2,
            }),
          ]);
        }
      }
    });

    const table = new Table({
      rows: rows.map((row) => new TableRow({ children: row })),
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: TableBorders.NONE,
      margins: {
        top: convertInchesToTwip(0.1),
        bottom: convertInchesToTwip(0.1),
        right: convertInchesToTwip(0),
        left: convertInchesToTwip(0),
      },
    });

    const doc = new Document({
      sections: [{
        properties: {},
        headers: {
          default: header, // Добавляем колонтитул в секцию
        },
        children: [table],
      }],
    });

    // Извлечение Ф.И.О. для имени файла
    const fullName = formData['Ф.И.О.'] || 'Резюме';
    const sanitizedFileName = fullName.replace(/[^a-zA-Z0-9а-яА-ЯёЁ\s]/g, '').replace(/\s+/g, '_').trim();

    Packer.toBlob(doc).then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${sanitizedFileName}.docx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }).catch((error) => {
      console.error('Ошибка при создании документа:', error);
    });
  };

  const addNewField = (key) => {
    let newElement;

    // Определение нового элемента в зависимости от ключа
    if (key === 'Образование и профессиональная квалификация' || key === 'Сведения о трудовой деятельности') {
      newElement = { year: '', value: '' };
    } else if (key === 'Аттестаты и сертификаты') {
      newElement = { year: '', value: '', number: '' };
    } else if (key === 'Знание иностранных языков') {
      newElement = { language: '', level: '' };
    } else if (key === 'Участие в профессиональных сообществах') {
      newElement = { year: '', value: '', position: '' };
    } else if (key === 'Основные проекты' || key === 'Ключевые компетенции') {
      newElement = ''; // Пустая строка для нового проекта
    }

    setFormData((prevData) => {
      // Создаем новый массив для текущего ключа, добавляя новый элемент
      const updatedArray = [...(prevData.draft[key] || []), newElement];

      // Обновляем draft, добавляя новый элемент к текущему ключу
      const newDraft = {
        ...prevData.draft,
        [key]: updatedArray,
      };

      return {
        ...prevData, // Сохраняем все предыдущие данные
        draft: newDraft, // Обновляем draft
      };
    });
  };

  const removeField = (key, index) => {
    const isDraft = formData.draft && formData.draft[key] !== undefined && formData.draft[key] !== '';

    // Создаем новый массив на основе того, что существует
    const updatedArray = [...(isDraft ? formData.draft[key] : formData[key] || [])];

    updatedArray.splice(index, 1); // Удаляем элемент по индексу

    // Обновляем formData
    setFormData((prevData) => {
      const newData = { ...prevData };

      // Если драфт существует, обновляем драфт
      if (isDraft) {
        newData.draft[key] = updatedArray;
      } else {
        // Если драфта нет, обновляем основное значение
        newData[key] = updatedArray;
      }

      return newData;
    });
  };

  const renderField = (key) => {
    // Получаем значение из драфта, если он существует, иначе берем основное значение
    const isDraft = formData.draft && formData.draft[key] !== undefined && formData.draft[key] !== '';
    const value = isDraft ? formData.draft[key] : formData[key];
    const valueDraft = formData.draft[key] || [];
    const valueOld = formData[key];
    const isDisabled = disabledFields.includes(key) || (!isAdmin && !isEditable);
    // console.log(isDisabled);

    // Логика для "Достоверность сведений, представленных в резюме, подтверждаю"
    if (key === 'Достоверность сведений, представленных в резюме, подтверждаю') {
      const dateRegex = /(\d{1,2}\.\d{1,2}\.\d{4}|\d{1,2}\s+(января|февраля|марта|апреля|мая|июня|июля|августа|сентября|октября|ноября|декабря)\s+\d{4})/g;
      const dates = value.match(dateRegex) || [];
      const currentDate = new Date();
      const formattedCurrentDate = `${String(currentDate.getDate()).padStart(2, '0')}.${String(currentDate.getMonth() + 1).padStart(2, '0')}.${currentDate.getFullYear()}`;
      const remainingText = value.replace(dateRegex, '').trim();

      return (
            <>
                <div style={{ marginBottom: '5px' }}>
                    <input
                      type="text"
                      value={remainingText || ''}
                      onChange={(e) => {
                        setFormData((prevData) => ({
                          ...prevData,
                          [isDraft ? 'draft' : key]: `${e.target.value} ${dates.join(', ')}`,
                        }));
                      }}
                      placeholder="Остальной текст"
                      style={{ width: '100%' }}
                      disabled={isDisabled}
                    />
                </div>
                {dates.map((date, index) => (
                    <div key={index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                        <Input
                          type="text"
                          value={formattedCurrentDate}
                          onChange={() => {
                            const updatedDates = [...dates];
                            updatedDates[index] = formattedCurrentDate;
                            setFormData((prevData) => ({
                              ...prevData,
                              [isDraft ? 'draft' : key]: `${remainingText} ${updatedDates.join(', ')}`,
                            }));
                          }}
                          placeholder="Дата"
                          style={{ marginRight: '5px', width: '150px' }}
                          disabled={isDisabled}
                        />
                    </div>
                ))}
            </>
      );
    }

    // Остальная логика для других ключей
    // Логика для "Образование и профессиональная квалификация/сертификаты" и "Сведения о трудовой деятельности"
    if (key === 'Образование и профессиональная квалификация' || key === 'Сведения о трудовой деятельности') {
      const draftItem = valueDraft || [];
      const oldItem = valueOld || [];
      // console.log(oldItem);
      return (
            <>
                {value && value.map((item, index) => (

                    <div key={index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                        {typeof item === 'object' ? (
                            <>
                                <Input
                                  type="text"
                                  value={item?.year || ''}
                                  onChange={(e) => handleArrayChange(key, index, 'year', e.target.value)}
                                  placeholder="Год"
                                  style={{ marginRight: '5px', width: '150px', fontWeight: 'bolder' }}
                                  disabled={isDisabled}
                                />
                                <Input
                                  type="text"
                                  value={item?.value || ''}
                                  onChange={(e) => handleArrayChange(key, index, 'value', e.target.value)}
                                  placeholder="Значение"
                                  style={{ marginRight: '5px', width: '100%', maxWidth: '1500px' }}
                                  disabled={isDisabled}
                                />

                                <Button type="button" className={smallCurtain} onClick={() => removeField(key, index)} disabled={isDisabled}>
                                    х
                                </Button>
                            </>
                        ) : (
                            <>
                               <Input
                                 type="text"
                                 value={item || ''}
                                 onChange={(e) => handleArrayChange(key, index, 'year', e.target.value)}
                                 placeholder="Год"
                                 style={{ marginRight: '5px', width: '150px', fontWeight: 'bolder' }}
                                 disabled={isDisabled}
                               />
                                <Input
                                  type="text"
                                  value={item || ''}
                                  onChange={(e) => handleArrayChange(key, index, 'value', e.target.value)}
                                  placeholder="Значение"
                                  style={{ marginRight: '5px', width: '100%' }}
                                  disabled={isDisabled}
                                />
                                <Button type="button" className={smallCurtain} onClick={() => removeField(key, index)} disabled={isDisabled}>
                                    х
                                </Button>
                            </>
                        )}
                    </div>
                ))}

{isAdmin && draftItem.length > 0 && (
  (JSON.stringify(draftItem) !== JSON.stringify(oldItem))
) && (
                                   <div style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                     <div style={{
                                       marginTop: '10px',
                                       // marginRight: '5px',
                                       // marginLeft: '5px',
                                       color: 'grey',
                                       width: '100%',
                                       backgroundColor: '#F8DAAA', // Мягкий цвет фона (светло-серый)
                                       borderRadius: '5px', // Скругленные углы
                                       padding: '10px', // Отступы внутри блока (опционально)
                                     }}
                                     >
                                           <div style={{ marginBottom: '5px' }}>
           {' '}
           {/* Новый div для переноса на новую строку */}
                                               <b>Предыдущее значение:</b>
                                           </div>
                                           {oldItem.length > 0
                                             ? oldItem.map((item) => <div>{`Год: ${item?.year}, значение: ${item?.value}`}</div>) : <p>отсутствует.</p>}

                                     </div>
                                   </div>
)}

                <Button
                  type="button"
                  className={smallCurtain}
                  onClick={() => addNewField(key)}
                  disabled={isDisabled}
                >
                    Добавить год и значение
                </Button>
            </>
      );
    }

    // Логика для "Аттестаты и сертификаты"
    if (key === 'Аттестаты и сертификаты') {
      const draftItem = valueDraft || [];
      const oldItem = valueOld || [];
      return (
            <>
                {value && value.map((item, index) => (
                    <div key={index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                        {typeof item === 'object' && item?.value ? (
                            <>
                                <Input
                                  type="text"
                                  value={item?.year || ''}
                                  onChange={(e) => handleArrayChange(key, index, 'year', e.target.value)}
                                  placeholder="Год"
                                  style={{ marginRight: '5px', width: '150px', fontWeight: 'bolder' }}
                                  disabled={isDisabled}
                                />
                                <Input
                                  type="text"
                                  value={item?.value || ''}
                                  onChange={(e) => handleArrayChange(key, index, 'value', e.target.value)}
                                  placeholder="Название"
                                  style={{ marginRight: '5px', width: '100%', maxWidth: '1500px' }}
                                  disabled={isDisabled}
                                />
                                <Input
                                  type="text"
                                  value={item?.number || ''}
                                  onChange={(e) => handleArrayChange(key, index, 'number', e.target.value)}
                                  placeholder="Номер"
                                  style={{ marginRight: '5px', width: '150px' }}
                                  disabled={isDisabled}
                                />
                                <Button type="button" className={smallCurtain} onClick={() => removeField(key, index)} disabled={isDisabled}>
                                    х
                                </Button>
                            </>
                        ) : (
                            <>
                                <Input
                                  type="text"
                                  value={item?.year || ''}
                                  onChange={(e) => handleArrayChange(key, index, 'year', e.target.value)}
                                  placeholder="Год"
                                  style={{ marginRight: '5px', width: '150px', fontWeight: 'bolder' }}
                                  disabled={isDisabled}
                                />
                                <Select
                                  id="certificates"
                                  array={updatedCertificates}
                                  onChoose={(certificate) => handleArrayChange(key, index, 'value', certificate.title)}
                                  defaultValue="Выберите тип"
                                  search
                                  style={{ marginRight: '5px', width: '100%', maxWidth: '1500px' }}
                                  disabled={isDisabled}
                                />
                                <Input
                                  type="text"
                                  value={item?.number || ''}
                                  onChange={(e) => handleArrayChange(key, index, 'number', e.target.value)}
                                  placeholder="Номер"
                                  style={{ marginLeft: '5px', width: '150px' }}
                                  disabled={isDisabled}
                                />
                                <Button
                                  type="button"
                                  className={smallCurtain}
                                  onClick={() => removeField(key, index)}
                                  style={{ marginLeft: '5px' }}
                                  disabled={isDisabled}
                                >
                                    х
                                </Button>
                            </>
                        )}
                    </div>
                ))}
                                               {isAdmin && draftItem.length > 0 && (
                                                 (JSON.stringify(draftItem) !== JSON.stringify(oldItem))
                                               ) && (
                                   <div style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                     <div style={{
                                       marginTop: '10px',
                                       // marginRight: '5px',
                                       // marginLeft: '5px',
                                       color: 'grey',
                                       width: '100%',
                                       backgroundColor: '#F8DAAA', // Мягкий цвет фона (светло-серый)
                                       borderRadius: '5px', // Скругленные углы
                                       padding: '10px', // Отступы внутри блока (опционально)
                                     }}
                                     >
                                           <div style={{ marginBottom: '5px' }}>
           {' '}
           {/* Новый div для переноса на новую строку */}
                                               <b>Предыдущее значение:</b>
                                           </div>
                                           {oldItem.length > 0
                                             ? oldItem.map((item) => <div>{`Год: ${item?.year}, значение: ${item?.value}, номер: ${item?.value}`}</div>) : <p>отсутствует.</p>}

                                     </div>
                                   </div>
                                               )}
                <Button
                  type="button"
                  className={smallCurtain}
                  onClick={() => addNewField(key)}
                  disabled={isDisabled}
                >
                    Добавить сертификат/аттестат
                </Button>
            </>
      );
    }

    // Остальные условия для других ключей
    if (key === 'Основные проекты') {
      const draftItem = valueDraft || [];
      const oldItem = valueOld || [];
      return (
            <>
                {value && value.map((item, index) => (
                    <div key={index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                        <Input
                          type="text"
                          value={item || ''}
                          onChange={(e) => handleArrayStringChange(key, index, e.target.value)}
                          placeholder="Введите проект"
                          style={{ marginRight: '5px', width: '100%', maxWidth: '1500px' }}
                          disabled={isDisabled}
                        />
                        <Button type="button" className={smallCurtain} onClick={() => removeField(key, index)}>
                            х
                        </Button>
                    </div>
                ))}

{isAdmin && draftItem.length > 0 && (
  (JSON.stringify(draftItem) !== JSON.stringify(oldItem))
) && (
                                   <div style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                     <div style={{
                                       marginTop: '10px',
                                       // marginRight: '5px',
                                       // marginLeft: '5px',
                                       color: 'grey',
                                       width: '100%',
                                       backgroundColor: '#F8DAAA', // Мягкий цвет фона (светло-серый)
                                       borderRadius: '5px', // Скругленные углы
                                       padding: '10px', // Отступы внутри блока (опционально)
                                     }}
                                     >
                                           <div style={{ marginBottom: '5px' }}>
           {' '}
           {/* Новый div для переноса на новую строку */}
                                               <b>Предыдущее значение:</b>
                                           </div>
                                           {oldItem.length > 0
                                             ? oldItem.map((item) => <div>{item}</div>) : <p>отсутствует.</p>}

                                     </div>
                                   </div>
)}

                <Button
                  type="button"
                  className={smallCurtain}
                  onClick={() => addNewField(key)}
                  disabled={isDisabled}
                >
                    Добавить проект
                </Button>
            </>
      );
    }

    // Обработка для "Знание иностранных языков"
    if (key === 'Знание иностранных языков') {
      const draftItem = valueDraft || [];
      const oldItem = valueOld || [];

      return (
            <>
                {value && value.map((item, index) => (
                    <div key={item.id} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                            <Select
                              id={`language-${index}`}
                              array={nameOfLanguages}
                              onChoose={(language) => handleArrayChange(key, index, 'language', language.title)}
                              search
                              defaultValue={item?.language || 'Выберите язык'}
                              style={{ marginRight: '5px', width: '150px', maxWidth: '150px' }}
                              disabled={isDisabled}
                            />
                            <Select
                              id={`level-${index}`}
                              array={languages}
                              onChoose={(level) => handleArrayChange(key, index, 'level', level.title)}
                              defaultValue={item?.level || 'Выберите уровень'}
                              style={{ marginRight: '5px', width: '150px', maxWidth: '150px' }}
                              disabled={isDisabled}
                            />

                        <Button type="button" className={smallCurtain} onClick={() => removeField(key, index)} disabled={isDisabled}>
                            x
                        </Button>

                    </div>
                ))}
                   {isAdmin && draftItem.length > 0 && (
                     (JSON.stringify(draftItem) !== JSON.stringify(oldItem))
                   ) && (
                        <div style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                          <div style={{
                            marginTop: '10px',
                            // marginRight: '5px',
                            // marginLeft: '5px',
                            color: 'grey',
                            width: '100%',
                            backgroundColor: '#F8DAAA', // Мягкий цвет фона (светло-серый)
                            borderRadius: '5px', // Скругленные углы
                            padding: '10px', // Отступы внутри блока (опционально)
                          }}
                          >
                                <div style={{ marginBottom: '5px' }}>
{' '}
{/* Новый div для переноса на новую строку */}
                                    <b>Предыдущее значение:</b>
                                </div>
                                {oldItem.length > 0
                                  ? oldItem.map((item) => <div>{`Язык: ${item?.language}, Уровень: ${item?.level}`}</div>) : <p>отсутствует.</p>}

                          </div>
                        </div>
                   )}
                <Button
                  type="button"
                  className={smallCurtain}
                  onClick={() => addNewField(key)}
                  disabled={isDisabled}
                >
                    Добавить язык
                </Button>

            </>
      );
    }

    // Остальные условия для других ключей
    if (key === 'Ключевые компетенции') {
      const draftItem = valueDraft || [];
      const oldItem = valueOld || [];
      return (
            <>
                {value && value.map((item, index) => (
                    <div key={index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                        <Input
                          type="text"
                          value={item || ''}
                          onChange={(e) => handleArrayStringChange(key, index, e.target.value)}
                          placeholder="Компетенция"
                          style={{ marginRight: '5px', width: '100%', maxWidth: '1500px' }}
                          disabled={isDisabled}
                        />
                        <Button
                          type="button"
                          className={smallCurtain}
                          onClick={() => removeField(key, index)}
                          disabled={isDisabled}
                        >
                            х
                        </Button>
                    </div>
                ))}

{isAdmin && draftItem.length > 0 && (
  (JSON.stringify(draftItem) !== JSON.stringify(oldItem))
) && (
                                   <div style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                     <div style={{
                                       marginTop: '10px',
                                       // marginRight: '5px',
                                       // marginLeft: '5px',
                                       color: 'grey',
                                       width: '100%',
                                       backgroundColor: '#F8DAAA', // Мягкий цвет фона (светло-серый)
                                       borderRadius: '5px', // Скругленные углы
                                       padding: '10px', // Отступы внутри блока (опционально)
                                     }}
                                     >
                                           <div style={{ marginBottom: '5px' }}>
           {' '}
           {/* Новый div для переноса на новую строку */}
                                               <b>Предыдущее значение:</b>
                                           </div>

                                           {oldItem.length > 0 ? oldItem.map((item) => <div>{item}</div>) : <p>отсутствует.</p>}

                                     </div>
                                   </div>
)}

                <Button
                  type="button"
                  className={smallCurtain}
                  onClick={() => addNewField(key)}
                  disabled={isDisabled}
                >
                    Добавить компетенции
                </Button>
            </>
      );
    }

    if (key === 'Участие в профессиональных сообществах') {
      const draftItem = valueDraft || [];
      const oldItem = valueOld || [];
      return (
            <>
                {value && value.map((item, index) => (
                    <div key={index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                        <Input
                          type="text"
                          value={item?.year || ''}
                          onChange={(e) => handleArrayChange(key, index, 'year', e.target.value)}
                          placeholder="Год"
                          style={{ marginRight: '5px', width: '150px', fontWeight: 'bolder' }}
                          disabled={isDisabled}
                        />
                        <Input
                          type="text"
                          value={item?.value || ''}
                          onChange={(e) => handleArrayChange(key, index, 'value', e.target.value)}
                          placeholder="Название"
                          style={{ marginRight: '5px', width: '100%', maxWidth: '1500px' }}
                          disabled={isDisabled}
                        />
                        <Input
                          type="text"
                          value={item?.pozition || ''}
                          onChange={(e) => handleArrayChange(key, index, 'pozition', e.target.value)}
                          placeholder="Позиция"
                          style={{ marginRight: '5px', width: '150px' }}
                          disabled={isDisabled}
                        />
                        <Button type="button" className={smallCurtain} onClick={() => removeField(key, index)} disabled={isDisabled}>
                            х
                        </Button>
                    </div>
                ))}
                  {isAdmin && draftItem.length > 0 && (
                    (JSON.stringify(draftItem) !== JSON.stringify(oldItem))
                  ) && (
                        <div style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                          <div style={{
                            marginTop: '10px',
                            // marginRight: '5px',
                            // marginLeft: '5px',
                            color: 'grey',
                            width: '100%',
                            backgroundColor: '#F8DAAA', // Мягкий цвет фона (светло-серый)
                            borderRadius: '5px', // Скругленные углы
                            padding: '10px', // Отступы внутри блока (опционально)
                          }}
                          >
                                <div style={{ marginBottom: '5px' }}>
{' '}
{/* Новый div для переноса на новую строку */}
                                    <b>Предыдущее значение:</b>
                                </div>
                                {oldItem.length > 0
                                  ? oldItem.map((item) => <div>{`Год: ${item?.year}, значение: ${item?.value}, позиция: ${item?.pozition}`}</div>) : <p>отсутствует.</p>}

                          </div>
                        </div>)}
                <Button
                  type="button"
                  className={smallCurtain}
                  onClick={() => addNewField(key)}
                  disabled={isDisabled}
                >
                    Добавить профессиональное сообщество
                </Button>
            </>
      );
    }

    if (key === 'Стаж финансово-экономической деятельности' || key === 'Стаж юридической деятельности' || key === 'Стаж оценочной деятельности') {
      const draftItem = valueDraft || [];
      const oldItem = valueOld || [];

      return (
          <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <Input
                type="text"
                id={key}
                name={key}
                value={value || ''}
                onChange={handleChange}
                style={{ width: '150px', marginRight: '10px' }} // Отступ справа для Input
              />
              {isAdmin && draftItem.length > 0 && (draftItem !== oldItem) && (
                  <div style={{
                    marginRight: '10px', // Отступ справа для блока с предыдущим значением
                    color: 'grey',
                    backgroundColor: '#F8DAAA', // Мягкий цвет фона (светло-серый)
                    borderRadius: '5px', // Скругленные углы
                    padding: '10px', // Отступы внутри блока (опционально)
                  }}
                  >
                          <div style={{ marginBottom: '5px' }}>
                              <b>Предыдущее значение:</b>
                          </div>
                          <div>{`${oldItem}`}</div>
                  </div>
              )}
          </div>
      );
    }

    // Остальные условия для других ключей
    if (typeof value === 'string') {
      return (
            <input
              type="text"
              id={key}
              name={key}
              value={value || ''}
              onChange={handleChange}
              style={{ width: '100%' }}
              disabled={isDisabled}
            />
      );
    }

    return null;
  };

  if ((userId !== cvData.id) && !isAdmin) return <p>К этому разделу нет доступа</p>;

  return (
    <div style={{ padding: '0px 20px 0px', height: 'calc(100vh - 120px)' }}>
     {/* Условный рендеринг кнопки */}
     {isAdmin && ( // Проверяем, что роль не "user"
                <Button onClick={handleBackToResults} className="text-button">
                    <span className="arrow">←</span>
                    Вернуться к резюме сотрудников
                </Button>
     )}
      <h1>Резюме</h1>
      {((!isAdmin) && (cvData?.cv?.draft && Object.keys(cvData?.cv?.draft).length > 0)) && (
                <div style={{ color: 'red', marginBottom: '10px', marginTop: '10px' }}>
                    Внимание: Ваше резюме находится на утверждении у администратора. Режим правки отключен. Вы можете скачать предыдущу версию резюме.
                </div>
      )}
      <div style={{
        height: 'calc(100vh - 220px)', overflowY: 'auto', marginBottom: '10px', paddingTop: '10px', borderTop: '1px solid black',
      }}
      >
        <form onSubmit={handleSubmit}>
          {orderedKeys.map((key) => {
            const value = formData[key];

            if ((value === undefined || value === null || value === '')) {
              return null; // Если значение отсутствует, ничего не рендерим
            }

            const isSpecialKey = specialKey.includes(key);

            const hasTopBorderKey = topBorderKey.includes(key);

            return (
              <div
                key={key}
                style={{
                  marginBottom: '15px',
                  borderTop: hasTopBorderKey ? '1px solid black' : 'none',
                  paddingTop: hasTopBorderKey ? '10px' : '0',
                }}
              >
                {isSpecialKey ? (
                  <>
                    <label htmlFor={key} style={{ display: 'block', fontWeight: 'bold', marginBottom: '10px' }}>
                      {key}
:
                    </label>
                    {renderField(key)}
                  </>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor={key} style={{ flex: '0 0 150px', fontWeight: 'bold', marginRight: '5px' }}>
                      {key}
:
                    </label>
                    <div style={{ flex: '1 1 100%' }}>
                      {renderField(key)}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </form>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button type="button" onClick={generateWordDocument} style={{ marginRight: '20px' }}>
          Скачать резюме
        </Button>
        {!isAdmin && (JSON.stringify(formData.draft) !== JSON.stringify(dataWithDraft)) && (
      <Button
        type="submit"
        onClick={handleSubmit}
        disabled={!isAdmin && cvData?.cv?.draft && Object.keys(cvData?.cv?.draft).length > 0}
      >
        Сохранить изменения
      </Button>
        )}
    {CVStatus === 'not_confirmed' && !isAdmin && !((JSON.stringify(formData.draft) !== JSON.stringify(dataWithDraft))) && (
      <Button
        type="submit"
        onClick={handleApproved}
        disabled={!isAdmin && cvData?.cv?.draft && Object.keys(cvData?.cv?.draft).length > 0}
      >
        Подтвердить
      </Button>
    )}
{isAdmin && ((JSON.stringify(formData.draft) !== JSON.stringify(dataWithDraft))) && (
<Button type="submit" onClick={handleSubmit} disabled={!isAdmin && cvData?.cv?.draft && Object.keys(cvData?.cv?.draft).length > 0}>
          Сохранить изменения
</Button>)}
      </div>
    </div>
  );
}

export default CV;
