import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StaffCV from './StaffCV';
import CV from './CV';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { setOperInfo } from '../../utils/functions/others';
import { clientSendData } from '../../utils/functions/requests';
import { authorization } from '../../utils/functions/authenticate';

function MainCVPage() {
  // const isAdmin = false;
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const location = useSearchParams()[0].get('location'); // интерфейс откуда заходит пользователь
  const [currentUser, setCurrentUser] = useState();
  // isAdmin = admins.includes(String(current_user)) ? true: false;
  const isAdmin = ['800', '1294', '632298', '893', '795', '307', '298'].includes(String(currentUser));

  useEffect(() => {
    authorizationCheck();
    firstRender();
  }, []);

  async function authorizationCheck() {
    setIsLoading(true);
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
    // if (checkAuthorization) await getSdInterfaces(); // получить интерфейсы пользователя
    setIsLoading(false);
  }

  async function firstRender() {
    try {
      const reqData = {
        type: 'cvAuthorization',
      };
      const result = await clientSendData('POST', '/cv_authorization', reqData); // запрос в БД
      if (result) {
        setCurrentUser(result);
      }
    } catch (error) {
      catchHandler(error, 'firstRender');
    }
  }

  if (isAuthorized) {
    if (isAdmin) return <StaffCV isAdmin />;
    // if (isAdmin && location === 'admin') return <CV isAdmin />;
    if (!isAdmin) return <CV userId={currentUser} isAdmin={false} />;
  }
}

export default MainCVPage;
