import React, { useState } from 'react';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import Button from '../Button/Button';
import Loader from '../Loader';
import classes from '../Button/button.module.scss';
import './fileUpload.scss';

/**
* @component UI компонент для массовой загрузки файлов
@prop все переданные свойства записываются в атрибуты input
@prop {handler} function (Обязательный) Обработчик загрузки (вызывается при нажатии на кнопку "Загрузить файлы")
@prop {accept} string типы разрешенных для загрузки файлов
*/

function BulkFileUpload(props) {
  const { handler, accept = '*', children } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDropped, setIsDropped] = useState(false);
  const [isDrag, setIsDrag] = useState(false);
  const [files, setFiles] = useState([]);

  function dragStartHandler(e) {
    e.preventDefault();
    setIsDrag(true);
  }

  function dragLeaveHandler(e) {
    e.preventDefault();
    setIsDrag(false);
  }
  // удаление файла
  // file - удаляемый файл
  function deleteFile(file) {
    const filtered = files.filter((f) => f.name !== file.name);
    setFiles(filtered);
    if (filtered.length === 0) setIsDropped(false);
  }
  // Проверка файлов на дубликаты
  // arrayOfFiles - массив файлов для проверки
  function checkFiles(e, arrayOfFiles) {
    e.preventDefault();
    setIsDrag(false);
    // поиск дублей файлов]
    const checkDoubles = arrayOfFiles.map((file) => {
      // если какой нибудь файл уже присутствует в массиве файлов для загрузки
      const findResult = files.find((f) => f.name === file.name);
      if (findResult) return null; // возвращается null
      return file;
    });
    const newFiles = checkDoubles.filter((file) => file); // удаление null из массива
    setFiles([...files, ...newFiles]); // обновление массива файлов для загрузки
    setIsDropped(true);
  }

  // Проверка перед загрузкой
  async function checkBeforeUpload(e) {
    try {
      if (handler) { // если есть обработчик файлов
        if (files.length > 0) { // и выбраны файлы для загрузки
          setIsUploading(true);
          await handler(files); // выполняется обработка файлов
          setIsUploading(false);
          setIsOpen(false);
        } else alertFunction('choose_file', 'clientPost'); // иначе уведомление
      } else {
        console.log('no handler');
        reset();
      }
    } catch (error) {
      catchHandler(error, 'checkBeforeUpload');
    }
  }

  function reset() {
    setIsUploading(false);
    setIsDropped(false);
    setIsDrag(false);
    setFiles([]);
  }

  return (
    <>
        <span className="clickable" onClick={() => setIsOpen(true)}>{children}</span>
        {isOpen
        && <div className="file-upload">
            <div className="file-upload__form">
                <span className="file-upload__close" onClick={() => { setIsOpen(false); reset(); }}>×</span>
                <h2 className="file-upload__title">Загрузка файлов</h2>
                <div
                  className={isDrag ? 'file-upload__drop-area file-upload__drop-area_active' : 'file-upload__drop-area'}
                  onDragStart={dragStartHandler}
                  onDragOver={dragStartHandler}
                  onDragLeave={dragLeaveHandler}
                  onDrop={(e) => checkFiles(e, [...e.dataTransfer.files])}
                >
                    {isUploading ? <Loader />
                      : <>
                    <img className="file-upload__cloud-img" src="../../../icons/upload.svg" alt="cloud" />
                    {isDrag
                      ? <span className="file-upload__subtitle">Отпустите, чтобы добавить файлы к загрузке</span>
                      : <>
                            <span className="file-upload__subtitle">
                                Перетащите или
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label className="file-upload__label clickable" htmlFor="file-upload">выберите файлы</label>
                            </span>
                            <input
                              id="file-upload"
                              type="file"
                              name="file"
                              onChange={(e) => checkFiles(e, [...e.target.files])}
                              className="file-upload__input-file"
                              accept={accept}
                              multiple
                            />
                        </>}
                        </>}
                </div>
                {isDropped && files.length > 0
                && <>
                    <span className="file-upload__uploading">{`Файлы для загрузки ${files.length}`}</span>
                    <div className="file-upload__info">
                        {files.map((file) => (
                            <div className="file-upload__status" key={file.lastModified}>
                                <span className="file-upload__filename">{file.name}</span>
                                <span className="file-upload__delete" onClick={() => deleteFile(file)}>×</span>
                            </div>
                        ))}
                    </div>
                   </>}
                <Button className={`${classes.button} ${classes.full_width}`} onClick={checkBeforeUpload}>Загрузить файл(ы)</Button>
            </div>
           </div>}
    </>
  );
}

export default BulkFileUpload;
