import React from 'react';

/**
* @component Строка таблицы
@prop {onClick} function Обработчик клика на строку
@prop {button} boolean Сделать строку кликабельной
@prop {active} boolean Визуальное выделение, строка выглядит нажатой
@prop {mark} boolean Визуальное выделение, фон строки #EFDBB2;
*/

function TRow(props) {
  const {
    children, onClick, button, active, mark,
  } = props;

  // Определения классов стилей
  const defineClass = () => {
    let result = 'upu-table__table-row';
    if (active) result += ' upu-table__table-row_active';
    if (button) result += ' upu-table__row-button';
    if (mark) result += ' upu-table__table-row_mark';
    if (mark === 'odd') result += ' upu-table__table-row_mark-odd';
    return result;
  };

  // Обработка клика
  function clickHandler(e) {
    if (onClick) onClick(e);
  }

  return <tr onClick={clickHandler} className={defineClass()}>{children}</tr>;
}

export default TRow;
