// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
#admin_staff_cv {
  width: 100%; /* Убедитесь, что таблица занимает всю ширину контейнера */
}

.id-cell {
  width: 50px; /* Ширина для ячейки с ID */
}

.full-name-cell {
  width: 300px; /* Ширина для ячейки с полным именем (самая большая) */
}

.short-name-cell {
  width: 150px; /* Ширина для ячейки с коротким именем (поменьше) */
}

.checkbox-cell {
  width: 30px; /* Ширина для ячейки с чекбоксом (маленькая) */
}

.table-header th {
  width: 10%; /* Ширина для ячейки с ID */
}

.table-header th:nth-child(2) {
  width: 40%; /* Ширина для ячейки с полным именем (самая большая) */
}

.table-header th:nth-child(3) {
  width: 30%; /* Ширина для ячейки с коротким именем (поменьше) */
}

.table-header th:nth-child(4) {
  width: 20%; /* Ширина для ячейки с чекбоксом (маленькая) */
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminStaffCV/adminstaffcv.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACI,WAAA,EAAA,0DAAA;AACJ;;AAEE;EACE,WAAA,EAAA,2BAAA;AACJ;;AAEE;EACE,YAAA,EAAA,sDAAA;AACJ;;AAEE;EACE,YAAA,EAAA,mDAAA;AACJ;;AAEE;EACE,WAAA,EAAA,8CAAA;AACJ;;AAEE;EACE,UAAA,EAAA,2BAAA;AACJ;;AAEE;EACE,UAAA,EAAA,sDAAA;AACJ;;AAEE;EACE,UAAA,EAAA,mDAAA;AACJ;;AAEE;EACE,UAAA,EAAA,8CAAA;AACJ","sourcesContent":["\n#admin_staff_cv {\n    width: 100%; /* Убедитесь, что таблица занимает всю ширину контейнера */\n  }\n  \n  .id-cell {\n    width: 50px; /* Ширина для ячейки с ID */\n  }\n  \n  .full-name-cell {\n    width: 300px; /* Ширина для ячейки с полным именем (самая большая) */\n  }\n  \n  .short-name-cell {\n    width: 150px; /* Ширина для ячейки с коротким именем (поменьше) */\n  }\n  \n  .checkbox-cell {\n    width: 30px; /* Ширина для ячейки с чекбоксом (маленькая) */\n  }\n\n  .table-header th {\n    width: 10%; /* Ширина для ячейки с ID */\n  }\n  \n  .table-header th:nth-child(2) {\n    width: 40%; /* Ширина для ячейки с полным именем (самая большая) */\n  }\n  \n  .table-header th:nth-child(3) {\n    width: 30%; /* Ширина для ячейки с коротким именем (поменьше) */\n  }\n  \n  .table-header th:nth-child(4) {\n    width: 20%; /* Ширина для ячейки с чекбоксом (маленькая) */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
