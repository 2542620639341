import React, { useState, useEffect, useRef } from 'react';
import './asap.scss';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input';
import RadioButton from '../../components/UI/RadioButton';
import { alertFunction } from '../../utils/functions/alertFunction';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import ProgressBar from './ProgressBar';
import CardSetting from '../../components/Card/CardSetting';
// import Loader from '../../components/UI/Loader';

// Компонент для отображения перетаскиваемой кнопки

function Analysis() {
  const [lines, setLines] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loadedJp, setLoadedJp] = useState(false);
  const [selectedCheckpoint, setSelectedCheckpoint] = useState(null);
  const [visibleContent, setVisibleContent] = useState('page_1'); // Состояние для управления содержимым страницы
  const [linesTab, setLinesTab] = useState([]);
  const [linesComma, setLinesComma] = useState([]);
  const [linesSemicolon, setLinesSemicolon] = useState([]);
  const [selectedDelimiter, setSelectedDelimiter] = useState('space');
  const [selectedTableData, setSelectedTableData] = useState(null);
  const [uploadedFileOSV, setUploadedFileOSV] = useState(null);
  const [uploadedFilesJP, setUploadedFilesJP] = useState([]);
  const [columnIndexesOSV, setColumnIndexesOSV] = useState({});
  const [columnIndexesJP, setColumnIndexesJP] = useState({});
  const [uploadedFileNameOSV, setUploadedFileNameOSV] = useState('');
  const [removeHeaders, setRemoveHeaders] = useState(false); // Состояние для удаление повторных заголовков
  const [isUploading, setIsUploading] = useState(false); // Состояние для отслеживания загрузки файла
  const [isUploadingFiles, setIsUploadingFiles] = useState(false); // Состояние для отслеживания загрузки файла
  const [uploadProgress, setUploadProgress] = useState(0); // сСостояние загрузки лоадера
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [email, setEmail] = useState('');
  const [originalCells, setOriginalCells] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true); // флаг первой загрузки файла ЖП
  const [confirmedEncoding, setConfirmedEncoding] = useState(false);
  const [confirmedEncodingJp, setConfirmedEncodingJp] = useState(false);

  const sectionRef = useRef(null);

  useEffect(() => {
    if (selectedDelimiter === 'space') {
      setSelectedTableData(linesTab);
    } else if (selectedDelimiter === 'comma') {
      setSelectedTableData(linesComma);
    } else if (selectedDelimiter === 'semicolon') {
      setSelectedTableData(linesSemicolon);
    }
  }, [selectedDelimiter, linesTab, linesComma, linesSemicolon]);

  useEffect(() => {
    getAsapMail();
  }, []);

  async function getAsapMail() {
    try {
      const reqData = { type: 'getAsapMail' };
      const result = await clientSendData('POST', '/get_asap_mail', reqData);
      if (result?.success) {
        setEmail(result.data.mail);
      }
    } catch (error) {
      catchHandler(error, 'getAsapMail');
    }
  }

  async function editAsapData(
    fileOSV,
    fileOSVName,
    columnOSV,
    lineOSV,
    columnJP,
    delimiter,
  ) {
    try {
      const reqData = {
        fileOSV,
        fileOSVName,
        columnOSV,
        lineOSV,
        columnJP,
        delimiter,
        type: 'editAsapData',
      };

      const result = await clientSendData('POST', '/edit_asap_data', reqData);

      if (result) {
        await alertFunction('save_settings', 'client');
      }
    } catch (error) {
      catchHandler(error, 'editAsapData');
    }
    // finally { getEmailTemplate(); }
  }

  async function updateAsapData(
    startDateJp,
    endDateJp,
    emailJp,
  ) {
    try {
      const reqData = {
        startDate: startDateJp,
        endDate: endDateJp,
        email: emailJp,
        type: 'updateAsapData',
      };

      const result = await clientSendData('POST', '/update_asap_data', reqData);

      if (result) {
        await alertFunction('save_settings', 'client');
      }
    } catch (error) {
      catchHandler(error, 'updateAsapData');
    }
    // finally { getEmailTemplate(); }
  }

  const confirmEncoding = () => {
    // Функция для подтверждения корректности кодировки
    setConfirmedEncoding(true);
  };

  const confirmEncodingJp = () => {
    // Функция для подтверждения корректности кодировки
    setConfirmedEncodingJp(true);
  };

  async function Confirm() {
    // предупреждение, если кодировка не подтверждена
    if (!confirmedEncoding) {
      await alertFunction('asap_confirm_encoding', 'client');
      return;
    }
    // Проверяем, выбран ли RadioButton
    const radioButtonSelected = selectedCheckpoint !== null;
    // Проверяем, все ли кнопки были перемещены
    const allButtonsMoved = Array.from(
      document.querySelectorAll('.drag'),
    ).every(
      (element) => element.parentNode && element.parentNode.id === 'big' && element.parentNode.className.includes('dropzone'),
    );

    // Если RadioButton не выбран или не все кнопки перемещены
    if (!radioButtonSelected || !allButtonsMoved) {
      // Вызываем alertFunction с сообщением об ошибке
      await alertFunction('asap_all_fields', 'client');
    } else {
      // Если все в порядке, продолжаем с изменением состояния
      setVisibleContent('page_2');
      setLoaded(false);
    }
  }

  async function ConfirmJp() {
    if (!confirmedEncodingJp) {
      await alertFunction('asap_confirm_encoding', 'client');
      return;
    }
    if (uploadedFilesJP.length > 1) {
      setVisibleContent('page_3_1');
    } else {
      setVisibleContent('page_3');
    }
  }

  async function proceedToPage3() {
    setVisibleContent('page_3');
  }

  /* eslint-disable no-await-in-loop */

  async function ConfirmJpEnd() {
    const allButtonsMoved2 = Array.from(
      document.querySelectorAll('.drag'),
    ).every(
      (element) => element.parentNode && element.parentNode.id === 'big' && element.parentNode.className.includes('dropzone'),
    );
    // Проверяем, что каждая .dropzone содержит хотя бы один элемент с классом .drag
    if (!allButtonsMoved2) {
    // Если проверка не пройдена, выводим сообщение об ошибке
      await alertFunction('asap_all_fields', 'client');
      return;
    }
    // Если все элементы выбраны, продолжаем с загрузкой
    setVisibleContent('page_4');
    setIsUploading(true); // Включаем лоадер
    try {
      await editAsapData(
        uploadedFileOSV,
        uploadedFileNameOSV,
        columnIndexesOSV,
        selectedCheckpoint,
        columnIndexesJP,
        selectedDelimiter,
        removeHeaders,

      );
      // Загрузка всех файлов ЖП
      for (const [index, file] of uploadedFilesJP.entries()) {
      // eslint-disable-next-line no-await-in-loop
        await uploadChunk(file, file.name, index + 1, removeHeaders);
      }
      alertFunction('asap_download_file', 'client'); // Выводим сообщение о завершении загрузки
      setIsUploadingFiles(true);
    } catch (error) {
      catchHandler(error, 'ConfirmJpEnd');
    } finally {
      setIsUploading(false); // Выключаем лоадер
    }
  }

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    // Временное изменение состояния
    if (name === 'startDate') {
      setStartDate(value);
      // Проверка только если есть дата окончания
      if (endDate && new Date(value) >= new Date(endDate)) {
        alertFunction('asap_date_invalid', 'clientPost');
      }
    } else if (name === 'endDate') {
      setEndDate(value);
      // Проверка только если есть дата начала
      if (startDate && new Date(value) <= new Date(startDate)) {
        alertFunction('asap_date_invalid', 'clientPost');
      }
    }
  };

  const confirmDates = () => {
    // Проверяем, что startDate и endDate определены и не пусты
    if (startDate && endDate && new Date(startDate) < new Date(endDate)) {
      setVisibleContent('page_5');
    } else {
      // Выводим сообщение об ошибке, если даты не введены или startDate >= endDate
      alertFunction('required_fields', 'clientPost');
    }
  };

  const sendData = async () => {
    // Проверяем, что email определен и не пустой
    if (email) {
      setVisibleContent('page_6'); // Переход на страницу page_6
      try {
        // Вызов функции updateAsapData с передачей дат, включая email
        await updateAsapData(startDate, endDate, email);

        // Сообщение пользователю о том, что заявка сформирована
        await alertFunction('asap_success', 'clientPost');
      } catch (error) {
        catchHandler(error, 'sendData');
      }
    } else {
      await alertFunction('required_fields', 'clientPost');
    }
  };

  const dragStart = (originalEvent) => {
    const event = { ...originalEvent };
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/plain', event.target.getAttribute('id'));
    event.dataTransfer.setDragImage(event.target, 10, 10);

    // Сохраняем исходную ячейку элемента
    setOriginalCells((prevCells) => ({
      ...prevCells,
      [event.target.getAttribute('id')]: event.target.parentNode,
    }));

    // Добавляем класс при начале перетаскивания
    event.target.classList.add('dragging');
  };

  const dragOver = (event) => {
    event.preventDefault();
  };

  const dragEnter = (event) => {
    event.preventDefault();
    const dropzone = event.target.closest('.dropzone');
    const draggingElement = document.querySelector('.dragging');

    if (dropzone && draggingElement) {
      dropzone.classList.add('highlight'); // Подсветка зоны
    }
  };

  const dragDrop = async (event) => {
    event.preventDefault();
    const data = event.dataTransfer.getData('text/plain');
    const draggableElement = document.getElementById(data);
    const dropzone = event.target.closest('.dropzone');

    if (!draggableElement || !dropzone) {
      console.error('Перетаскиваемый элемент или зона сброса не найдены');
      return;
    }

    const dropzoneIndex = Array.from(dropzone.parentNode.children).indexOf(dropzone);

    // Обработка индексации элементов (остается без изменений)
    if (draggableElement.id.startsWith('jp_')) {
      setColumnIndexesJP((prevIndexes) => ({
        ...prevIndexes,
        [draggableElement.id]: dropzoneIndex,
      }));
    } else {
      setColumnIndexesOSV((prevIndexes) => ({
        ...prevIndexes,
        [draggableElement.id]: dropzoneIndex - 1,
      }));
    }

    // Перемещение элемента
    if (dropzone && dropzone.children.length === 0) {
      dropzone.appendChild(draggableElement);

      if (dropzone.id === 'big') {
        draggableElement.classList.remove('drag', 'dragging');
        draggableElement.classList.add('dropped', 'green'); // Присваиваем класс для зеленого цвета
      } else {
        draggableElement.classList.remove('dropped', 'green');
        draggableElement.classList.add('drag'); // Возвращаем класс `drag`
      }
    } else {
      const originalDropzone = originalCells[draggableElement.id];
      if (originalDropzone && originalDropzone.children.length === 0) {
        originalDropzone.appendChild(draggableElement);
        draggableElement.classList.remove('dragging', 'dropped', 'green');
        draggableElement.classList.add('drag'); // Возвращаем класс `drag`
      } else {
        console.error('Исходная ячейка не найдена или занята');
        await alertFunction('occupied_cell', 'client');
      }
    }

    // Убираем подсветку зоны
    if (dropzone) {
      dropzone.classList.remove('highlight');
    }
  };

  const dragBack = (event) => {
    event.preventDefault();
    const data = event.dataTransfer.getData('text/plain');
    const draggableElement = document.getElementById(data);

    if (!draggableElement) {
      console.error('Перетаскиваемый элемент не найден');
      return;
    }

    // Возвращаем элемент обратно в исходную ячейку
    const originalDropzone = originalCells[draggableElement.id];
    if (originalDropzone && originalDropzone.children.length === 0) {
      originalDropzone.appendChild(draggableElement);
      draggableElement.classList.remove('dragging', 'dropped', 'green');
      draggableElement.classList.add('drag'); // Возвращаем класс `drag`
    } else if (sectionRef.current) {
      sectionRef.current.appendChild(draggableElement);
    }

    // Убираем класс после завершения перетаскивания
    draggableElement.classList.remove('dragging');
  };

  // Функция чтения файла ОСВ
  const readTextFile = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (uploadedFileOSV && file.name === uploadedFileOSV.name) {
        setConfirmedEncoding(false);
        setLoaded(false);
      }
      if (file.size > 1024 * 1024) { // Проверяем размер файла (1MB)
        await alertFunction('asap_big_files', 'client');
        return;
      }
      setUploadedFileNameOSV(file.name); // Сохраняем имя файла
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        setUploadedFileOSV(text);
        // Разбиваем текст на строки и затем на столбцы
        const linesWithColumns = text
          .split('\n')
          .slice(0, 10)
          .map((line) => line.split('\t'));
        setLines(linesWithColumns);
        setLoaded(true);
      };
      reader.readAsText(file);
    }
  };

  async function uploadChunk(file, fileName, fileIndex, removeHeaders = false) {
    // console.log('Начало функции uploadChunk');
    const chunkSize = 1024 * 1024; // Размер куска 5 MB
    const maxConcurrentChunks = 4; // Максимальное количество одновременно обрабатываемых кусков
    const fileSize = file.size;
    console.log(fileSize);
    const totalChunks = Math.ceil(fileSize / chunkSize); // Общее количество кусков
    console.log(totalChunks);
    let start = 0;
    let uploadedChunks = 0;

    const activeUploads = new Set();
    const maxRetries = 3; // Максимальное количество попыток для каждого куска

    const updateProgress = (uploadedChunks, totalChunks) => {
      const progress = (uploadedChunks / totalChunks) * 100;
      setUploadProgress(progress); // Обновляем состояние прогресса загрузки
    };

    const enqueueUpload = async (chunk, chunkIndex, shouldRemoveHeaders, retries = 0) => {
      console.log(chunk);
      let result = null;
      while (activeUploads.size >= maxConcurrentChunks) {
        await new Promise((resolve) => {
          setTimeout(async () => {
            resolve();
          }, 10);
        });
      }
      activeUploads.add(chunkIndex);
      try {
        result = await processChunk(chunk, chunkIndex, fileName, fileIndex, shouldRemoveHeaders);
        if (chunkIndex === 0 || chunkIndex === 1) {
          // console.log(`Кусок ${chunkIndex} успешно загружен`);
        }
        // console.log(`Кусок ${chunkIndex} успешно загружен ${fileIndex} ${shouldRemoveHeaders}`);
        uploadedChunks++;
        updateProgress(uploadedChunks, totalChunks);
      } catch (error) {
        if (retries < maxRetries) {
          console.error(`Ошибка при передаче куска ${chunkIndex}, попытка ${retries + 1}:`, error);
          result = await enqueueUpload(chunk, chunkIndex, retries + 1, shouldRemoveHeaders);
        } else {
          console.error(`Кусок ${chunkIndex} не удалось загрузить после ${maxRetries} попыток:`, error);
          throw error;
        }
      } finally {
        activeUploads.delete(chunkIndex);
      }
      return result;
    };

    const processChunk = (chunk, chunkIndex, fileName, fileIndex, shouldRemoveHeaders) => new Promise((resolve, reject) => {
      const process = async () => {
        try {
          const buffer = await chunk.arrayBuffer();
          let finalBuffer = buffer;
          if (shouldRemoveHeaders && chunkIndex === 0 && fileIndex !== 0) {
            let text = new TextDecoder('utf-8').decode(buffer);
            const lines = text.split('\n');
            lines.shift();
            text = lines.join('\n');
            finalBuffer = new TextEncoder().encode(text).buffer;
          }
          const byteArray = new Uint8Array(finalBuffer);
          const jsonString = JSON.stringify(Array.from(byteArray));

          const reqData = {
            chunk: jsonString,
            chunkIndex,
            totalChunks,
            fileName,
            fileIndex,
            type: 'uploadChunk',
          };

          const result = await clientSendData('POST', '/upload_chunk', reqData);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      };
      process();
    });

    const uploadPromises = [];
    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const end = Math.min(start + chunkSize, fileSize);
      const chunk = file.slice(start, end);
      start = end;
      let shouldRemoveHeaders = false;
      if (removeHeaders && chunkIndex === 0 && fileIndex !== 1) {
        shouldRemoveHeaders = true;
      }
      uploadPromises.push(enqueueUpload(chunk, chunkIndex, shouldRemoveHeaders));
    }

    await Promise.all(uploadPromises);
    // console.log('Все куски файла были успешно загружены');
  }

  const readTextFileJp = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      if (uploadedFilesJP.length > 0 && files[0].name === uploadedFilesJP[0].name) {
        setConfirmedEncodingJp(false);
        setLoadedJp(false);
      }
      setUploadedFilesJP(files);

      // Очищаем данные таблицы только если это не первая загрузка
      if (!isFirstLoad) {
        setLinesTab([]);
        setLinesComma([]);
        setLinesSemicolon([]);
        setSelectedTableData([]);
      }

      // Устанавливаем флаг первой загрузки в false после первой загрузки
      setIsFirstLoad(false);

      readAndDisplayFirstTenLines(files[0]);
      files.forEach((file) => {
        readAndDisplayFirstTenLines(file);
      });

      setVisibleContent('page_2');
    }
  };

  const readAndDisplayFirstTenLines = (file) => {
    const CHUNK_SIZE = 1024 * 1024; // Размер куска, например 1MB
    const blob = file.slice(0, CHUNK_SIZE); // Читаем только первый кусок файла

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      // Разбиваем текст на строки и затем на столбцы в зависимости от выбранного разделителя
      const linesWithColumnsTab = text
        .split('\n')
        .slice(0, 10)
        .map((line) => line.split('\t'));
      const linesWithColumnsComma = text
        .split('\n')
        .slice(0, 10)
        .map((line) => line.split(','));
      const linesWithColumnsSemicolon = text
        .split('\n')
        .slice(0, 10)
        .map((line) => line.split(';'));

      // Обновляем состояния для всех трех типов разделителей
      setLinesTab((prev) => [...prev, linesWithColumnsTab]);
      setLinesComma((prev) => [...prev, linesWithColumnsComma]);
      setLinesSemicolon((prev) => [...prev, linesWithColumnsSemicolon]);

      // Устанавливаем выбранные данные в зависимости от выбранного разделителя
      if (selectedDelimiter === 'space') {
        setSelectedTableData(linesWithColumnsTab);
      } else if (selectedDelimiter === 'comma') {
        setSelectedTableData(linesWithColumnsComma);
      } else if (selectedDelimiter === 'semicolon') {
        setSelectedTableData(linesWithColumnsSemicolon);
      }

      setLoadedJp(true);
    };
    reader.onerror = (e) => {
      console.error('Ошибка чтения файла:', e);
    };
    reader.readAsText(blob);
  };

  const renderTable = (lines) => {
    if (!lines || !Array.isArray(lines)) {
      return null; // Возвращаем null, если lines не определен или не является массивом
    }

    const maxColumns = Math.max(...lines.map((line) => line.length));

    return (
      <table className="asap__table">
        <tbody>
          {lines.map((line, index) => {
            if (!line || !Array.isArray(line)) {
              return null; // Возвращаем null, если line не определен или не является массивом
            }

            const paddedColumns = [...line, ...Array(maxColumns - line.length).fill('')];
            return (
              <tr key={index}>
                {paddedColumns.map((column, columnIndex) => (
                  <td key={columnIndex}>{column}</td>
                ))}
              </tr>
            );
          })}
          <tr>
            {Array.from({ length: maxColumns }, (_, index) => (
              <td
                key={index}
                style={{ backgroundColor: 'rgba(40, 180, 125, 0.3)', height: '50px' }}
                id="big"
                className="dropzone"
                onDragEnter={(event) => dragEnter(event)}
                onDrop={(event) => dragDrop(event)}
                onDragOver={(event) => dragOver(event)}
                onDragLeave={(event) => dragBack(event)}
              />
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  const handleReloadFile = () => {
    document.getElementById('fileInput').click();
    setConfirmedEncoding(false);
  };

  const handleReloadFileJp = () => {
    document.getElementById('fileInputJp').click();
    setConfirmedEncodingJp(false);
  };

  // console.log(uploadedFilesJP);
  // console.log(confirmedEncodingJp);

  return (
    <div className="asap">
      <h1 className="asap__title">Анализ журнальных проводок</h1>

      <p className="asap__title__bold">
        ВНИМАНИЕ! Это первоначальное тестирование!
      </p>
      <p className="asap__title">
        Без этого тестирования дальнейшее тестирование бессмысленно.
      </p>

      <h2 className="asap__title">Этапы анализа журнальных проводок</h2>

      <ol className="asap__list">
        <li className="asap__list__li">
          Загрузите файлы для первоначального анализа (дождитесь загрузки
          каждого файла, и сопоставьте колонки).
        </li>
        <li className="asap__list__li">
          Укажите период проведения анализа данных.
        </li>
      </ol>

      {visibleContent === 'page_1' && (
        <div>
          <h2 className="asap__title">1 этап</h2>
          <p className="asap__title">
            a) Загрузите оборотно-сальдовую ведомость за период:
          </p>
          <Input
            type="file"
            onChange={readTextFile}
            style={{ display: 'none' }}
            id="fileInput"
          />
          <Button
            className="asap_button_1"
            onClick={handleReloadFile}
            style={{ backgroundColor: loaded ? '#28B47D' : '#647D91' }}
          >
            {loaded ? 'Перезагрузить файл' : 'Загрузить файл'}
          </Button>
        </div>
      )}
      {loaded && (
        <>
          <p className="asap__title__bold">
            Загруженный файл ОСВ в табличном виде выглядит следующим образом:
          </p>
          <div id="file_preview_box" className="file_preview_box">
          <table className="asap__table">
  <tbody>
    {lines.map((columns, index) => (
      <tr key={index}>
        {/* Первый столбец с RadioButton */}
        <td key={`select-td-${index + 1}`}>
          <RadioButton
            id={`${index + 1}`}
            group="select-td-group"
            value={selectedCheckpoint === `${index + 1}`}
            onChange={() => setSelectedCheckpoint(
              selectedCheckpoint === `${index + 1}` ? null : `${index + 1}`,
            )
            }
            checked={selectedCheckpoint === `${index + 1}`}
          />
        </td>
        {/* Остальные столбцы данных */}
        {columns.map((column, columnIndex) => (
          <td key={columnIndex}>{column}</td>
        ))}
      </tr>
    ))}
    {/* Зеленая строка в конце таблицы */}
    <tr>
      {/* Первая ячейка пустая и белая */}
      <td style={{ backgroundColor: 'white' }}>&nbsp;</td>
      {/* Остальные ячейки с зелёным фоном */}
      {Array.from(
        { length: Math.max(...lines.map((columns) => columns.length)) },
        (_, index) => (
          <td
            key={index + 1}
            style={{
              backgroundColor: 'rgba(40, 180, 125, 0.3)',
              height: '50px',
            }}
            id="big"
            className="dropzone"
            onDragEnter={(event) => dragEnter(event)}
            onDrop={(event) => dragDrop(event)}
            onDragOver={(event) => dragOver(event)}
            onDragLeave={(event) => dragBack(event)}
          />
        ),
      )}
    </tr>
  </tbody>
          </table>
          </div>
          {/* Модальное окно для подтверждения кодировки */}
      {uploadedFileOSV && !confirmedEncoding && (
        <div className="asap_modal">
          <div className="modal-content">
            <h2>Подтвердите корректность кодировки</h2>
            <p>Пожалуйста, подтвердите, что данные загружены в правильной кодировке.</p>
            <p>Если кодировка отображается не корректно, переведите файл в кодировку UTF-8 и загрузите обновленный файл.</p>
            <p>
Если необходима помощь, обратитесь в
{' '}
<a href="https://uniplace.unicon.ru/helpdesk">Сервисную службу</a>
.
            </p>
            <Button onClick={confirmEncoding} className="asap_button_1">Подтвердить</Button>
          </div>
        </div>
      )}
          <h3 className="asap__title">
            Cопоставьте необходимые колонки и строки для анализа:
          </h3>
          <ol className="asap__list">
            <li className="asap__list__li">
              В самом левом столбце отметьте, пожалуйста, начало данных в
              таблице, т.е. строку сразу после заголовков ОСВ.
            </li>
            <li className="asap__list__li">
              Перетяните нужное поле в подходящую колонку зеленой области в
              таблице.
            </li>
          </ol>

          <table
            ref={sectionRef}
            onDragEnter={dragEnter}
            onDrop={dragDrop}
            onDragOver={dragOver}
            className="table-custom"
          >
  <tbody>
    <tr>
      <td className="dropzone">
        <div
          className="drag"
          id="bill"
          draggable="true"
          onDragStart={dragStart}
          onDrop={dragDrop}
        >
          <span className="text">Счет</span>
        </div>
      </td>
      <td className="dropzone">
        <div
          className="drag"
          id="sndt"
          draggable="true"
          onDragStart={dragStart}
          onDrop={dragDrop}
        >
          <span className="text">СнДт</span>
        </div>
      </td>
      <td className="dropzone">
        <div
          className="drag"
          id="snnkt"
          draggable="true"
          onDragStart={dragStart}
          onDrop={dragDrop}
        >
          <span className="text">СнКт</span>
        </div>
      </td>
      <td className="dropzone">
        <div
          className="drag"
          id="obdt"
          draggable="true"
          onDragStart={dragStart}
          onDrop={dragDrop}
        >
          <span className="text">ОбДт</span>
        </div>
      </td>
      <td className="dropzone">
        <div
          className="drag"
          id="obkt"
          draggable="true"
          onDragStart={dragStart}
          onDrop={dragDrop}
        >
          <span className="text">ОбКт</span>
        </div>
      </td>
      <td className="dropzone">
        <div
          className="drag"
          id="spdt"
          draggable="true"
          onDragStart={dragStart}
          onDrop={dragDrop}
        >
          <span className="text">СпДт</span>
        </div>
      </td>
      <td className="dropzone">
        <div
          className="drag"
          id="spkt"
          draggable="true"
          onDragStart={dragStart}
          onDrop={dragDrop}
        >
          <span className="text">СпКт</span>
        </div>
      </td>
    </tr>
  </tbody>
          </table>

          <div className="button-wrapper">
  <Button onClick={Confirm} className="asap_button_1">Подтверить файл ОСВ</Button>
          </div>
        </>
      )}
      {visibleContent === 'page_2' && (
        <div>
          <h2 className="asap__title">1 этап</h2>
          <p className="asap__title">
            b) Загрузите файл журнальных проводок за период:
          </p>
          <Input
            type="file"
            onChange={readTextFileJp}
            style={{ display: 'none' }}
            id="fileInputJp"
            multiple // Добавляем этот атрибут для множественной загрузки
          />
          <Button
            className="asap_button_1"
            onClick={handleReloadFileJp}
            style={{ backgroundColor: loadedJp ? '#28B47D' : '#647D91' }}
          >
            {loadedJp ? 'Перезагрузить файл' : 'Выберите файл для загрузки'}
          </Button>
          {loadedJp && (
            <>
              <p className="asap__title">
                Загруженный файл журнала проводок в табличном виде:
              </p>
              <div>
                <RadioButton
                  onChange={() => setSelectedDelimiter('space')}
                  id="space"
                  group="Delimiter"
                  defaultChecked={selectedDelimiter === 'space'}
                >
                  Вариант по умолчанию:
                </RadioButton>
                <div id="file_preview_box" className="file_preview_box">
                  {renderTable(linesTab[0])}
                </div>

                <p className="asap__title">
                  Если таблица отображается не коректно, выберите один из
                  вариантов ниже:
                </p>
                <RadioButton
                  onChange={() => setSelectedDelimiter('comma')}
                  id="comma"
                  group="Delimiter"
                >
                  Вариант №2
                </RadioButton>
                <div id="file_preview_box" className="file_preview_box">
                  {renderTable(linesComma[0])}
                </div>
                <RadioButton
                  onChange={() => setSelectedDelimiter('semicolon')}
                  id="semicolon"
                  group="Delimiter"
                >
                  Вариант №3
                </RadioButton>
                <div id="file_preview_box" className="file_preview_box">
                  {renderTable(linesSemicolon[0])}
                </div>
              </div>
              <div className="button-wrapper">
{' '}
<Button onClick={ConfirmJp} className="asap_button_1">Подтвердить</Button>

              </div>
              {uploadedFilesJP && !confirmedEncodingJp && (
        <div className="asap_modal">
          <div className="modal-content">
            <h2>Подтвердите корректность кодировки</h2>
            <p>Пожалуйста, подтвердите, что данные загружены в правильной кодировке.</p>
            <p>Если кодировка отображается не корректно, переведите файл в кодировку UTF-8 и загрузите обновленный файл.</p>
            <p>
Если необходима помощь, обратитесь в
{' '}
<a href="https://uniplace.unicon.ru/helpdesk">Сервисную службу</a>
.
            </p>
            <Button onClick={confirmEncodingJp} className="asap_button_1">Подтвердить</Button>
            {/* <Button onClick={() => setUploadedFilesJP([])} className="asap_button_1">Отменить</Button> */}
          </div>
        </div>
              )}
            </>
          )}
        </div>

      )}

{uploadedFilesJP && uploadedFilesJP.length > 1 && visibleContent === 'page_3_1' && selectedTableData && (
      <>

        <h3 className="asap__title">Первые 10 строк всех файлов:</h3>
        {uploadedFilesJP.map((file, index) => (
          <div key={index} className="file_preview_box">
            <h3>
Файл
{' '}
{index + 1}
:
            </h3>
            {selectedTableData[index + 1] ? (
              <>
                {selectedDelimiter === 'space' && renderTable(selectedTableData[index + 1])}
                {selectedDelimiter === 'comma' && renderTable(selectedTableData[index + 1])}
                {selectedDelimiter === 'semicolon' && renderTable(selectedTableData[index + 1])}
              </>
            ) : (
              <p>Данные для этого файла отсутствуют</p>
            )}
          </div>
        ))}

        <div>
          <input
            type="checkbox"
            id="removeHeaders"
            onChange={() => setRemoveHeaders(true)}
          />
          Удалить повторные заголовки таблиц
        </div>

        <Button onClick={proceedToPage3} className="asap_button_1">Колонки сходятся</Button>
                  {/* Модальное окно для подтверждения кодировки */}
      </>

)}

      {visibleContent === 'page_3' && selectedTableData && (
        <>
          <h3 className="asap__title">Выбранная таблица журнала проводок:</h3>
          <div id="file_preview_box" className="file_preview_box">
            {renderTable(selectedTableData[0])}
          </div>
          <h3 className="asap__title">
            Cопоставьте необходимые колонки и строки для анализа:
          </h3>
          <p className="asap__title">
            Перетяните нужное поле в подходящую колонку зеленой области в
            таблице.
          </p>

          <table
            ref={sectionRef}
            onDragEnter={dragEnter}
            onDrop={dragDrop}
            onDragOver={dragOver}
            className="table-custom"
          >
  <tbody>
    <tr>
      <td className="dropzone">
        <div
          className="drag"
          id="jp_date"
          draggable="true"
          onDragStart={dragStart}
          onDrop={dragDrop}
        >
          <span className="text">Дата</span>
        </div>
      </td>
      <td className="dropzone">
        <div
          className="drag"
          id="jp_document"
          draggable="true"
          onDragStart={dragStart}
          onDrop={dragDrop}
        >
          <span className="text">Документ</span>
        </div>
      </td>
      <td className="dropzone">
        <div
          className="drag"
          id="jp_bill_dt"
          draggable="true"
          onDragStart={dragStart}
          onDrop={dragDrop}
        >
          <span className="text">Счет Дт</span>
        </div>
      </td>
      <td className="dropzone">
        <div
          className="drag"
          id="jp_bill_kt"
          draggable="true"
          onDragStart={dragStart}
          onDrop={dragDrop}
        >
          <span className="text">Счет Кт</span>
        </div>
      </td>
      <td className="dropzone">
        <div
          className="drag"
          id="jp_sum"
          draggable="true"
          onDragStart={dragStart}
          onDrop={dragDrop}
        >
          <span className="text">Сумма</span>
        </div>
      </td>
      <td className="dropzone">
        <div
          className="drag"
          id="jp_content"
          draggable="true"
          onDragStart={dragStart}
          onDrop={dragDrop}
        >
          <span className="text">Содержание</span>
        </div>
      </td>
    </tr>
  </tbody>
          </table>
          <div className="button-wrapper">
          <Button onClick={ConfirmJpEnd} className="asap_button_1">Подтверить файл ЖП</Button>
          </div>
        </>
      )}
     {isUploading && visibleContent === 'page_4' && (
      <>
        <p className="asap__title">Идет загрузка файлов. Это может занять несколько минут...</p>
        <ProgressBar progress={uploadProgress} fileName={uploadedFileNameOSV} />
      </>
     )}
    {/* После загрузки прогресс бара добавляем новую область */}
  {isUploadingFiles && visibleContent === 'page_4' && (
  <div className="asap__date-inputs">
    <h2 className="asap__title">2 этап</h2>
    <h3 className="asap__title">В полях ниже укажите даты проведения тестирования.</h3>
    <p className="asap__title">Это границы в рамках которых будет проходить всё дальнейшее тестирование.</p>
    <div className="asap__date-input">
    <CardSetting title="Дата начала периода:" invalid={!startDate}>
      <Input
        placeholder="Введите дату начала периода"
        value={startDate || ''} // Условный оператор
        type="date"
        id="startDate"
        name="startDate"
        onChange={(event) => handleDateChange(event)} // Стрелочная функция
      />
    </CardSetting>

    </div>
    <div className="asap__date-input">
    <CardSetting title="Дата окончания периода:" invalid={!endDate}>
        <Input
          placeholder="Введите дату окончания периода"
          value={endDate || ''} // Условный оператор
          type="date"
          id="endDate"
          name="endDate"
          onChange={(event) => handleDateChange(event)} // Стрелочная функция
        />
    </CardSetting>

    </div>
    <Button onClick={confirmDates} className="asap_button_1">Подтвердить даты</Button>
  </div>
  )}

  {visibleContent === 'page_5' && (
    <div className="asap__email-input">
    <CardSetting title="Адрес электронной почты" invalid={!email}>
    <Input
      placeholder={email ? '' : 'Введите Ваш email'}
      value={email}
      type="email"
      id="email"
      name="email"
      onChange={(e) => setEmail(e.target.value)}
    />
    </CardSetting>

    <Button onClick={sendData} className="asap_button_1">Отправить данные</Button>
    </div>
  )}
  {visibleContent === 'page_6' && (
  <div className="asap__confirmation-message">
    <h3 className="asap__title">Заявка сформирована</h3>
    <p>Ожидайте письмо на почту.</p>
  </div>
  )}
    </div>
  );
}

export default Analysis;
