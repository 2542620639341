import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import ServiceBody from '../../../components/Service/ServiceBody';
import Button from '../../../components/UI/Button/Button';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TBody from '../../../components/Table/TBody';
import TFoot from '../../../components/Table/TFoot';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import Loader from '../../../components/UI/Loader';
import ServicePanel from '../../../components/Service/ServicePanel';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import Wrapper from '../../../components/Basic/Wrapper';
import Select from '../../../components/UI/Select';
import Service from '../../../components/Service/Service';

// Сервис оценок - Интерфейс заполнения проектов
function ApproverInfo() {
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [projects, setProjects] = useState(null); // проекты связанные с сотрудником
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const [period, setPeriod] = useState(''); // период времени
  const [finYears, setFinYears] = useState([]);
  const location = 'approver';

  const headers = [ // заголовки
    { id: 1, title: 'Клиент', field: 'client_title' },
    { id: 2, title: 'Проект', field: 'project_title' },
    { id: 3, title: 'Сотрудник', field: 'employee_title' },
    { id: 4, title: 'Грейд', field: 'grade' },
    { id: 5, title: 'Руководитель', field: 'approver_title' },
    { id: 6, title: 'Партнер', field: 'partner_title' },
    { id: 7, title: 'Директор', field: 'director_title' },
    { id: 8, title: 'Менеджер', field: 'manager_title' },
    { id: 9, title: 'Кол-во часов', field: 'hours_amount' },
    { id: 10, title: 'Статус', field: 'status_title' },
    { id: 11, title: 'Действие', field: 'action' },
  ];

  const defaultTableState = {
    title: 'Мои проекты',
    page: 1,
    limit: 50, // количество строк в таблице
    switch_data: {
      current: 'active',
      titles: {
        all: 'Все',
        active: 'Активные',
      },
    },
    button_place: 'header',
  };
  const [tableState, setTableState] = useState(defaultTableState);
  const [total, setTotal] = useState(0);
  const isAdmin = false;

  useEffect(() => {
    findmyProjsApplications();
  }, [tableState, period]);

  useEffect(() => {
    getFinYears();
  }, []);

  // Поиск заявлений на выбранные даты
  async function getFinYears() {
    try {
      const reqData = {
        type: 'getAllFinYears',
        interfaceType: 'approver',
      };
      const result = await clientSendData('POST', '/get_all_fin_years', reqData); // запрос в БД
      if (result) {
        setFinYears(result);
        setPeriod(result[0].title);
      }
    } catch (error) {
      catchHandler(error, 'findProjectEvs'); // обработка ошибок
    }
  }

  async function findmyProjsApplications() {
    try {
      const reqData = {
        type: 'findmyProjsApplications',
        is_approver: true,
        tableState,
        period,
      };
      const result = await clientSendData('POST', '/find_myProjs_applications', reqData); // запрос в БД
      setProjects(result);
      setTotal(result.length);
    } catch (error) {
      catchHandler(error, 'findApplications'); // обработка ошибок
    }
  }

  // Открыть заявление
  function openApplication(id) {
    setSearchParams({ app: id, isAdmin, location });
  }

  function chooseFinYear(finYear) {
    setPeriod(finYear.title);
    localStorage.setItem('myProjPeriod', finYear.title);
    setTableState((state) => ({ ...state, page: 1 }));
  }

  // Определение отображения на основе исходного значения
  const defineValue = (field, value) => {
    if (field === 'type') {
      switch (value) {
        case 'leadPartn': return 'Главный партнёр';
        case 'execPartn': return 'Исполняющий партнёр';
        case 'director': return 'Директор';
        case 'manager': return 'Менеджер';
        default: return 'Не определено';
      }
    } else if (field === 'is_closed') {
      return value ? 'В работе' : 'Завершён';
    } else return value;
  };

  return (
    <ServiceBody>
      {/* <div>Инфо о сотруднике</div> */}
      {isLoading ? <Loader /> : (
        <>
        <Wrapper flexFlow="row wrap">
        Финансовый год
        <Select
          id="fin_year"
          array={finYears}
          onChoose={(finYear) => chooseFinYear(finYear)}
          defaultValue={period}
        />
        </Wrapper>
        <PaginatedTable
          id="evaluation__approver_info-table"
          className="approver_info-table"
          headers={headers}
          setState={setTableState}
          state={tableState}
          button_place="row"
          total={total}
        >
          {/* Если нет результатов */}
          {projects?.length === 0 ? (
          <TRow>
            {headers.map((item, index) => (
              <TData key={item.id}>
                {index === 0 ? 'Результаты отсутствуют' : ''}
              </TData>
            ))}
          </TRow>) : (
            projects?.map((row) => ( // Если результаты есть
            <TRow key={row.id}>
              {headers.map((item) => {
                if (item.field !== 'action') return <TData key={item.id}>{row[item.field] ? row[item.field] : '-'}</TData>;
                return <TData key={item.id} onClick={() => openApplication(row.id)}>Посмотреть</TData>;
              })}
            </TRow>
            )))}
        </PaginatedTable>
        </>)}
    </ServiceBody>
  );
}

export default ApproverInfo;
