import React, { useState } from 'react';
import TRow from '../../../../../components/Table/TRow';
import TData from '../../../../../components/Table/TData';
import Select from '../../../../../components/UI/Select';
import TextArea from '../../../../../components/UI/TextArea';
import {
  integerOptions,
  characterOptions,
  integerNoValue,
  characterNoValue,
  incidentCharacterOptions,
  incidentIntegerOptions,
  finishedTasksIntegerOptions,
  finishedTasksCharacterOptions,
  description,
} from '../options';

// Сервис оценок - страница заявления - заявление на оценку - таблица оценки - строка таблицы
function RowChangeable(props) {
  const {
    data, // данные для строки (деструктуризация ниже)
    isUser, // смотрит сотрудник?
    isApprover, // смотрит согласующий?
    isMainApprover, // смотрит утверждающий?
    appraisal, // данные оценки
    setAppraisal, // обновление состояния данных оценки
    extTasks, // доп задачи
    setExtTasks, // обновление состояния доп задач
    user_id,
    isMonitoringUser,
    isAdmin,
    location,
    role,
  } = props;
  const {
    category_id, // id категории
    task_id, // id задачи
    task_title, // название задачи
    mark, // Параметр выделения строки цветом
    order,
  } = data;

  const [isCommenting, setIsCommenting] = useState(false); // комментирование задачи сотрудником
  const [isApprCommenting, setIsApprCommenting] = useState(false); // комментирование задачи руководителем
  const [isExtEditing, setIsExtEditing] = useState(false); // состояние редактирования доп задачи

  const isExtTask = category_id === 'ext'; // Это дополнительная задача?
  const taskData = appraisal?.[category_id]?.[task_id]; // данные задачи в appraisal
  const valueInt = taskData?.value_int ? taskData?.value_int : 0; // числовая оценка задачи
  const valueChar = taskData?.value_char; // буквенная оценка задачи
  const comment = taskData?.comment; // комментарий аудитора к задаче
  const approverComment = taskData?.approver_comment; // комментарий аудитора к задаче

  // Изменение данных заявления
  // value - значение ячейки
  // type - тип изменяемой ячейки

  function changeFinishedTasksAppraisal(value, type) {
    const changes = {}; // изменения
    // если изменяется char ячейка - ключ value_char
    if (type === 'char') {
      let intValue = '';
      switch (value) {
        case characterNoValue: intValue = integerNoValue;
          break;
        case 'A': intValue = 0;
          break;
        case 'B': intValue = 0;
          break;
        case 'C': intValue = -1;
          break;
        case 'D': intValue = -2;
          break;
        default: intValue = '';
      }
      changes.value_int = intValue;
      changes.value_char = value;
    }

    // если изменяется int ячейка - ключ value_int/char
    if (type === 'int') {
      let charValue = '';
      switch (value) {
        case integerNoValue: charValue = characterNoValue;
          break;
        case 0: charValue = 'A';
          break;
        case -1: charValue = 'C';
          break;
        case -2: charValue = 'D';
          break;
        default: charValue = '';
      }
      changes.value_int = value;
      changes.value_char = charValue;
    }

    // если изменяется ячейка комментария - ключ comment
    if (type === 'comment') changes.comment = value;

    // если изменяется ячейка комментария руководителя - ключ approver_comment
    if (type === 'approver_comment') changes.approver_comment = value;

    // Обновить объект заявления
    setAppraisal({
      ...appraisal, // объект заявления
      [category_id]: { // ключ по id категории
        ...appraisal?.[category_id], // объект категории
        [task_id]: { // ключ по id задачи
          ...taskData, // объект задачи
          category_id, // id категории
          task_id, // id задачи
          task_title, // название задачи
          ...changes, // добавить изменения
        },
      },
    });
  }

  function changeExpressMonitoringAppraisal(value, type) {
    const changes = {}; // изменения
    // если изменяется char ячейка - ключ value_char
    if (type === 'char') {
      let intValue = '';
      switch (value) {
        case characterNoValue: intValue = integerNoValue;
          break;
        case 'Нарушения не выявлены': intValue = 0;
          break;
        case 'Нарушения выявлены': intValue = -0.5;
          break;
        default: intValue = '';
      }
      changes.value_int = intValue;
      changes.value_char = value;
    }

    // если изменяется int ячейка - ключ value_int/char
    if (type === 'int') {
      let charValue = '';
      switch (value) {
        case integerNoValue: charValue = characterNoValue;
          break;
        case 0: charValue = 'Нарушения не выявлены';
          break;
        case -0.5: charValue = 'Нарушения выявлены';
          break;
        default: charValue = '';
      }
      changes.value_int = value;
      changes.value_char = charValue;
    }

    // если изменяется ячейка комментария - ключ comment
    if (type === 'comment') changes.comment = value;

    // если изменяется ячейка комментария руководителя - ключ approver_comment
    if (type === 'approver_comment') changes.approver_comment = value;

    // Обновить объект заявления
    setAppraisal({
      ...appraisal, // объект заявления
      [category_id]: { // ключ по id категории
        ...appraisal?.[category_id], // объект категории
        [task_id]: { // ключ по id задачи
          ...taskData, // объект задачи
          category_id, // id категории
          task_id, // id задачи
          task_title, // название задачи
          ...changes, // добавить изменения
        },
      },
    });
  }

  function changeAppraisal(value, type) {
    const changes = {}; // изменения
    // если изменяется char ячейка - ключ value_char
    if (type === 'char') {
      let intValue = '';
      switch (value) {
        case characterNoValue: intValue = integerNoValue;
          break;
        case 'A': intValue = 9;
          break;
        case 'B': intValue = 7;
          break;
        case 'C': intValue = 3;
          break;
        case 'D': intValue = 1;
          break;
        default: intValue = '';
      }
      changes.value_int = intValue;
      changes.value_char = value;
    }

    // если изменяется int ячейка - ключ value_int/char
    if (type === 'int') {
      let charValue = '';
      switch (value) {
        case integerNoValue: charValue = characterNoValue;
          break;
        case 1: case 2: charValue = 'D';
          break;
        case 3: case 4: case 5: charValue = 'C';
          break;
        case 6: case 7: case 8: charValue = 'B';
          break;
        case 9: case 10: charValue = 'A';
          break;
        default: charValue = '';
      }
      changes.value_int = value;
      changes.value_char = charValue;
    }

    // если изменяется ячейка комментария - ключ comment
    if (type === 'comment') changes.comment = value;

    // если изменяется ячейка комментария руководителя - ключ approver_comment
    if (type === 'approver_comment') changes.approver_comment = value;

    // Обновить объект заявления
    setAppraisal({
      ...appraisal, // объект заявления
      [category_id]: { // ключ по id категории
        ...appraisal?.[category_id], // объект категории
        [task_id]: { // ключ по id задачи
          ...taskData, // объект задачи
          category_id, // id категории
          task_id, // id задачи
          task_title, // название задачи
          ...changes, // добавить изменения
        },
      },
    });
  }

  // Изменить имя дополнительной задачи
  function changeTaskName(e) {
    setExtTasks(extTasks.map((task) => {
      if (task.id === task_id) {
        return {
          ...task,
          object: {
            ...task?.object,
            title: e.target.value,
          },
        };
      } return task;
    }));
  }

  // Удалить дополнительную задачу
  function deleteTask() {
    setExtTasks(extTasks.filter((task) => task.id !== task_id));
  }

  const [extInfo, setExtInfo] = useState(false);
  return (
    <TRow mark={mark} user_id={user_id}>

      {/* НАЗВАНИЕ ЗАДАЧИ */}
      {/* Если это стандартная задача - показать название */}
      {!isExtTask ? <TData>
        {task_title}
        <span className="task_info" onPointerEnter={() => setExtInfo(true)} onPointerLeave={() => setExtInfo(false)}>
          {/* <p title={description[task_id][role]}> */}
          <div className="question_mark">
?
{extInfo && <div className="help-card"><span>{description[task_id][role]}</span></div> }
          </div>
          {/* </p> */}
        </span>
                    </TData> : (
      // Иначе это дополнительная задача - есть возможность изменить название
      <TData>
        {isExtEditing ? (// если имя редактируется - показывается textArea
        <TextArea
          onChange={changeTaskName}
          defaultValue={task_title}
          onBlur={() => setIsExtEditing(false)}
          onKeyUp={(e) => { if (e.key === 'Enter') setIsExtEditing(false); }}
          autoFocus
        />
        ) : ( // Иначе показывается название задачи
        <span className="clickable" onClick={() => setIsExtEditing(true)}>
          {task_title}
        </span>)}
      </TData>)}

      {/* ЧИСЛОВАЯ ОЦЕНКА ЗАДАЧИ */}
      <TData>
        {(isMonitoringUser && category_id === 11 && order === 1) && <Select
          id={`evaluation__choose-int-option_${task_id}`}
          onChoose={(item) => changeExpressMonitoringAppraisal(item.title, 'int')}
          array={incidentIntegerOptions}
          defaultValue={String(valueInt)}
        />}
        {(isMonitoringUser && category_id === 11 && order === 2) && <Select
          id={`evaluation__choose-int-option_${task_id}`}
          onChoose={(item) => changeFinishedTasksAppraisal(item.title, 'int')}
          array={finishedTasksIntegerOptions}
          defaultValue={String(valueInt)}
        />}
        {!isMonitoringUser && <Select
          id={`evaluation__choose-int-option_${task_id}`}
          onChoose={(item) => changeAppraisal(item.title, 'int')}
          array={integerOptions}
          defaultValue={valueInt}
        />}
      </TData>

      {/* БУКВЕННАЯ ОЦЕНКА ЗАДАЧИ */}
      <TData>
      {(isMonitoringUser && category_id === 11 && order === 1) && <Select
        id={`evaluation__choose-char-option_${task_id}`}
        onChoose={(item) => changeExpressMonitoringAppraisal(item.title, 'char')}
        array={incidentCharacterOptions}
        defaultValue={valueChar}
      />}
        {(isMonitoringUser && category_id === 11 && order === 2) && <Select
          id={`evaluation__choose-char-option_${task_id}`}
          onChoose={(item) => changeFinishedTasksAppraisal(item.title, 'char')}
          array={finishedTasksCharacterOptions}
          defaultValue={valueChar}
        />}
         {!isMonitoringUser && <Select
           id={`evaluation__choose-char-option_${task_id}`}
           onChoose={(item) => changeAppraisal(item.title, 'char')}
           array={characterOptions}
           defaultValue={valueChar}
         />}
      </TData>

      {/* КОММЕНТАРИЙ СОТРУДНИКА */}
      <TData>
          {isCommenting && ( // Если состояние комментирования
          <TextArea // показать textarea
            onChange={(e) => changeAppraisal(e.target.value, 'comment')}
            defaultValue={comment}
            onBlur={() => setIsCommenting(false)}
            onKeyUp={(e) => { if (e.key === 'Enter') setIsCommenting(false); }}
            autoFocus
            maxlength="250"
          />)}

          {/* Если смотрит сотрудник или админ -
          показать текст комментария с возможностью изменения */}
          {!isCommenting && (location === 'employee') && (
          <span className="clickable" onClick={() => setIsCommenting(true)}>
              {comment?.trim() || 'Оставить комментарий'}
          </span>
          ) }

           {(!isCommenting && (location === 'admin' || location === 'approver')) && (
          <span>
              {comment?.trim()}
          </span>
           ) }

          {isExtTask && <span className="clickable" onClick={deleteTask}>Удалить задачу</span>}
      </TData>

      {/* КОММЕНТАРИЙ РУКОВОДИТЕЛЯ */}
      <TData>
          {isApprCommenting && (// Если состояние комментирования руководителем
          <TextArea // показать textarea
            onChange={(e) => changeAppraisal(e.target.value, 'approver_comment')}
            defaultValue={approverComment}
            onBlur={() => setIsApprCommenting(false)}
            onKeyUp={(e) => { if (e.key === 'Enter') setIsApprCommenting(false); }}
            maxlength="250"
            autoFocus
          />)}

          {/* Если если смотрит согласующий, админ или утверждающий -
          - показать текст комментария с возможностью изменения */}
            {!isApprCommenting && (location === 'admin' || location === 'approver') && (
            <span className="clickable" onClick={() => setIsApprCommenting(true)}>
                {approverComment?.trim() || 'Оставить комментарий'}
            </span>
            ) }

        {(!isCommenting && (location === 'employee')) && (
          <span>
              {approverComment?.trim()}
          </span>
        ) }
      </TData>
    </TRow>
  );
}

export default RowChangeable;
