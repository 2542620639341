import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Service from '../../components/Service/Service';
import ServiceBody from '../../components/Service/ServiceBody';
import Button from '../../components/UI/Button/Button';
import ServicePanel from '../../components/Service/ServicePanel';
import Chat from './Chat';
import Message from './Message';
import Decision from './Decision';
import MessageHistory from './MessageHistory';
import classes from '../../components/UI/Button/button.module.scss';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';

function PODFTpage(props) {
  const {
    messageId, sdlUser, dir, getMessages,
  } = props;
  const pages = (sdlUser || dir) ? ['Сообщение', 'Работа с СДЛ', 'Принятие решения', 'История'] : ['Сообщение', 'Работа с СДЛ']; // вкладки
  const [choice, setChoice] = useState(pages[0]); // Состояние выбора сервиса
  const [searchParams, setSearchParams] = useSearchParams(''); // параметры поиска url
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const content = {
    Сообщение: <Message messageId={messageId} users={users} sdlUser={sdlUser} dir={dir} />,
    'Работа с СДЛ': <Chat messageId={messageId} sdlUser={sdlUser} dir={dir} />,
    'Принятие решения': <Decision messageId={messageId} sdlUser={sdlUser} dir={dir} />,
    История: <MessageHistory messageId={messageId} />,
  };

  useEffect(() => {
    getUsers();
  }, []);

  function onBackClick() {
    setSearchParams('');
    getMessages();
  }

  async function getUsers() {
    try {
      const reqData = {
        type: 'podftGetUsers',
      };
      const result = await clientSendData('POST', '/podft_get_users', reqData); // запрос в БД
      if (result) {
        setUsers(result.data);
      }
    } catch (error) {
      catchHandler(error, 'podftGetUsers'); // обработка ошибок
    }
  }

  return (
    <Service>
        <ServiceBody horizontal>
        <ServicePanel>
            <Button onClick={onBackClick} className="text-button" style={{ marginLeft: '10px' }}>
            <div className="evl">
                    <span className="arrow">←</span>
                    Вернуться на главную
            </div>
            </Button>
        {pages.map((page) => {
          const { button, button_ghost } = classes;
          const buttonClass = choice === page ? button : button_ghost;
          return (
            <Button key={page} className={buttonClass} onClick={() => setChoice(page)}>
              {page}
            </Button>
          );
        })}
        </ServicePanel>
        {content[choice]}
        </ServiceBody>
    </Service>
  );
}

export default PODFTpage;
