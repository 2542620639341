import React from 'react';
import './PODFT.scss';
import ServiceBody from '../../components/Service/ServiceBody';
import Service from '../../components/Service/Service';

function MessageToPdf(props) {
  const {
    message, defaultDecision, messageId, signData,
  } = props;

  return (
    <div id="message-to-pdf">
      <div className="msg-container">
        <table>
          <tbody>
            <tr>
              <td className="header">
                <p>
                  <strong>
                    Внутреннее сообщение
                    {/* {messageId} */}
                  </strong>
                </p>
              </td>
            </tr>

            <tr className="tableRow">
              <td className="tableCell">
                <p
                  style={{
                    borderBottom: '1px solid black',
                    padding: '2px',
                  }}
                >
                  а) разовая необычная операция;
                </p>
                <p style={{ padding: '2px' }}>б) иные операции (сделки)[1];</p>
              </td>
              <td className="tableCell">
                <p className="optData">{message?.operationType}</p>
              </td>
            </tr>

            <tr className="tableRow">
              <td className="tableCell">
                <p>
                  Критерии (признаки) либо иные обстоятельства (причины), по
                  которым операция (сделка) может быть отнесена к разовым
                  необычным операциям (сделкам)/иные операции (сделки)
                </p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              <td className="tableCell">
                <p className="optData">
{message?.operTypeCriteria?.map((item) => (
                    <p>{item.code}</p>
))}

                </p>
              </td>
            </tr>

            <tr className="tableRow">
              <td className="tableCell">
                <p>Содержание (характер) операции (сделки)</p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              <td className="tableCell">
                <p className="optData">{message?.operationContent}</p>
              </td>
            </tr>

            <tr className="tableRow">
              <td className="tableCell">
                <p>Иные данные по операции (№ договора, и т.п.)</p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              <td className="tableCell">
                <p className="optData">{message?.additionalData}</p>
              </td>
            </tr>

            <tr className="tableRow">
              <td className="tableCell">
                <p>Дата совершения операции (сделки)</p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              <td className="tableCell">
                <p className="optData">{message?.executionDate}</p>
              </td>
            </tr>

            <tr className="tableRow">
              <td className="tableCell">
                <p>Сумма операции (сделки)</p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              <td className="tableCell">
                <p className="optData">{message?.operationSumm}</p>
              </td>
            </tr>

            <tr className="tableRow">
              <td className="tableCell">
                <p>Валюта проведения операции (сделки)</p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              <td className="tableCell">
                <p className="optData">{message?.currency}</p>
              </td>
            </tr>

            <tr className="tableRow">
              <td className="tableCell">
                <p>
                  Сведения о лице (лицах) иностранной структуры без образования
                  юридического лица, проводящих операцию (сделку)[2]
                </p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              <td className="tableCell">
                <p className="optData">{message?.foreignExecutor}</p>
              </td>
            </tr>

            <tr className="tableRow">
              <td className="tableCell">
                <p>
                  Сведения о работнике, составившем внутреннее сообщение об
                  операции (сделке)
                </p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              <td className="tableCell">
                <p className="optData">{message?.employee?.name}</p>
              </td>
            </tr>

            {/* <tr className="tableRow">
              <td className="tableCell">
                <p>Подпись работника, составившего сообщение</p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              {/* <td className="tableCell">
                <p className="optData">{message?.employee?.name}</p>
              </td>
            </tr>  */}

            <tr className="tableRow">
              <td className="tableCell">
                <p>
                  Дата и время составления внутреннего сообщения об операции
                  (сделке)
                </p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              <td className="tableCell">
                <p className="optData">
                  {defaultDecision?.compileDate?.date}
                  {'  '}
                  {defaultDecision?.compileDate?.time}
                </p>
              </td>
            </tr>

            <tr className="tableRow">
              <td className="tableCell">
                <p>Дата и время получения сообщения СДЛ</p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              <td className="tableCell">
                <p className="optData">
                  {defaultDecision?.sdlRecieveDate?.date}
                  {'  '}
                  {defaultDecision?.sdlRecieveDate?.time}
                </p>
              </td>
            </tr>

             <tr className="tableRow">
              <td className="tableCell">
                <p>Подпись СДЛ</p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
               <td className="tableCell">
                <p className="optData">{signData?.sdl_full_sign}</p>
               </td>
             </tr>

            <tr className="tableRow">
              <td className="tableCell">
                <p>
                  Запись (отметка) о решении СДЛ, принятом в отношении
                  внутреннего сообщения об операции (сделке)
                </p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              <td className="tableCell">
                <p className="optData">{defaultDecision?.sdlDecisionMark}</p>
              </td>
            </tr>

            <tr className="tableRow">
              <td className="tableCell">
                <p>
                  Мотивированное обоснование принятого СДЛ решения (в случае
                  принятия решения о не направлении сведений об операции в
                  Уполномоченный орган)
                </p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              <td className="tableCell">
                <p className="optData">{defaultDecision?.decisionReason}</p>
              </td>
            </tr>

            <tr className="tableRow">
              <td className="tableCell">
                <p>Дата и время принятия решения СДЛ</p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              <td className="tableCell">
                <p className="optData">
                  {defaultDecision?.sdlDecisionDate?.date}
                  {'  '}
                  {defaultDecision?.sdlDecisionDate?.time}
                </p>
              </td>
            </tr>

            <tr className="tableRow">
              <td className="tableCell">
                <p>
                  Запись (отметка) о решении Руководителя Компании либо
                  уполномоченного им должностного лица, принятом в отношении
                  внутреннего сообщения об операции (сделке) в соответствии с
                  программой выявления операций, и его мотивированное
                  обоснование
                </p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              <td className="tableCell">
                <div>
                  <p style={{ marginBottom: '3px' }}>
                    а) признать выявленную необычную операцию (сделку)
                    подозрительной операцией (сделкой), осуществление которой
                    может быть направлено на легализацию (отмывание) доходов,
                    полученных преступным путем, или финансирование терроризма,
                    или распространение оружия массового уничтожения;
{' '}
                  </p>
                  <tr
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      border: '1px solid black',
                    }}
                  >
                    <p
                      style={{
                        borderRight: '1px solid black',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '50%',
                      }}
                    >
                      ДА
                    </p>
                    <p
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '50%',
                      }}
                    >
                      НЕТ
                    </p>
                  </tr>
                  <tr
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      border: '1px solid black',
                      borderTop: 'none',
                    }}
                  >
                    <p
                      style={{
                        borderRight: '1px solid black',
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {defaultDecision?.supervisorMark?.D1 === 'Да' ? '+' : ''}
                    </p>
{' '}
                    <p
                      style={{
                        //  borderRight: '1px solid black',
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {defaultDecision?.supervisorMark?.D1 === 'Нет' ? '+' : ''}
                    </p>
                  </tr>
                </div>

                <div>
                  <p style={{ marginBottom: '3px' }}>
                    б) принять дополнительные меры по изучению необычной
                    операции (сделки) клиента;
{' '}
                  </p>
                  <tr
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      border: '1px solid black',
                    }}
                  >
                    <p
                      style={{
                        borderRight: '1px solid black',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '50%',
                      }}
                    >
                      ДА
                    </p>
                    <p
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '50%',
                      }}
                    >
                      НЕТ
                    </p>
                  </tr>
                  <tr
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      border: '1px solid black',
                      borderTop: 'none',
                    }}
                  >
                    <p
                      style={{
                        borderRight: '1px solid black',
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {defaultDecision?.supervisorMark?.D2 === 'Да' ? '+' : ''}
                    </p>
{' '}
                    <p
                      style={{
                        //  borderRight: '1px solid black',
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {defaultDecision?.supervisorMark?.D2 === 'Нет' ? '+' : ''}
                    </p>
                  </tr>
                </div>

                <div>
                  <p style={{ marginBottom: '3px' }}>
                    в) представить информацию об операции, предусмотренной
                    подпунктом &quot;а&quot; настоящего пункта, в Федеральную
                    службу по финансовому мониторингу;
                  </p>
                  <tr
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      border: '1px solid black',
                    }}
                  >
                    <p
                      style={{
                        borderRight: '1px solid black',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '50%',
                      }}
                    >
                      ДА
                    </p>
                    <p
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '50%',
                      }}
                    >
                      НЕТ
                    </p>
                  </tr>
                  <tr
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      border: '1px solid black',
                      borderTop: 'none',
                      //  borderBottom: 'none',
                    }}
                  >
                    <p
                      style={{
                        borderRight: '1px solid black',
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {defaultDecision?.supervisorMark?.D3 === 'Да' ? '+' : ''}
                    </p>
                    <p
                      style={{
                        //  borderRight: '1px solid black',
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {defaultDecision?.supervisorMark?.D3 === 'Нет' ? '+' : ''}
                    </p>
                  </tr>
                </div>
              </td>
            </tr>
            <tr className="tableRow">
              <td className="tableCell">
                <p>
                  Запись (отметка) о дополнительных мерах (иных действиях),
                  предпринятых Компанией в отношении клиента в связи с
                  выявлением необычной операции (сделки) или ее признаков
                </p>
              </td>
              {/* <td>б) иные операции (сделки)[1];</td> */}
              <td className="tableCell">
                <p className="optData">{defaultDecision?.extraMeasuresMark}</p>
              </td>
            </tr>
            {/* <tr className="tableRow">
              <td className="tableCell">
                <p>Подпись Руководителя Компании</p>
              </td>
              <td className="tableCell">
                <p className="optData">{defaultDecision?.dirSign}</p>
              </td>
            </tr> */}

            <tr className="tableRow">
              <div className="sign">

                <div className="sign-header">
                  <p>Документ подписан</p>
                </div>

                <div className="sign-content">
                <div className="wrapper">
                <p className="bold">ФИО</p>
                <div className="fio">
                    <p className="h">Руководитель задания: </p>
                    <p>{signData?.supervisor_title}</p>
                </div>
                <div className="fio">
                    <p className="h">СДЛ: </p>
                    <p>{signData?.sdl_title}</p>
                </div>
                <div className="fio">
                    <p className="h">ГД: </p>
                    <p>{signData?.dir_title}</p>
                </div>
                </div>

                <div className="wrapper">
                <p className="bold">Идентификатор</p>
                <p className="login">{signData?.init_login}</p>
                <p className="login">{signData?.sdl_login}</p>
                <p className="login">{signData?.dir_login}</p>
                </div>

                {/* <div className="wrapper">
                <p className="bold">Дата</p>
                <p className="date">{signData?.initiator_sign_date}</p>
                <p className="date">{signData?.sdl_sign_date}</p>
                <p className="date">{signData?.dir_sign_date}</p>
                </div> */}
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MessageToPdf;
