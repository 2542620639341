/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import Loader from '../../../../components/UI/Loader';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import CardSetting from '../../../../components/Card/CardSetting';

// Система Оценок - интерфейс сотрудника - карточка заявления - вкладка Истории
function Logs(props) {
  const { id } = props;

  const [logs, setLogs] = useState([]); // история
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки

  useEffect(() => {
    getApplicationHistory(); // получить логи
  }, []);

  // eslint-disable-next-line prefer-const
  // let tmpHistObj = {};
  async function getApplicationHistory() {
    try {
      const reqData = {
        type: 'getApplicationHistory',
        application_id: id,
      };
      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/get_application_history', reqData);
      // tmpHistObj представляет собой объект, содержащий в себе ключи-индексы "карточек"
      // со значениями в виде массива. В указанный массив входят названия полей (англ), которые претерпели изменения в этой "карточке"
      //   console.log(result);
      if (result) {
        setLogs(result);
      }
      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'getApplicationHistory');
      setIsLoading(false); // загрузка завершена
    }
  }

  // Показать объект
  function showObject(object) {
    // console.log(typeof (object));
    // console.log(object);
    if (typeof object === 'object' && object !== null) {
      if (Array.isArray(object)) {
        return <p className="upu__setting-title">{object.join(', ')}</p>;
      }

      return Object.keys(object).map((key) => {
        const keyData = object[key];
        const engKey = key;
        key = newInnerKeys[key];
        return (

                        <CardSetting key={key} title={`${key}:`}>
                            {showObject(keyData)}
                        </CardSetting>
        );
      });
    } return <p className="upu__setting-title">{String(object)}</p>;
  }

  const newKeys = {
    // appraisal: 'Оценка',
    approver_id: 'Согласующий',
    changes_id: 'Изменения',
    client_id: 'Клиент',
    contract_id: 'Контракт',
    created_at: 'Дата создания',
    date_start: 'Дата начала',
    date_end: 'Дата окончания',
    draft: 'Черновик',
    main_approver_id: 'Главный согласующий',
    monitoring: 'Мониторинг',
    notified: 'Уведомление',
    project_id: 'Проект',
    status_id: 'Статус',
    user_id: 'Сотрудник',
    user_role: 'Роль',
    version: 'Версия',
    'Профессиональные навыки и эффективность': ' Профессиональные навыки и эффективность',
    'Работа в команде и управление': ' Работа в команде и управление',
    'Ориентация на клиента': ' Ориентация на клиента',
    'Ответственность и стратегия': ' Ответственность и стратегия',
    'Результаты мониторинга завершенных заданий': 'Результаты мониторинга завершенных заданий',
    'Результаты экспресс-мониторинга (соблюдение регламентов)': 'Результаты экспресс-мониторинга (соблюдение регламентов)',
  };

  const newInnerKeys = {
    comment: 'Комментарий',
    approver_comment: 'Комментарий руководителя',
    task_id: 'ID вопроса',
    task_title: 'Вопрос',
    value_char: 'Буквенная оценка',
    value_int: 'Числовая оценка',
    category_id: 'Категория',
    status_id: 'Статус',
  };
  console.log(logs);
  // если загружается - показывается лоадер
  if (isLoading) return <Loader />;
  // Если логи пустые - показывается заголовок об их отсутствии
  if (logs.length === 0) return <h2 className="upu-service__title">История отсутствует</h2>;
  // Иначе отображается история
  return (
    <div id="evaluation__application-history" className="upu-card__block">
        {logs.map((entry) => {
          const {
            date_created, application, performer,
          } = entry;
          return (
            <div key={entry.id} className="question-history">
                <p className="upu__setting-title">{date_created}</p>
                <p className="upu__setting-title">{performer}</p>
                {Object.keys(application).map((key) => {
                  const keyData = application[key];
                  const engKey = key;
                  // eslint-disable-next-line no-param-reassign
                  key = newKeys[key];

                  // eslint-disable-next-line valid-typeof
                  // if (engKey === 'appraisal') {
                  //   // Если поле appraisal.full === true, то нам надо выделить всю структуру
                  //   if (HistObj.hasOwnProperty(index) && HistObj[index].appraisal.full === true) {
                  //     return (
                  //       <div className="changed_history">
                  //       <CardSetting key={key} title={`${key}:`}>
                  //       {showObject(keyData)}
                  //       </CardSetting>
                  //       </div>
                  //     );
                  //   }
                  //   // return (
                  //   //   <div className="changed_history">
                  //   //   <CardSetting key={key} title={`${key}:`}>
                  //   //   {showObject(keyData[10])}
                  //   //   </CardSetting>
                  //   //   </div>
                  //   // );
                  // }
                  // eslint-disable-next-line no-prototype-builtins

                  // if (HistObj.hasOwnProperty(index) && HistObj[index].fields.includes(engKey)) {
                  //   return (
                  //     <div className="changed_history">
                  //     <CardSetting key={key} title={`${key}:`}>
                  //     {showObject(keyData)}
                  //     </CardSetting>
                  //     </div>
                  //   );
                  // }
                  return (

                    <CardSetting key={key} title={`${key}:`}>
                        {showObject(keyData)}
                    </CardSetting>
                  );
                })}

            </div>
          );
        })}
    </div>
  );
}

export default Logs;
