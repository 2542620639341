import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Card from '../../../components/Card/Card';
import Service from '../../../components/Service/Service';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import ServiceBody from '../../../components/Service/ServiceBody';
import Button from '../../../components/UI/Button/Button';
import CardSetting from '../../../components/Card/CardSetting';
import Select from '../../../components/UI/Select';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import ServicePanel from '../../../components/Service/ServicePanel';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TBody from '../../../components/Table/TBody';
import Input from '../../../components/UI/Input';

function FinalEvalToPrint(props) {
  const {
    employee, finalEvaluation, employeeEvals, userInfo,
  } = props;
  // const [userInfo, setUserInfo] = useState();
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  // const [employeeEvals, setEmployeeEvals] = useState([]);
  const [total, setTotal] = useState(0);
  const [extraInfo, setExtraInfo] = useState('');
  const [cascadeChar, setCascadeChar] = useState('Оценка');
  let finalProjMark = 0;
  let finalProjChar;
  let totalProjMark;
  const headers = [ // заголовки
    { id: 1, title: 'Проект', field: 'project_title' },
    { id: 2, title: 'Роль', field: 'user_role' },
    { id: 3, title: 'Количество часов', field: 'hours_amount' },
    { id: 4, title: 'Проектный балл', field: 'proj_score' },
    { id: 5, title: 'Проектная оценка', field: 'final_mark' },
    // { id: 6, title: 'Результаты мониторинга', field: 'monitoring_res' },
  ];
  const defaultTableState = {
    title: 'Финальные оценки',
    page: 1,
    limit: 5, // количество строк в таблице
    // switch_data: {
    //   current: 'active',
    //   titles: {
    //     all: 'Все',
    //     active: 'Активные',
    //   },
    // },
    button_place: 'header',
  };
  const [tableState, setTableState] = useState(defaultTableState);
  const cascadeSolutions = [
    { id: 1, title: 'Повысить в грейде/должности' },
    { id: 2, title: 'Оставить в занимаемой должности ' },
    { id: 3, title: 'Рассмотреть вопрос о расставании ' },
    { id: 4, title: 'Рассмотреть в промежуточную аттестацию' },
    { id: 5, title: 'Условный промоушен' },
    { id: 6, title: 'Не оценивается' },
    { id: 7, title: 'Понизить в грейде/должности' },
  ];

  const cascadeChars = [
    { id: 1, title: 'A' },
    { id: 2, title: 'B' },
    { id: 3, title: 'C' },
    { id: 4, title: 'D' },
  ];

  // Итоговая проектная оценка
  function finalProjScore() {
    if (employeeEvals.length) {
      let projHoursTotal = 0;
      let markMultiplyHoursTotal = 0;
      for (const evl of employeeEvals) {
        const markMultiplyHours = evl.proj_score * evl.hours_amount;
        markMultiplyHoursTotal += markMultiplyHours;
        projHoursTotal += Number(evl.hours_amount);
      }
      if (markMultiplyHoursTotal) {
        finalProjMark = markMultiplyHoursTotal / projHoursTotal;
      }
      if (finalProjMark > 9) {
        finalProjChar = 'A';
      } else if (finalProjMark < 9 && finalProjMark >= 6) {
        finalProjChar = 'B';
      } else if (finalProjMark < 6 && finalProjMark >= 3) {
        finalProjChar = 'C';
      } else {
        finalProjChar = 'D';
      }
      totalProjMark = `${finalProjMark.toFixed(2)}/${finalProjChar}`;
    }
  }
  finalProjScore();

  // Добавление опционального столбца "Результаты мониторинга"
  if (employeeEvals.length > 0) {
    for (const evl of employeeEvals) {
      if (evl.monitoring_res && headers.length < 6) {
        headers.push({ id: 6, title: 'Результаты мониторинга', field: 'monitoring_res' });
      }
    }
  }

  // Определить отображаемое значение
  const defineValue = (key) => {
    if (userInfo[key]) {
      const data = userInfo[key];
      if (key === 'experience') {
        return `${data?.years || 0}лет ${data?.months || 0}мес.`;
      } return data;
    }
    return '-';
  };

  if (employeeEvals.length > 0) {
    for (const empEval of employeeEvals) {
      if (Number(empEval.proj_score) > 9) {
        empEval.final_mark = 'A';
      } else if (Number(empEval.proj_score) < 9 && Number(empEval.proj_score) >= 6) {
        empEval.final_mark = 'B';
      } else if (Number(empEval.proj_score) < 6 && Number(empEval.proj_score) >= 3) {
        empEval.final_mark = 'C';
      } else {
        empEval.final_mark = 'D';
      }
    }
  }

  function onBackClick() {
    setSearchParams('');
  }

  // Открыть заявление
  function openApplication(id) {
    const search = { app: id, finalApp: true };
    setSearchParams(search);
  }

  function defineNormative() {
    if (['АИ0', 'АИ1', 'АИ2', 'АИ3', 'К0', 'К1', 'К2', 'К3', 'Ф0', 'Ф1', 'Ф2', 'Ф3', 'Р0', 'Р1', 'Р2', 'Р3'].includes(userInfo?.grade)) {
      return '75%';
    } if (['АИ4', 'АИ5', 'К4', 'К5', 'Ф4', 'Ф5', 'Р', 'Р5'].includes(userInfo?.grade)) {
      return '65%';
    } if (['АИ', 'АИ7', 'К6', 'К7', 'Ф6', 'Ф7', 'Р', 'Р7', 'НГ'].includes(userInfo?.grade)) {
      return '55%';
    } if (['АИ8', 'К8', 'Ф8', 'Р8'].includes(userInfo?.grade)) {
      return '30%';
    } if (['П1', 'П2'].includes(userInfo?.grade)) {
      return '15%';
    }
    return '100%';
  }

  return (
    <div id="final-evl">

  <div className="container">
    <div className="period">
       <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Период:</p>
       {/* <Select id="period" /> */}
       <p>01-07-2024 - 30 -06-2025</p>
    </div>

    <div className="user_info_1">
        <div>
        <CardSetting title="ФИО:">
          <span className="userName">
{userInfo?.employee_title}
          </span>
        </CardSetting>
        <CardSetting title="Должность:">{userInfo?.job_title}</CardSetting>
        <CardSetting title="Грейд:">{userInfo?.grade}</CardSetting>
       <CardSetting title="Подразделение:">{userInfo?.dept}</CardSetting>
        </div>
        <div>
        <CardSetting title="МВЗ:">{userInfo?.mvz}</CardSetting>
        <CardSetting title="Средняя утилизация:">
          {userInfo?.utilization ? `${String(userInfo?.utilization)} %` : '-'}
        </CardSetting>
        <CardSetting title="Норматив:">{defineNormative()}</CardSetting>
        {/* <CardSetting title="Оценка 'снизу-вверх' ментор:">B</CardSetting>
        <CardSetting title="Оценка 'снизу-вверх' руководитель:">B</CardSetting> */}
        <CardSetting title="Итоговая проектная оценка:">
          <div className="finalMark">
            <p>
             {totalProjMark}
            </p>
          </div>
        </CardSetting>
        </div>
    </div>

<Table id="employee_final-evals">
<THead
  headers={headers}
  array={employeeEvals}
 // setArray={setEmployeeEvals}
>
  <TRow>
    {headers.map((item) => <TData key={item.id}>
      {item.title}
                           </TData>)}
    {/* <TData key="monitoring_res">Результаты мониторинга</TData> */}

  </TRow>
</THead>
<TBody>
          {employeeEvals.length === 0

            ? <TRow>
                    <TData>Результаты отсутствуют</TData>
                    <TData />
                    <TData />
                    <TData />
                    <TData />
                    <TData />
              </TRow>
            // Если результаты есть
            : employeeEvals.map((row) => {
              const {
                id, finalApp,
              } = row;
              return (
                        <TRow key={id}>
                            {headers.map((item) => {
                              if (item.field !== 'project_title') {
                                return (
                                  <TData key={item.id}>
                                  {row[item.field] ? row[item.field] : '-'}
                                  </TData>
                                );
                              }
                              if (item.field === 'proj_score') {
                                return (
                                  <TData key={item.id}>
                                  {row[item.field] ? row[item.field].toFixed(2) : '-'}
                                  </TData>
                                );
                              }

                              return (
                                    <TData key={item.id} onClick={() => openApplication(id, finalApp)}>
                                     <p style={{ fontWeight: '500' }}>{row[item.field] ? row[item.field] : '-'}</p>
                                    </TData>
                              );
                            })}
                        </TRow>
              );
            })}
</TBody>
</Table>

<span className="decision-to-print">
  <div className="row-to-print">
    <p className="title-to-print">Решение по результатам каскада: </p>
    <p>{finalEvaluation?.cascadeSolution?.title}</p>
  </div>

  <div className="row-to-print">
    <p className="title-to-print">Оценка по итогам каскада:</p>
    <p style={{ fontWeight: 'bold' }}>{finalEvaluation.cascadeChar}</p>
  </div>

  <div className="row-to-print">
    <p className="title-to-print">Рекомендация:</p>
    <p style={{ width: '100%', textAlign: 'right' }}>{finalEvaluation.recommendation}</p>
  </div>
</span>
{/* <span className="decision-rec">
        <div className="decision">
        <Select
          array={cascadeSolutions}
          id="decision"
          defaultValue="Решение по результатам каскада"
          onChoose={(e) => setCascadeSolution({ id: e.id, title: e.title })}
        />
        {[5, 6].includes(cascadeSolution.id)
        && <textarea
          className="comment"
          placeholder="Обоснование решения"
          onChange={(e) => setCascadeComment(e.target.value)}
        /> }
        <div className="cascade-mark">
       <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Оценка по итогам каскада:</p>
        <Select id="cascade" array={cascadeChars} onChoose={(e) => setCascadeChar(e.title)} defaultValue={cascadeChar} />
        </div>
        </div>

        <div className="recommendation">
        <textarea className="comment" placeholder="Рекомендация" onChange={(e) => setRecommendation(e.target.value)} />
        </div>
</span> */}
{/* <div className="cascade-mark">
       <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Оценка по итогам каскада:</p>
       <Select id="cascade" array={cascadeChars} onChoose={(e) => setCascadeChar(e.title)} defaultValue={cascadeChar} />
</div> */}

  </div>

    </div>
  );
}

export default FinalEvalToPrint;
